// @mui
import PropTypes from 'prop-types';
import { Box, Card, TextField, Stack, CardHeader, CardContent, Grid, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

AddForm.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  postDataChangePassword: PropTypes.func,
  dataFormChangePassword: PropTypes.func,
  button: PropTypes.bool,
  handleChangePassword: PropTypes.func,
};

export default function AddForm({
  title,
  subheader,
  handleChangePassword,
  postDataChangePassword,
  dataFormChangePassword,
  button,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="email"
                  label="Email Address"
                  value={dataFormChangePassword.email}
                  onChange={(e) => handleChangePassword(e)}
                  disabled
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="new_password"
                  label="New Password"
                  value={dataFormChangePassword.new_password}
                  onChange={(e) => handleChangePassword(e)}
                  type="password"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="new_password_repetition"
                  label="New Password Repetition"
                  value={dataFormChangePassword.new_password_repetition}
                  onChange={(e) => handleChangePassword(e)}
                  type="password"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="old_password"
                  label="Old Password"
                  value={dataFormChangePassword.old_password}
                  onChange={(e) => handleChangePassword(e)}
                  type="password"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={postDataChangePassword}
                >
                  {button}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
