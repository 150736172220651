import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Grid, Container, Stack, Typography, Link, Button, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// sections
import { AddForm } from '../../sections/contact';
// components
import Iconify from '../../components/iconify';
import useContact from '../../hooks/contact/useContact';
// constant
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

export default function ContactAddPage() {
  const { handleChangeContact, postDataContact, dataFormContact, button, dataCompany } = useContact();

  return (
    <>
      <Helmet>
        <title>Contact: Add | InvoicePlus</title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Contact / Add
            </Typography>
            <Link to="/contacts/list" component={RouterLink} sx={{ display: 'contents' }}>
              <Button startIcon={<Iconify icon="ic:twotone-chevron-left" />} sx={{ color: Colors.WHITE, marginTop: 2 }}>
                Back to list
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid item xs={12} md={6} lg={4}>
          <AddForm
            handleChangeContact={handleChangeContact}
            dataFormContact={dataFormContact}
            postDataContact={postDataContact}
            dataCompany={dataCompany}
            title="Contact Add"
            button={button}
          />
        </Grid>
      </Container>
    </>
  );
}
