const INITIAL_STATE = {
  dataUserAuth: {
    access_token: '',
    meta_access_token: {
      exp: 0,
      iat: 0,
      iss: '',
      nbf: 0,
      sub: '',
    },
    refresh_token: '',
    meta_refresh_token: {
      exp: 0,
      iat: 0,
      iss: '',
      nbf: 0,
      sub: '',
    },
  },
  dataUserProfile: {
    id: 0,
    created_at: '',
    created_by: '',
    updated_at: '',
    updated_by: '',
    deleted_at: null,
    user_name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    phone_code: '',
    email_verification_send_at: '',
    language_id: null,
    country_id: null,
    language: {
      id: 0,
      name: '',
      short_name: '',
      flag: '',
      is_active: null,
    },
    country: {
      id: 0,
      name: '',
      short_name: '',
      phone_code: '',
    },
  },
};

const users = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DATA_USER_AUTH':
      return {
        ...state,
        dataUserAuth: action.data,
      };
    case 'DATA_USER_AUTH_EMPTY':
      return {
        ...state,
        dataUserAuth: INITIAL_STATE,
      };
    case 'DATA_USER_PROFILE':
      return {
        ...state,
        dataUserProfile: action.data,
      };
    default:
      return state;
  }
};

export default users;
