import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField, Link, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// constants
import Colors from '../../../constant/colors';

const styleLines = { borderBottom: 0.5, borderColor: Colors.GREYBB, width: '45%', position: 'relative', top: -10 };

export default function ResetPasswordForm({ emailData, setEmailData, postForgotPassword, button }) {
  const navigate = useNavigate();

  return (
    <>
      <Stack spacing={3}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={emailData}
          onChange={(e) => setEmailData(e.target.value)}
          name="email"
          label="Enter your email address"
        />
      </Stack>

      <LoadingButton
        sx={{
          borderRadius: 20,
          mt: 3,
          backgroundColor: Colors.PRIMARY,
          '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={postForgotPassword}
      >
        {button}
      </LoadingButton>

      <Box sx={{ mb: 2, mt: 2, display: 'flex' }}>
        <Box sx={styleLines} />
        <Typography sx={{ textAlign: 'center', color: Colors.GREYBB, px: 2 }}> or </Typography>
        <Box sx={styleLines} />
      </Box>

      <LoadingButton
        sx={{
          borderRadius: 20,
          backgroundColor: Colors.WHITE,
          borderColor: Colors.PRIMARY,
          '&.MuiButton-root:hover': { bgcolor: Colors.WHITE, borderColor: Colors.PRIMARY },
          color: Colors.PRIMARY,
          textDecoration: 'none',
        }}
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => navigate('/register')}
      >
        Register
      </LoadingButton>
    </>
  );
}
