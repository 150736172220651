import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useInvoice = () => {
  const INITIAL_STATE_IMAGE_INV = [
    {
      id: 1,
      img: '/assets/template-invoice/invoice-1.png',
      isSelected: true,
    },
    {
      id: 2,
      img: '/assets/template-invoice/invoice-2.png',
      isSelected: false,
    },
    {
      id: 3,
      img: '/assets/template-invoice/invoice-3.png',
      isSelected: false,
    },
    {
      id: 4,
      img: '/assets/template-invoice/invoice-4.png',
      isSelected: false,
    },
    {
      id: 5,
      img: '/assets/template-invoice/invoice-5.png',
      isSelected: false,
    },
    {
      id: 6,
      img: '/assets/template-invoice/invoice-6.png',
      isSelected: false,
    },
  ];

  const INITIAL_STATE_LINE = {
    id: '',
    service_id: '',
    title: '',
    price: 0,
    quantity: 0,
    description: '',
  };

  const [dataInvoice, setDataInvoice] = useState([]);
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataServices, setDataServices] = useState([]);
  const [dataImageInvoice, setDataImageInvoice] = useState(INITIAL_STATE_IMAGE_INV);
  // sent,unsend
  const [statusInvoice, setStatusInvoice] = useState('unsend');
  const [customerId, setCustomerId] = useState('0');
  const [dueDate, setDueDate] = useState('');
  const [numberOfPayments, setNumberOfPayments] = useState('');
  const [firstPaymentDate, setFirstPaymentDate] = useState('');
  const [fontColor, setFontColor] = useState('#222222');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceTemplate, setInvoiceTemplate] = useState('1');
  const [invoiceTheme, setInvoiceTheme] = useState('#b3b3b3');
  const [linesData, setLinesData] = useState([]);
  const [viewPayment, setViewPayment] = useState('one-time');
  // one-time,weekly,monthly
  const [paymentFrequency, setPaymentFrequency] = useState('weekly');
  const [paymentFrequencyPer, setPaymentFrequencyPer] = useState(0);
  const [sendToEmail, setSendToEmail] = useState('');
  const [emailNote, setEmailNote] = useState('');
  const [discount, setDiscount] = useState(0);
  const [taxRate, setTaxRate] = useState(0);
  const [vatRate, setVatRate] = useState(0);

  const [loadingInvDetail, setLoadingInvDetail] = useState(false);

  const [tempDataServices, setTempDataServices] = useState(INITIAL_STATE_LINE);
  const [subTotalServices, setSubTotalServices] = useState(0);
  const [grandTotalServices, setGrandTotalServices] = useState(0);

  const [totalTaxServices, setTotalTaxServices] = useState(0);
  const [totalVateTaxServices, setTotalVateTaxServices] = useState(0);

  const [openModalDetailInv, setOpenModalDetailInv] = useState(null);

  const [customerSelected, setCustomerSelected] = useState({
    id: '-',
    contact_person: '-',
    email: '-',
    phone_number: '-',
    company_name: '-',
  });

  const [idServicesId, setIdServicesId] = useState(null);
  const [titleModalServices, setTitleModalServices] = useState('Add Jasa / Produk');

  const [idInvoice, setIdInvoice] = useState('0');
  const [idInvoiceEdit, setIdInvoiceEdit] = useState('0');

  const [button, setButton] = useState('Save & Exit');

  const pageSizeTable = 10;
  const [statusTable, setStatusTable] = useState('all');
  const [dataTotalInvoice, setTotalDataInvoice] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId, dataCompany } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
    dataCompany: states?.CompanyReducer?.dataCompany,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    if (companyId) {
      getDataInvoice();
      getDataCustomer();
      getDataServices();

      const urlParams = new URLSearchParams(window.location.search);
      const myParamId = urlParams.get('invoice_id');

      if (myParamId) {
        getDataInvoiceDetail(myParamId);
        setIdInvoiceEdit(myParamId);
      }

      if (dataCompany.length > 0) {
        const filterDataCompany = dataCompany.filter((i) => i.id === companyId);

        setSendToEmail(filterDataCompany[0].email);
      }
    }
  }, []);

  const getDataInvoice = async (newPage = 1, filterTable = '') => {
    api
      .get(`${endpoint.INVOICE}/${companyId}/${statusTable}?page_size=${pageSizeTable}&&page=${newPage}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const dataRes = res.data.data.data;
          const totalData = res.data.data.total_data;
          const totalDataPlus = dataInvoice.length + dataRes.length;

          if (totalDataPlus <= totalData) {
            setDataInvoice([...dataInvoice, ...dataRes]);
          }

          if (filterTable === 'filter') {
            setDataInvoice(dataRes);
          }

          setTotalDataInvoice(totalData);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataInvoiceDetail = async (myParamId = '') => {
    api
      .get(`${endpoint.INVOICE}/${myParamId}`, getAuthHeader)
      .then((res) => {
        setLoadingInvDetail(true);

        if (res.data.success) {
          const dataInv = res.data.data;

          setCustomerId(dataInv.customer_id);
          setInvoiceNumber(dataInv.invoice_number);

          const dueDateFormat = new Date(dataInv.due_date);

          const dueDateYear = dueDateFormat.getFullYear();
          const dueDateMonthPlus = dueDateFormat.getMonth() + 1;
          const dueDateMonthString = dueDateMonthPlus.toString();
          const dueDateMonth = dueDateMonthString.length === 1 ? `0${dueDateMonthPlus}` : dueDateMonthString;

          const dueDateDayPlus = dueDateFormat.getDate();
          const dueDateDayString = dueDateDayPlus.toString();
          const dueDateDay = dueDateDayString.length === 1 ? `0${dueDateDayPlus}` : dueDateDayString;

          setDueDate(`${dueDateYear}-${dueDateMonth}-${dueDateDay}`);

          for (let i = 0; i < dataImageInvoice.length; i += 1) {
            if (dataImageInvoice[i].id === dataInv.template) {
              dataImageInvoice[0].isSelected = false;
              dataImageInvoice[i].isSelected = true;
            }
          }

          setDataImageInvoice(dataImageInvoice);
          setInvoiceTemplate(dataInv.template);
          setInvoiceTheme(dataInv.theme);
          setFontColor(dataInv.font_color);
          setFontColor(dataInv.font_color);
          setViewPayment(dataInv.frequency);
          setPaymentFrequency(dataInv.frequency);
          setNumberOfPayments(dataInv.number_of_payments);
          setCustomerSelected(dataInv.customer);
          setStatusInvoice(dataInv.status);

          const firstDateFormat = new Date(dataInv.first_payment_date);

          const firstDateYear = firstDateFormat.getFullYear();
          const firstDateMonthPlus = firstDateFormat.getMonth() + 1;
          const firstDateMonthString = firstDateMonthPlus.toString();
          const firstDateMonth = firstDateMonthString.length === 1 ? `0${firstDateMonthPlus}` : firstDateMonthString;

          const firstDateDayPlus = firstDateFormat.getDate();
          const firstDateDayString = firstDateDayPlus.toString();
          const firstDateDay = firstDateDayString.length === 1 ? `0${firstDateDayPlus}` : firstDateDayString;

          if (dataInv.frequency !== 'one-time') {
            setFirstPaymentDate(`${firstDateYear}-${firstDateMonth}-${firstDateDay}`);
          }

          const lines = dataInv.invoice_lines;
          let subTotal = 0;
          for (let i = 0; i < lines.length; i += 1) {
            lines[i].title = lines[i].item_type;

            subTotal += lines[i].subtotal;
          }

          setLinesData(lines);
          setSubTotalServices(subTotal);
          setTaxRate(dataInv.tax_rate);
          setVatRate(dataInv.vat_rate);
          setDiscount(dataInv.discount_rate);
          setTotalTaxServices(dataInv.tax);
          setTotalVateTaxServices(dataInv.vat);
          setGrandTotalServices(dataInv.grand_total);
        }
      })
      .catch((err) => {
        setLoadingInvDetail(true);
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataCustomer = async () => {
    api
      .get(`${endpoint.GET_USER_CONTACT}/${companyId}/customer?page_size=9999999999&&page=1`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setDataCustomer(res.data.data.data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataServices = async (type) => {
    api
      .get(`${endpoint.GET_USER_SERVICES}/${companyId}/customer?page_size=9999999999&&page=1`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setDataServices(res.data.data.data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleValueServices = async (id) => {
    const result = dataServices.filter((val) => val.id === id);

    if (result.length > 0) {
      setTempDataServices({
        service_id: result[0].id,
        title: result[0].name,
        price: result[0].price_per_unit,
        quantity: 0,
        description: result[0].description,
      });
    }
  };

  const handleChangeServices = async (event) => {
    let values = event.target.value;
    if (event.target.name === 'price' || event.target.name === 'quantity') {
      values = parseFloat(event.target.value);
    }

    setTempDataServices({
      ...tempDataServices,
      [event.target.name]: values,
    });
  };

  const handleAddServices = async () => {
    setLinesData([...linesData, tempDataServices]);

    const subTotalData = [...linesData, tempDataServices];

    let subTotalLoop = 0;
    for (let i = 0; i < subTotalData.length; i += 1) {
      subTotalData[i].id = i;
      subTotalLoop += subTotalData[i].price * subTotalData[i].quantity;
    }

    // menghitung discount bedasarkan subtotal
    const discountTotal = (subTotalLoop * discount) / 100;
    const totalDiscount = subTotalLoop - discountTotal;
    // menghitung tax bedasarkan kalkukasi discount dari subtotal
    const iTax = totalDiscount * taxRate;
    const pTax = iTax / 100;
    // menghitung vat bedasarkan kalkukasi discount dari subtotal
    const iVat = totalDiscount * vatRate;
    const pVat = iVat / 100;
    // jumlah grand total
    const grandTotal = totalDiscount + pTax + pVat;

    setSubTotalServices(subTotalLoop);
    setGrandTotalServices(grandTotal);
  };

  const postDataInvoice = async (statusInvoiceData = 'unsend') => {
    if (statusInvoiceData === 'unsend') {
      setButton('Loading...');
    }

    let isSend = false;
    if (statusInvoiceData === 'sent') {
      isSend = true;
    }

    const dataForm = {
      company_id: companyId,
      company_address: '',
      company_email: '',
      customer_id: customerId,
      company_phone: '',
      due_date: dueDate,
      payment_frequency: paymentFrequency,
      number_of_payments: numberOfPayments ? parseFloat(numberOfPayments) : 0,
      first_payment_date: firstPaymentDate,
      invoice_number: invoiceNumber,
      lines: linesData,
      status: statusInvoiceData,
      tax_rate: taxRate ? parseFloat(taxRate) : 0,
      template: invoiceTemplate ? parseFloat(invoiceTemplate) : 0,
      font_color: fontColor,
      theme: invoiceTheme,
      vat_rate: vatRate ? parseFloat(vatRate) : 0,
      is_send: isSend,
      send_to_email: sendToEmail,
      email_note: emailNote,
      discount_rate: discount ? parseFloat(discount) : 0,
    };

    api
      .post(`${endpoint.INVOICE}`, dataForm, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          toastAlert(res.data.message, 'success');

          setButton('Save & Exit');

          if (res.data.success) {
            setTimeout(() => {
              navigate('/invoice/list/');
            }, 2000);
          }
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postDataInvoiceEdit = async (statusInvoiceData = 'unsend') => {
    if (statusInvoiceData === 'unsend') {
      setButton('Loading...');
    }

    let isSend = false;
    if (statusInvoiceData === 'sent') {
      isSend = true;
    }

    const dataForm = {
      company_id: companyId,
      company_address: '',
      company_email: '',
      customer_id: customerId,
      company_phone: '',
      due_date: dueDate,
      payment_frequency: paymentFrequency,
      number_of_payments: numberOfPayments ? parseFloat(numberOfPayments) : 0,
      first_payment_date: firstPaymentDate,
      invoice_number: invoiceNumber,
      lines: linesData,
      status: statusInvoiceData,
      tax_rate: taxRate ? parseFloat(taxRate) : 0,
      template: invoiceTemplate ? parseFloat(invoiceTemplate) : 0,
      font_color: fontColor,
      theme: invoiceTheme,
      vat_rate: vatRate ? parseFloat(vatRate) : 0,
      is_send: isSend,
      send_to_email: sendToEmail,
      email_note: emailNote,
      discount_rate: discount ? parseFloat(discount) : 0,
    };

    api
      .put(`${endpoint.INVOICE}/${idInvoiceEdit}`, dataForm, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          toastAlert(res.data.message, 'success');

          setButton('Save & Exit');

          if (res.data.success) {
            setTimeout(() => {
              navigate('/invoice/list/');
            }, 2000);
          }
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const deleteServicesInvoice = () => {
    const filterDataServices = linesData.filter((item) => item.id !== idServicesId);

    setLinesData(filterDataServices);

    let subTotalLoop = 0;
    for (let i = 0; i < filterDataServices.length; i += 1) {
      subTotalLoop += filterDataServices[i].price * filterDataServices[i].quantity;
    }

    if (subTotalLoop === 0) {
      setTaxRate(0);
      setVatRate(0);
      setDiscount(0);
    }

    // menghitung discount bedasarkan subtotal
    const discountTotal = (subTotalLoop * discount) / 100;
    const totalDiscount = subTotalLoop - discountTotal;
    // menghitung tax bedasarkan kalkukasi discount dari subtotal
    const iTax = totalDiscount * taxRate;
    const pTax = iTax / 100;
    // menghitung vat bedasarkan kalkukasi discount dari subtotal
    const iVat = totalDiscount * vatRate;
    const pVat = iVat / 100;
    // jumlah grand total
    const grandTotal = totalDiscount + pTax + pVat;

    setSubTotalServices(subTotalLoop);
    setGrandTotalServices(grandTotal);
  };

  const updateServicesInvoice = () => {
    const linePush = [];
    for (let i = 0; i < linesData.length; i += 1) {
      if (idServicesId !== linesData[i].id) {
        linePush.push(linesData[i]);
      }
    }

    setLinesData([...linePush, tempDataServices]);

    const filterDataServices = [...linePush, tempDataServices];

    let subTotalLoop = 0;
    for (let i = 0; i < filterDataServices.length; i += 1) {
      subTotalLoop += filterDataServices[i].price * filterDataServices[i].quantity;
    }

    // menghitung discount bedasarkan subtotal
    const discountTotal = (subTotalLoop * discount) / 100;
    const totalDiscount = subTotalLoop - discountTotal;
    // menghitung tax bedasarkan kalkukasi discount dari subtotal
    const iTax = totalDiscount * taxRate;
    const pTax = iTax / 100;
    // menghitung vat bedasarkan kalkukasi discount dari subtotal
    const iVat = totalDiscount * vatRate;
    const pVat = iVat / 100;
    // jumlah grand total
    const grandTotal = totalDiscount + pTax + pVat;

    setSubTotalServices(subTotalLoop);
    setGrandTotalServices(grandTotal);
  };

  const confirmDelete = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        postDeleteInvoice();
      }
    });
  };

  const postDeleteInvoice = async () => {
    api
      .delete(`${endpoint.INVOICE}/${idInvoice}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          toastAlert(res.data.message, 'success');

          submitSearch();
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataInvoiceDownload = async (idInvoice = null) => {
    getAuthHeader.responseType = 'arraybuffer';

    api
      .get(`${endpoint.INVOICE}/download/${idInvoice}`, getAuthHeader)
      .then((res) => {
        let tempLink = '';

        const data = new Blob([res.data], { type: 'application/pdf' });
        const csvURL = window.URL.createObjectURL(data);

        tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `INV-${idInvoice}.pdf`);
        tempLink.click();
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postStatusMark = async (statusData = '') => {
    const dataForm = {
      company_id: companyId,
      status: statusData !== 'paid' ? 'paid' : 'unsend',
    };

    api
      .put(`${endpoint.INVOICE}/status/${idInvoice}`, dataForm, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          toastAlert(res.data.message, 'success');

          if (res.data.success) {
            setTimeout(() => {
              submitSearch();
            }, 2000);
          }
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const submitSearch = () => {
    getDataInvoice(1, 'filter');
  };

  const countDiscount = (valueDiscount) => {
    setDiscount(valueDiscount);
    setGrandTotalServices(subTotalServices);

    if (valueDiscount >= 0) {
      setDiscount(valueDiscount);

      if (valueDiscount <= 100) {
        const i = subTotalServices * valueDiscount;
        const x = i / 100;
        const p = subTotalServices - x;
        const total = p + totalTaxServices + totalVateTaxServices;

        setGrandTotalServices(total);
      }
    }

    const valueDiscString = valueDiscount.toString();
    if (valueDiscString.length > 3) {
      setDiscount(100);

      const i = subTotalServices * 100;
      const x = i / 100;
      const p = subTotalServices - x;

      setGrandTotalServices(p);
    }

    if (valueDiscount < 0) {
      setDiscount(0);
    }
  };

  const countTax = (value) => {
    setTaxRate(value);

    if (value >= 0) {
      setTaxRate(value);

      const a = subTotalServices * discount;
      const b = a / 100;
      const c = subTotalServices - b;

      const i = c * value;
      const x = i / 100;
      const p = x + c + totalVateTaxServices;

      setTotalTaxServices(x);
      setGrandTotalServices(p);
    }

    if (value < 0) {
      setTaxRate(0);
    }
  };

  const countVat = (value) => {
    setVatRate(value);

    if (value >= 0) {
      setVatRate(value);

      const a = subTotalServices * discount;
      const b = a / 100;
      const c = subTotalServices - b;

      const i = c * value;
      const x = i / 100;
      const p = x + c + totalTaxServices;

      setTotalVateTaxServices(x);
      setGrandTotalServices(p);
    }

    if (value < 0) {
      setVatRate(0);
    }
  };

  return {
    dataCustomer,
    dataServices,
    postDataInvoice,
    button,
    customerId,
    setCustomerId,
    dueDate,
    setDueDate,
    firstPaymentDate,
    setFirstPaymentDate,
    fontColor,
    setFontColor,
    invoiceNumber,
    setInvoiceNumber,
    invoiceTemplate,
    setInvoiceTemplate,
    invoiceTheme,
    setInvoiceTheme,
    linesData,
    setLinesData,
    paymentFrequency,
    setPaymentFrequency,
    paymentFrequencyPer,
    setPaymentFrequencyPer,
    statusInvoice,
    setStatusInvoice,
    taxRate,
    setTaxRate,
    vatRate,
    setVatRate,
    numberOfPayments,
    setNumberOfPayments,
    dataInvoice,
    dataImageInvoice,
    setDataImageInvoice,
    handleAddServices,
    handleChangeServices,
    tempDataServices,
    setTempDataServices,
    handleValueServices,
    subTotalServices,
    grandTotalServices,
    setGrandTotalServices,
    totalTaxServices,
    setTotalTaxServices,
    totalVateTaxServices,
    setTotalVateTaxServices,
    customerSelected,
    setCustomerSelected,
    deleteServicesInvoice,
    updateServicesInvoice,
    setIdServicesId,
    titleModalServices,
    setTitleModalServices,
    confirmDelete,
    idInvoice,
    setIdInvoice,
    openModalDetailInv,
    setOpenModalDetailInv,
    getDataInvoice,
    getDataInvoiceDownload,
    sendToEmail,
    setSendToEmail,
    emailNote,
    setEmailNote,
    viewPayment,
    setViewPayment,
    loadingInvDetail,
    postDataInvoiceEdit,
    getDataInvoiceDetail,
    postStatusMark,
    discount,
    setDiscount,
    dataTotalInvoice,
    setStatusTable,
    submitSearch,
    countDiscount,
    countTax,
    countVat,
  };
};

export default useInvoice;
