import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import Iconify from '../iconify';
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [itemSelected, setItemSelected] = useState({ title: 'dashboard' });

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} setItemSelected={setItemSelected} itemSelected={itemSelected} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  setItemSelected: PropTypes.func,
  itemSelected: PropTypes.object,
};

function NavItem({ item, setItemSelected, itemSelected }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          fontWeight: 'fontWeightBold',
        },
      }}
      onClick={() => setItemSelected(item)}
    >
      {itemSelected.title === title && (
        <Box
          sx={{
            backgroundColor: Colors.WHITE,
            width: 9,
            height: 35,
            position: 'absolute',
            left: -8,
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
          }}
        />
      )}

      <StyledNavItemIcon>
        <Iconify icon={icon} sx={{ color: Colors.WHITE }} />
      </StyledNavItemIcon>

      <ListItemText sx={{ color: Colors.WHITE }} disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
