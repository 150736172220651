// @mui
import { Grid, Container, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import ImageListItem from '@mui/material/ImageListItem';

// ----------------------------------------------------------------------

Template5.propTypes = {
  invoiceNumber: PropTypes.string,
  dueDate: PropTypes.string,
  firstPaymentDate: PropTypes.string,
  customerSelected: PropTypes.array,
};

export default function Template5({ invoiceNumber, dueDate, firstPaymentDate, customerSelected }) {
  const { companyName, companyEmail, companyAddress, companyPhone, companyLogo } = useSelector((states) => ({
    companyName: states?.CompanyReducer?.companyName,
    companyEmail: states?.CompanyReducer?.companyEmail,
    companyAddress: states?.CompanyReducer?.companyAddress,
    companyPhone: states?.CompanyReducer?.companyPhone,
    companyLogo: states?.CompanyReducer?.companyLogo,
  }));

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack spacing={3}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ border: 0 }}>
                  <TableBody>
                    <TableCell style={{ width: 200 }}>
                      <ImageListItem>
                        <img
                          src={'https://cdn.invoiceplus.id/dev/invoiceplus-logo.png'}
                          srcSet={'https://cdn.invoiceplus.id/dev/invoiceplus-logo.png'}
                          alt={'Invoice'}
                          loading="lazy"
                          style={{ width: '100%' }}
                        />
                      </ImageListItem>
                    </TableCell>
                    <TableCell align="right" colSpan={4}>
                      <Typography variant="h6" component="h2">
                        Bill
                      </Typography>
                      Number - {invoiceNumber}
                    </TableCell>
                  </TableBody>
                  <TableBody>
                    <TableCell colSpan={4} align="right">
                      <Typography variant="h6" component="h2">
                        Billing Date: {dueDate}
                      </Typography>
                    </TableCell>
                  </TableBody>
                  <TableBody>
                    <TableCell>
                      <b>Vendor Name:</b>
                      <br />
                      {customerSelected.contact_person}
                    </TableCell>
                    <TableCell>
                      <b>Email:</b>
                      <br />
                      {customerSelected.email}
                    </TableCell>
                    <TableCell>
                      <b>Phone:</b>
                      <br />
                      {customerSelected.email}
                    </TableCell>
                    <TableCell>
                      <b>Company Name:</b>
                      <br />
                      {customerSelected.company_name}
                    </TableCell>
                  </TableBody>
                  <TableBody>
                    <TableCell>
                      <b>Company Name:</b>
                      <br />
                      {companyName}
                    </TableCell>
                    <TableCell>
                      <b>Phone:</b>
                      <br />
                      {companyPhone}
                    </TableCell>
                    <TableCell>
                      <b>Email:</b>
                      <br />
                      {companyEmail}
                    </TableCell>
                    <TableCell>
                      <b>Address:</b>
                      <br />
                      {companyAddress}
                    </TableCell>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
