import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { Card, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  width: 80,
  height: '100%',
  objectFit: 'contain',
});

// ----------------------------------------------------------------------

CardData.propTypes = {
  dataBank: PropTypes.array,
  idPay: PropTypes.string,
};

export default function CardData({ dataBank, idPay }) {
  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item md={4}>
        {dataBank.map((value, index) => (
          <Card
            key={index}
            onClick={() => navigate(`/pay/payment/additional-info?page=pay&&id=${idPay}`)}
            style={{ cursor: 'pointer' }}
          >
            <Grid container>
              <Grid item md={3}>
                <StyledProductImg alt={value.name} src={value.logo} />
              </Grid>
              <Grid item md={9} style={{ marginTop: 10 }}>
                <Typography variant="h6"> &nbsp; {value.name}</Typography>
                <Typography style={{ fontSize: 11 }}>&nbsp;&nbsp; please click and redirect page.</Typography>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Grid>
    </Grid>
  );
}
