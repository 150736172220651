import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
// component
import Card from './Card';

// ----------------------------------------------------------------------

CompanyList.propTypes = {
  dataCompany: PropTypes.array,
  changeCompany: PropTypes.func,
  confirmDeleteCompany: PropTypes.func,
};

export default function CompanyList({ dataCompany, changeCompany, confirmDeleteCompany, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {dataCompany.map((value, index) => (
        <Grid key={value.id} item xs={12} sm={6} md={3}>
          <Card
            value={value}
            confirmDeleteCompany={confirmDeleteCompany}
            balanceData={value.balance ? value.balance : 0}
            changeCompany={changeCompany}
          />
        </Grid>
      ))}
    </Grid>
  );
}
