// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: 'carbon:dashboard',
  },
  {
    title: 'company',
    path: '/company/list',
    icon: 'mdi:company',
  },
  {
    title: 'billing',
    path: '/pay/list',
    icon: 'uiw:pay',
  },
  {
    title: 'invoice',
    path: '/invoice/list',
    icon: 'material-symbols:paid-outline',
  },
  {
    title: 'jasa / produk',
    path: '/services/list',
    icon: 'arcticons:services',
  },
  {
    title: 'contacts',
    path: '/contacts/list',
    icon: 'material-symbols:contacts-outline',
  },
  {
    title: 'payout funds',
    path: '/payout-funds/list',
    icon: 'ant-design:fund-filled',
  },
  {
    title: 'Payout History',
    path: '/payout-history/list',
    icon: 'ri:refund-fill',
  },
  {
    title: 'Transaction',
    path: '/transaction/list',
    icon: 'icon-park-outline:transaction',
  },
  {
    title: 'Support Tickets',
    path: '/ticket/list',
    icon: 'majesticons:tickets',
  },
  {
    title: 'paylater',
    path: '/paylater/list',
    icon: 'material-symbols:paid',
  },
];

export default navConfig;
