const INITIAL_STATE = {
  dataPaymentPayoutFund: {
    available_balance: '',
    charge_amount: '',
    is_pass: '',
    requested_amount: '',
    total_payable: '',
  },
  otpResponse: {
    Date: '',
    Name: '',
    Receiver: '',
  },
};

const payoutfunds = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DATA_PAYMENT_PAYOUT_FUND':
      return {
        ...state,
        dataPaymentPayoutFund: action.data,
      };
    case 'DATA_RESPONSE_OTP':
      return {
        ...state,
        otpResponse: action.data,
      };
    default:
      return state;
  }
};

export default payoutfunds;
