import { Helmet } from 'react-helmet-async';
import { Grid, Container, Stack, Typography, Box, Link, Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
import AddForm from './components/AddForm';
import Colors from '../../constant/colors';
import Iconify from '../../components/iconify';
import { usePaylaterAdd } from './hooks/usePaylater';

export default function PaylaterAddPage() {
  const {
    postDataInstallments,
    handleChangeInstallments,
    button,
    dataRulesTiers,
    dataInstallments,
    amount, 
    setAmount,
    dataRulesFilter,
    setDataRulesFilter,
    loanLateFeeRateTotal, 
    setLoanLateFeeRateTotal,
    copyInvoiceFee, 
    setCopyInvoiceFee,
    dutyStampFee, 
    setDutyStampFee,
    submitRequestOTP,
    otp,
    setOtp,
    buttonModalOtp, 
    modalOtp, 
    setModalOtp,
    otpResponse, 
    companyName,
    setDataRulesTiers
  } = usePaylaterAdd();

  return (
    <>
      <Helmet>
        <title> Paylater: Add | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Form Pengajuan Paylater
            </Typography>
            <Link to="/paylater/list" component={RouterLink} sx={{ display: 'contents' }}>
              <Button startIcon={<Iconify icon="ic:twotone-chevron-left" />} sx={{ color: Colors.WHITE, marginTop: 2 }}>
                Back to list
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7}>
            <AddForm
              handleChangeInstallments={handleChangeInstallments}
              button={button}
              postDataInstallments={postDataInstallments}
              dataRulesTiers={dataRulesTiers}
              dataInstallments={dataInstallments}
              amount={amount}
              setAmount={setAmount}
              setDataRulesFilter={setDataRulesFilter}
              dataRulesFilter={dataRulesFilter}
              loanLateFeeRateTotal={loanLateFeeRateTotal}
              setLoanLateFeeRateTotal={setLoanLateFeeRateTotal}
              copyInvoiceFee={copyInvoiceFee}
              setCopyInvoiceFee={setCopyInvoiceFee}
              dutyStampFee={dutyStampFee}
              setDutyStampFee={setDutyStampFee}
              submitRequestOTP={submitRequestOTP}
              otp={otp}
              setOtp={setOtp}
              buttonModalOtp={buttonModalOtp} 
              modalOtp={modalOtp} 
              setModalOtp={setModalOtp}
              otpResponse={otpResponse}
              companyName={companyName}
              setDataRulesTiers={setDataRulesTiers}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}