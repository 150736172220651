import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../../api/api';
import endpoint from '../../../constant/endpoint';
import toastAlert from '../../../constant/toast';

const useChangePassword = () => {
  const { user, userProfile } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    userProfile: states?.UserReducer?.dataUserProfile,
  }));

  const INITIAL_STATE_CHANGEPASSWORD = {
    email: userProfile?.email,
    new_password: '',
    new_password_repetition: '',
    old_password: '',
  };

  const [dataFormChangePassword, setDataFormChangePassword] = useState(INITIAL_STATE_CHANGEPASSWORD);
  const [button, setButton] = useState('Submit');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const handleChangePassword = async (event) => {
    setDataFormChangePassword({
      ...dataFormChangePassword,
      [event.target.name]: event.target.value,
    });
  };

  const postDataChangePassword = async () => {
    setButton('Loading...');

    if (dataFormChangePassword.new_password !== dataFormChangePassword.new_password_repetition) {
      toastAlert('Password not match!', 'error');
      return;
    }

    api
      .post(endpoint.CHANGE_PASSWORD, dataFormChangePassword, getAuthHeader)
      .then((res) => {
        toastAlert(res.data.message, 'success');

        setButton('Submit');

        if (res.data.success) {
          setTimeout(() => {
            dispatch({
              type: 'DATA_USER_AUTH_EMPTY',
            });

            navigate('/login');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setButton('Submit');
        console.log(err);
      });
  };

  return {
    handleChangePassword,
    postDataChangePassword,
    dataFormChangePassword,
    button,
  };
};

export default useChangePassword;
