const INITIAL_STATE = {
  dataPayment: { address: '', amount: '', type: '' },
};

const payment = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DATA_PAYMENT':
      return {
        ...state,
        dataPayment: action.data,
      };
    case 'EMPTY_DATA_PAYMENT':
      return {
        ...state,
        dataPayment: INITIAL_STATE.dataPayment,
      };
    default:
      return state;
  }
};

export default payment;
