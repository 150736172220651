import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';

// USER
// auth
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import OtpPage from './pages/auth/OtpPage';
import ChangePasswordAuthPage from './pages/auth/change_password/ChangePasswordPage';
// company
import CompanyListPage from './pages/company/CompanyListPage';
import CompanyAddPage from './pages/company/CompanyAddPage';
import CompanyEditPage from './pages/company/CompanyEditPage';

// contact
import ContactListPage from './pages/contact/ContactListPage';
import ContactAddPage from './pages/contact/ContactAddPage';
import ContactEditPage from './pages/contact/ContactEditPage';

// pay
import PayListPage from './pages/pay/PayListPage';
import PayAddPage from './pages/pay/PayAddPage';
import PayEditPage from './pages/pay/PayEditPage';
import PaymentPayListPage from './pages/pay/payment/PaymentPayListPage';
import PayPaymentAdditionalInfoPage from './pages/pay/payment/PayPaymentAdditionalInfoPage';
import PayPaymentPage from './pages/pay/payment/PayPaymentPage';
import PayPaymentChooseBankPage from './pages/pay/payment/PayPaymentChooseBankPage';
import PayPaymentSuccessPage from './pages/pay/payment/PayPaymentSuccessPage';

// ticket
import TicketListPage from './pages/ticket/TicketListPage';
import TicketAddPage from './pages/ticket/TicketAddPage';
import TicketViewPage from './pages/ticket/TicketViewPage';

// services
import ServicesListPage from './pages/services/ServicesListPage';
import ServicesAddPage from './pages/services/ServicesAddPage';
import ServicesEditPage from './pages/services/ServicesEditPage';

// payout funds
import PayoutFundsListPage from './pages/payoutfunds/PayoutFundsListPage';
import PayoutFundsAdditionalPage from './pages/payoutfunds/PayoutFundsAdditionalPage';

// payout history
import PayoutHistoryListPage from './pages/payouthistory/PayoutHistoryListPage';

//  transaction
import TransactionListPage from './pages/transaction/TransactionListPage';

// invoice
import InvoiceAddPage from './pages/invoice/InvoiceAddPage';
import InvoiceEditPage from './pages/invoice/InvoiceEditPage';
import InvoiceListPage from './pages/invoice/InvoiceListPage';

// profile
import ChangePasswordProfilePage from './pages/profile/change-password/ChangePasswordPage';

//  payment
import PaymentListPage from './pages/payment/PaymentListPage';
import PaymentChooseBankPage from './pages/payment/PaymentChooseBankPage';
import PaymentConfirmPage from './pages/payment/PaymentConfirmPage';

// ADMIN
// auth
import LoginAdminPage from './pages/admin/auth/LoginPage';
import ResetAdminPasswordPage from './pages/admin/auth/ResetPasswordPage';

// dashboard
import DashboardAppAdminPage from './pages/admin/dashboard/DashboardAppPage';

// paylater
import PaylaterListPage from './pages/paylater';
import PaylaterAddPage from './pages/paylater/add';
import PaylaterDetailPage from './pages/paylater/detail';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [{ path: 'app', element: <DashboardAppPage /> }],
    },
    {
      path: '/company',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <CompanyListPage />,
        },
        {
          path: 'add',
          element: <CompanyAddPage />,
        },
        {
          path: 'edit',
          element: <CompanyEditPage />,
        },
      ],
    },
    {
      path: '/pay',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <PayListPage />,
        },
        {
          path: 'add',
          element: <PayAddPage />,
        },
        {
          path: 'edit',
          element: <PayEditPage />,
        },
        {
          path: 'payment/list',
          element: <PaymentPayListPage />,
        },
        {
          path: 'payment/additional-info',
          element: <PayPaymentAdditionalInfoPage />,
        },
        {
          path: 'payment/buy',
          element: <PayPaymentPage />,
        },
        {
          path: 'payment/choose-bank',
          element: <PayPaymentChooseBankPage />,
        },
        {
          path: 'payment/success',
          element: <PayPaymentSuccessPage />,
        },
      ],
    },
    {
      path: '/contacts',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <ContactListPage />,
        },
        {
          path: 'add',
          element: <ContactAddPage />,
        },
        {
          path: 'edit',
          element: <ContactEditPage />,
        },
      ],
    },
    {
      path: '/ticket',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <TicketListPage />,
        },
        {
          path: 'add',
          element: <TicketAddPage />,
        },
        {
          path: 'view',
          element: <TicketViewPage />,
        },
      ],
    },

    {
      path: '/services',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <ServicesListPage />,
        },
        {
          path: 'add',
          element: <ServicesAddPage />,
        },
        {
          path: 'edit',
          element: <ServicesEditPage />,
        },
      ],
    },
    {
      path: '/payout-funds',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <PayoutFundsListPage />,
        },
        {
          path: 'additional-info',
          element: <PayoutFundsAdditionalPage />,
        },
      ],
    },
    {
      path: '/payout-history',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <PayoutHistoryListPage />,
        },
      ],
    },
    {
      path: '/transaction',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <TransactionListPage />,
        },
      ],
    },
    {
      path: '/invoice',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <InvoiceListPage />,
        },
        {
          path: 'add',
          element: <InvoiceAddPage />,
        },
        {
          path: 'edit',
          element: <InvoiceEditPage />,
        },
      ],
    },
    {
      path: '/profile',
      element: <DashboardLayout />,
      children: [
        {
          path: 'change-password',
          element: <ChangePasswordProfilePage />,
        },
      ],
    },
    {
      path: '/payment',
      children: [
        {
          path: 'list',
          element: <PaymentListPage />,
        },
        {
          path: 'pay',
          element: <PaymentChooseBankPage />,
        },
        {
          path: 'confirm',
          element: <PaymentConfirmPage />,
        },
      ],
    },
    {
      path: '/paylater',
      element: <DashboardLayout />,
      children: [
        {
          path: 'list',
          element: <PaylaterListPage />,
        },
      ],
    },
    {
      path: '/paylater',
      element: <DashboardLayout />,
      children: [
        {
          path: 'add',
          element: <PaylaterAddPage />,
        },
      ],
    },
    {
      path: '/paylater',
      element: <DashboardLayout />,
      children: [
        {
          path: 'detail',
          element: <PaylaterDetailPage />,
        },
      ],
    },
    

    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'password/reset',
      element: <ResetPasswordPage />,
    },
    {
      path: 'change-password',
      element: <ChangePasswordPage />,
    },
    {
      path: 'otp',
      element: <OtpPage />,
    },
    {
      path: 'password/change',
      element: <ChangePasswordAuthPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/dashboard/app" />, index: true },
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
    // admin route
    {
      path: '/admin',
      children: [
        {
          path: 'login',
          element: <LoginAdminPage />,
        },
        {
          path: 'password/reset',
          element: <ResetAdminPasswordPage />,
        },
      ],
    },
    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        {
          path: 'dashboard/app',
          element: <DashboardAppAdminPage />,
        },
      ],
    },
  ]);

  return routes;
}
