// @mui
import { Grid, Container, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import ImageListItem from '@mui/material/ImageListItem';

// ----------------------------------------------------------------------

Template3.propTypes = {
  invoiceNumber: PropTypes.string,
  dueDate: PropTypes.string,
  firstPaymentDate: PropTypes.string,
  customerSelected: PropTypes.array,
};

export default function Template3({ invoiceNumber, dueDate, firstPaymentDate, customerSelected }) {
  const { companyName, companyEmail, companyAddress, companyPhone, companyLogo } = useSelector((states) => ({
    companyName: states?.CompanyReducer?.companyName,
    companyEmail: states?.CompanyReducer?.companyEmail,
    companyAddress: states?.CompanyReducer?.companyAddress,
    companyPhone: states?.CompanyReducer?.companyPhone,
    companyLogo: states?.CompanyReducer?.companyLogo,
  }));

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack spacing={3}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ border: 0 }}>
                  <TableBody>
                    <TableCell style={{ width: 200 }}>
                      <ImageListItem>
                        <img
                          src={'https://cdn.invoiceplus.id/dev/invoiceplus-logo.png'}
                          srcSet={'https://cdn.invoiceplus.id/dev/invoiceplus-logo.png'}
                          alt={'Invoice'}
                          loading="lazy"
                          style={{ width: '50%' }}
                        />
                      </ImageListItem>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h6" component="h2">
                        Invoice
                      </Typography>
                      Number - {invoiceNumber}
                    </TableCell>
                  </TableBody>

                  <TableBody>
                    <TableCell align="left">
                      <Typography variant="h6" component="h2">
                        Pay To:
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h6" component="h2">
                        Invoiced To:
                      </Typography>
                    </TableCell>
                  </TableBody>
                  <TableBody>
                    <TableCell align="left">{companyName}</TableCell>
                    <TableCell align="right">{customerSelected.contact_person}</TableCell>
                  </TableBody>
                  <TableBody>
                    <TableCell align="left">{companyAddress}</TableCell>
                    <TableCell align="right">{customerSelected.email}</TableCell>
                  </TableBody>
                  <TableBody>
                    <TableCell align="left">{companyPhone}</TableCell>
                    <TableCell align="right">{customerSelected.phone_number}</TableCell>
                  </TableBody>
                  <TableBody>
                    <TableCell align="left">{companyEmail}</TableCell>
                    <TableCell align="right">{customerSelected.company_name}</TableCell>
                  </TableBody>
                  <TableBody>
                    <TableCell colSpan={2} align="right">
                      <Typography variant="h6" component="h2">
                        Date: {dueDate}
                      </Typography>
                    </TableCell>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
