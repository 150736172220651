const INITIAL_STATE = {
  dataCompany: [],
  companyId: 0,
};

const company = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DATA_USER_COMPANY':
      return {
        ...state,
        dataCompany: action.data,
      };
    case 'DATA_USER_COMPANY_ID':
      return {
        ...state,
        companyId: action.data,
      };
    case 'DATA_USER_COMPANY_NAME':
      return {
        ...state,
        companyName: action.data,
      };
    case 'DATA_USER_COMPANY_EMAIL':
      return {
        ...state,
        companyEmail: action.data,
      };
    case 'DATA_USER_COMPANY_PHONE':
      return {
        ...state,
        companyPhone: action.data,
      };
    case 'DATA_USER_COMPANY_ADDRESS':
      return {
        ...state,
        companyAddress: action.data,
      };

    case 'DATA_USER_COMPANY_LOGO':
      return {
        ...state,
        companyLogo: action.data,
      };
    default:
      return state;
  }
};

export default company;
