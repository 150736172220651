import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
import { useState } from 'react';
import ucfirst from 'ucfirst';
import { FormatRupiah } from '@arismun/format-rupiah';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Link,
  Grid,
  Box,
  Autocomplete,
  TextField,
} from '@mui/material';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// @mui
import { ToastContainer } from 'react-toastify';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import Label from '../../components/label';
// sections
import { UserListHead, UserListToolbar } from '../../sections/pay';
// mock
import useInvoice from '../../hooks/pay/usePay';
// constant
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'invoice_number', label: 'Billing Number', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'total', label: 'Total', alignRight: false },
  { id: 'created_at', label: 'Created At', alignRight: false },
  { id: '', label: '', alignRight: false },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.status.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function InvoiceListPage() {
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const {
    dataInvoice,
    confirmDelete,
    idInvoice,
    setIdInvoice,
    openModalDetailInv,
    setOpenModalDetailInv,
    getDataInvoice,
    getDataInvoiceDownload,
    statusInvoice,
    invoiceNumber,
    dueDate,
    subTotalServices,
    totalTaxServices,
    totalVateTaxServices,
    grandTotalServices,
    getDataInvoiceDetail,
    setStatusInvoice,
    postStatusMark,
    pageSizeTable,
    setPageSizeTable,
    setPageTable,
    dataTotalInvoice,
    statusInvoiceTable,
    setStatusTable,
    submitSearch,
  } = useInvoice();

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataInvoice.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    getDataInvoice(newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataInvoice.length) : 0;

  const filteredUsers = applySortFilter(dataInvoice, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Billing: List | InvoicePlus </title>
      </Helmet>

      <ToastContainer />

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Billing
            </Typography>
            <Link to="/pay/add" component={RouterLink} sx={{ display: 'contents' }} mt={5}>
              <Button
                sx={{
                  borderRadius: 20,
                  backgroundColor: Colors.PRIMARY,
                  '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
                  marginTop: 3.5,
                }}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Billing
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Card>
          <Box paddingY={2} paddingX={3} display="flex" alignItems="start">
            <Autocomplete
              id="combo-box-demo"
              options={[
                { label: 'All', id: 'all' },
                { label: 'Pending', id: 'pending' },
                { label: 'Sent', id: 'sent' },
                { label: 'Unsend', id: 'unsend' },
                { label: 'Scheduled', id: 'scheduled' },
                { label: 'Paid', id: 'paid' },
                { label: 'Rejected', id: 'rejected' },
              ]}
              sx={{ width: 300, mr: 4 }}
              onInputChange={(event, newInputValue) => setStatusTable(newInputValue.toLowerCase())}
              renderInput={(params) => <TextField {...params} placeholder="Status" />}
            />

            <Button
              onClick={submitSearch}
              variant="contained"
              size="large"
              startIcon={<Iconify icon="eva:search-fill" sx={{ color: 'white', width: 20 }} />}
            >
              Search
            </Button>
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataInvoice.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, name } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell align="left">{index + 1}.</TableCell>

                        <TableCell align="left">{row.bill_number}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="filled"
                            color={row.status === 'paid' ? 'success' : 'warning'}
                            sx={{
                              textTransform: 'uppercase',
                              color: '#fff',
                            }}
                          >
                            {row.status}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <FormatRupiah value={row.grand_total} />
                        </TableCell>
                        <TableCell align="left">{moment(row.created_at).format('LLLL')}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              handleOpenMenu(event);
                              setIdInvoice(row.id);
                              setStatusInvoice(row.status);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {dataInvoice.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not data to show
                          </Typography>
                          <Typography variant="body2">
                            This log will automatically update &nbsp;
                            <br /> Once users take action within the application.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={dataTotalInvoice}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            getDataInvoiceDetail(idInvoice);
            setOpenModalDetailInv(true);
          }}
        >
          Detail
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(false);
            postStatusMark(statusInvoice);
          }}
        >
          {statusInvoice !== 'paid' ? 'Mark as paid' : 'Mark as unpaid'}
        </MenuItem>
        {statusInvoice !== 'paid' && (
          <MenuItem>
            <Link
              to={`/pay/payment/list?page=pay&&id=${idInvoice}`}
              component={RouterLink}
              sx={{ display: 'contents', fontSize: 14 }}
            >
              Send Payment
            </Link>
          </MenuItem>
        )}

        {statusInvoice !== 'paid' && (
          <MenuItem onClick={() => navigate(`/pay/edit?bill_id=${idInvoice}`)}>Edit</MenuItem>
        )}

        <MenuItem onClick={getDataInvoiceDownload}>Download PDF</MenuItem>

        {statusInvoice !== 'paid' && (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={() => {
              setOpen(false);
              confirmDelete();
            }}
          >
            Delete
          </MenuItem>
        )}
      </Popover>

      <BootstrapDialog
        onClose={() => {
          setOpen(false);
          setOpenModalDetailInv(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openModalDetailInv}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Bill</DialogTitle>
        <DialogContent dividers style={{ width: 600 }}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography gutterBottom>Status</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="h6" component="h2" style={{ textAlign: 'right' }}>
                {`${ucfirst(statusInvoice)}`}
              </Typography>
            </Grid>
            {statusInvoice !== 'paid' && (
              <>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography gutterBottom>
                    Request Link
                    <Iconify color="#2065D1" icon="mdi:file-outline" />
                  </Typography>
                </Grid>
                <Grid item md={4} />
                <Grid item md={2}>
                  <Link
                    to={`/pay/payment/list?page=pay&&id=${idInvoice}`}
                    component={RouterLink}
                    sx={{ display: 'contents', fontSize: 12 }}
                  >
                    Bill Link
                  </Link>
                </Grid>
              </>
            )}
            <Grid item xs={6} md={6} lg={6}>
              <Typography gutterBottom>Bill Number</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="h6" component="h2" style={{ textAlign: 'right' }}>
                {invoiceNumber}
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography gutterBottom>Due Date</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="h6" component="h2" style={{ textAlign: 'right' }}>
                {dueDate}
              </Typography>
            </Grid>
            {/* <Grid item xs={6} md={6} lg={6}>
              <Typography gutterBottom>Invoice Issue Date</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="h6" component="h2" style={{ textAlign: 'right' }}>
                28/06/2023
              </Typography>
            </Grid> */}
            <Grid item xs={6} md={6} lg={6}>
              <Typography gutterBottom>Subtotal</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="h6" component="h2" style={{ textAlign: 'right' }}>
                <FormatRupiah value={subTotalServices} />
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography gutterBottom>Tax</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="h6" component="h2" style={{ textAlign: 'right' }}>
                <FormatRupiah value={totalTaxServices} />
              </Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography gutterBottom>Vat</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="h6" component="h2" style={{ textAlign: 'right' }}>
                <FormatRupiah value={totalVateTaxServices} />
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <hr />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography gutterBottom>Total Pay</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="h6" component="h2" style={{ textAlign: 'right' }}>
                <FormatRupiah value={grandTotalServices} />
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpen(false);
              setOpenModalDetailInv(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
