import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// components
import { List } from '../../sections/payoutfunds';
// mock
import usePayoutFunds from '../../hooks/payoutfunds/usePayoutFunds';
// constant
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

export default function CompanyListPage() {
  const {
    dataBank,
    loadingPayoutFund,
    submitRequestOTP,
    open,
    setOpen,
    fullWidth,
    maxWidth,
    setAmountData,
    nameCompany,
    balanceCompany,
    informationPayout,
  } = usePayoutFunds();

  return (
    <>
      <Helmet>
        <title> Payout Funds: List | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              How Do You Want To Payout Fund
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <List
          dataBank={dataBank}
          submitRequestOTP={submitRequestOTP}
          open={open}
          setOpen={setOpen}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          setAmountData={setAmountData}
          loadingPayoutFund={loadingPayoutFund}
          nameCompany={nameCompany}
          balanceCompany={balanceCompany}
          informationPayout={informationPayout}
        />
      </Container>
    </>
  );
}
