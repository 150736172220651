import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// sections
import { List } from '../../sections/payment';
import usePayment from '../../hooks/payment/usePayment';
// ----------------------------------------------------------------------

export default function ChangePasswordPage() {
  const {
    dataBank,
    button,
    handleSelectedBank,
    total,
    charge,
    payable,
    paramsPage,
    paramsId,
    dataListPayment,
    postStatusReject,
    loadingRejected,
    getDataInvoiceDownload,
    user,
    imageLogo,
  } = usePayment();

  return (
    <>
      <Helmet>
        <title> Payment: List | InvoicePlus </title>
      </Helmet>

      <Container>
        <ToastContainer />

        <List
          dataBank={dataBank}
          button={button}
          handleSelectedBank={handleSelectedBank}
          total={total}
          charge={charge}
          payable={payable}
          paramsPage={paramsPage}
          paramsId={paramsId}
          dataListPayment={dataListPayment}
          postStatusReject={postStatusReject}
          loadingRejected={loadingRejected}
          getDataInvoiceDownload={getDataInvoiceDownload}
          accessToken={user.access_token}
          imageLogo={imageLogo}
        />
      </Container>
    </>
  );
}
