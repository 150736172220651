import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../../api/api';
import endpoint from '../../../constant/endpoint';
import toastAlert from '../../../constant/toast';

const useLogin = () => {
  const [username, setUsername] = useState('');
  const [passwordData, setPasswordData] = useState('');
  const [button, setButton] = useState('Login');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserAdminReducer?.dataUserAdminAuth,
  }));

  useEffect(() => {
    if (user.access_token) {
      navigate('/admin/dashboard/app/');
    }
  }, []);

  const postLogin = async () => {
    setButton('Loading...');

    const data = {
      email: username,
      password: passwordData,
    };

    api
      .post(endpoint.POST_ADMIN_LOGIN, data)
      .then((res) => {
        if (res.data.success) {
          getDataProfile(res.data.data.access_token);
          dispatch({
            type: 'DATA_USER_ADMIN_AUTH',
            data: res.data.data,
          });
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }

        setButton('Login');
        console.log(err);
      });
  };

  const getDataProfile = async (token = '') => {
    const getAuthHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .get(endpoint.GET_ADMIN_PROFILE, getAuthHeader)
      .then((res) => {
        setButton('Login');

        if (res.data.success) {
          dispatch({
            type: 'DATA_USER_ADMIN_PROFILE',
            data: res.data.data,
          });

          navigate('/admin/dashboard/app/');
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }
        setButton('Login');
        console.log(err);
      });
  };

  return {
    username,
    setUsername,
    passwordData,
    setPasswordData,
    postLogin,
    button,
  };
};

export default useLogin;
