import PropTypes from 'prop-types';
// @mui
import { Card, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  width: 80,
  height: '100%',
  objectFit: 'contain',
});

// ----------------------------------------------------------------------

CardData.propTypes = {
  value: PropTypes.object,
};

export default function CardData({ value }) {
  const { name, logo } = value;

  return (
    <Card>
      <Grid container>
        <Grid item md={3}>
          <StyledProductImg alt={name} src={logo} />
        </Grid>
        <Grid item md={9} style={{ marginTop: 10 }}>
          <Typography variant="h6"> &nbsp; {name}</Typography>
          <Typography style={{ fontSize: 11 }}> &nbsp;&nbsp; please click and give amount to payout fund.</Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
