import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
import { useState } from 'react';
import { FormatRupiah } from '@arismun/format-rupiah';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Box,
} from '@mui/material';
import moment from 'moment';
// @mui
import { ToastContainer } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import Label from '../../components/label';
// sections
import { UserListHead, UserListToolbar } from '../../sections/payouthistory';
// mock
import usePayoutHistory from '../../hooks/payouthistory/usePayoutHistory';
// constant
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'transaction_id', label: 'Transaction ID', alignRight: false },
  { id: 'gateway', label: 'Gateway', alignRight: false },
  { id: 'amount_request', label: 'Amount Request', alignRight: false },
  { id: 'charge', label: 'Charge', alignRight: false },
  { id: 'amount_payment', label: 'Amount Payment', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: 'detail', label: 'Detail', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.payment_method.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PayoutHistoryListPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    dataPayoutHistory,
    openModal,
    setOpenModal,
    fullWidth,
    maxWidth,
    dataPayoutSelected,
    setSelectedDataPayout,
    getDataPayoutHistory,
    dataTotalTable,
  } = usePayoutHistory();

  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataPayoutHistory.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getDataPayoutHistory(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataPayoutHistory.length) : 0;

  const filteredUsers = applySortFilter(dataPayoutHistory, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Payout Log: List | InvoicePlus </title>
      </Helmet>
      <ToastContainer />

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Payout History
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataPayoutHistory.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, name } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    let buttonColorStatus = 'primary';
                    if (row.status === 'complete') {
                      buttonColorStatus = 'success';
                    } else if (row.status === 'pending') {
                      buttonColorStatus = 'warning';
                    }

                    let buttonColorType = 'primary';
                    if (row.type === 'vendor') {
                      buttonColorType = 'info';
                    }

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell align="left">{index + 1}.</TableCell>

                        <TableCell>{row.transaction_id.toUpperCase()}</TableCell>
                        <TableCell>{row.payment_method}</TableCell>
                        <TableCell>
                          <b>
                            <FormatRupiah value={row.amount} />
                          </b>
                        </TableCell>
                        <TableCell style={{ width: 120 }}>
                          <p style={{ color: 'red' }}>
                            <b>Rp -{new Intl.NumberFormat().format(row.fee)}</b>
                          </p>
                        </TableCell>
                        <TableCell>
                          <b>
                            <FormatRupiah value={row.amount - row.fee} />
                          </b>
                        </TableCell>
                        <TableCell>
                          <Label
                            variant="filled"
                            color={buttonColorStatus}
                            sx={{
                              zIndex: 9,
                              top: 16,
                              right: 16,
                              color: '#fff',
                            }}
                          >
                            {row.status}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <Label
                            variant="filled"
                            color={buttonColorType}
                            sx={{
                              zIndex: 9,
                              top: 16,
                              right: 16,
                              color: '#fff',
                            }}
                          >
                            {row.type}
                          </Label>
                        </TableCell>
                        <TableCell>{moment(row.created_at).format('LLLL')}</TableCell>

                        <TableCell>
                          <Label
                            variant="filled"
                            color={'primary'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedDataPayout(row);
                              setOpenModal(true);
                            }}
                          >
                            <Iconify style={{ color: '#fff' }} icon={'eva:info-fill'} />
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {dataPayoutHistory.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not data to show
                          </Typography>
                          <Typography variant="body2">
                            This log will automatically update &nbsp;
                            <br /> Once users take action within the application.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={dataTotalTable}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Transactions : {dataPayoutSelected.transaction_id}</DialogContentText>
          <DialogContentText>
            Admin Feedback : {dataPayoutSelected.admin_note ? dataPayoutSelected.admin_note : '-'}
          </DialogContentText>
          <hr />
          <br />
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            Payment Information
          </Typography>
          <DialogContentText>Name : {dataPayoutSelected.account_name}</DialogContentText>
          <DialogContentText>AccountNumber : {dataPayoutSelected.account_number}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
