import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../../api/api';
import endpoint from '../../../constant/endpoint';
import toastAlert from '../../../constant/toast';

const usePaymentPay = () => {
  const INITIAL_STATE_DATA_PAYOUT = [
    {
      id: 1,
      bank_code: '',
      fee: '',
      fee_type: '',
      name: 'Bank Transfer',
      swift: '',
      logo: 'https://cdn.invoiceplus.id/dev/044149547dd9c161920828642873e18e4fa28560fe25d49adda647b95c4a216cc4e135f7caf35453ca52555ce8b2528cc0.jpg',
    },
  ];

  const [dataBank, setDataBank] = useState(INITIAL_STATE_DATA_PAYOUT);
  const [dataBankMethod, setDataBankMethod] = useState([]);
  const [dataAmount, setDataAmount] = useState({
    amount: 0,
    charge_amount: 0,
    total_payable: 0,
  });
  const [dataFormAdditional, setDataFormAdditional] = useState({
    account_name: '',
    account_number: '',
    bank_name: '',
  });
  const [dataFormPayment, setDataFormPayment] = useState({
    address: '',
    id: '',
    payment_type: '',
    type: 'Bill',
    logo: '',
  });
  const [dataDetailPayment, setDataDetailPayment] = useState({
    app_fee: 0,
    fixed_charge: 0,
    gateway_charge: 0,
    percent_charge: 0,
    subtotal: 0,
    tax: 0,
    total: 0,
    vat: 0,
  });
  const [idPay, setIdPay] = useState('0');
  const [dataBankData, setDataBankData] = useState([]);
  const [loadingPayoutFund, setLoadingPayoutFund] = useState('Next');
  const [buttonAdditional, setButtonAdditional] = useState('Submit');
  const [amountData, setAmountData] = useState(0);
  const [button, setButton] = useState('Submit');
  const [loadingPayment, setLoadingPayment] = useState('Pay Now');
  const [dataBankSelected, setDataBankSelected] = useState({
    bank_code: '',
    fee: 0,
    fee_type: '',
    is_selected: false,
    logo: '',
    name: '',
    swift: '',
  });
  const [dataWithdraw, setDataWithdraw] = useState({
    Amount: 0,
    Charge: 0,
    TotalPayable: 0,
  });

  const [myParamLogoData, setMyParamLogoData] = useState('');
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('xs');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId, paymentPayoutFund } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
    paymentPayoutFund: states?.PayoutFundsReducer?.dataPaymentPayoutFund,
  }));

  const [formPayout, setFormPayout] = useState({
    account_name: '',
    account_number: '',
    amount: parseFloat(paymentPayoutFund.total_payable),
    bank_name: '0',
    company_id: companyId,
  });

  useEffect(() => {
    getDataBank();
    getDataBankMethod();

    const urlParams = new URLSearchParams(window.location.search);
    const myParamId = urlParams.get('id');
    const myParamLogo = urlParams.get('logo');

    setMyParamLogoData(myParamLogo);

    if (myParamId) {
      setIdPay(myParamId);
      getDataInvoiceDetail(myParamId);
      getDataWithdraw(myParamId);
    }
  }, []);

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const getDataBank = async () => {
    api
      .get(`${endpoint.PAYOUTS}/method`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setDataBankData(res.data.data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataInvoiceDetail = async (myParamId = '') => {
    api
      .get(`${endpoint.BILL}/${myParamId}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const dataInv = res.data.data;

          setDataAmount({
            amount: dataInv.subtotal,
            charge_amount: dataInv.grand_total - dataInv.subtotal,
            total_payable: dataInv.grand_total,
          });
        }
      })
      .catch((err) => {
        // setLoadingInvDetail(true);
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataWithdraw = async (myParamId = '') => {
    api
      .get(`${endpoint.BILL}/withdraw-info/${myParamId}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setDataWithdraw(res.data.data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleChangeAdditionalForm = async (event) => {
    setDataFormAdditional({
      ...dataFormAdditional,
      [event.target.name]: event.target.value,
    });
  };

  const submitPaymentAditional = async () => {
    setButtonAdditional('Loading...');

    api
      .post(`${endpoint.BILL}/payout/${idPay}/submit`, dataFormAdditional, getAuthHeader)
      .then((res) => {
        setButtonAdditional('Submit');

        toastAlert(res.data.message, 'success');

        if (res.data.success) {
          setTimeout(() => {
            navigate(`/pay/payment/choose-bank?page=pay&&id=${idPay}`);
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
        setButtonAdditional('Submit');
      });
  };

  const getDataBankMethod = async () => {
    api
      .get(`${endpoint.PAYMENT}/method`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const dataBank = res.data.data;

          for (let i = 0; i < dataBank.length; i += 1) {
            dataBank[i].is_selected = false;
          }
          setDataBankMethod(dataBank);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataDetailPayment = async (paymentCode = '') => {
    api
      .get(`${endpoint.BILL}/detail-payment/${idPay}/${paymentCode}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          setDataDetailPayment(res.data.data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleSelectedBank = async (value, index) => {
    let paymentType = '';
    if (value.bank_code === 'qris') {
      paymentType = 'qris';
    } else if (value.bank_code === 'cc') {
      paymentType = 'cc';
    } else {
      paymentType = 'va';
    }

    let addressData = value.bank_code;
    if (value.bank_code === 'qris' || value.bank_code === 'cc') {
      addressData = '';
    }

    setDataFormPayment({
      ...dataFormPayment,
      address: addressData,
      id: idPay,
      payment_type: paymentType,
      logo: value.logo,
    });

    for (let i = 0; i < dataBankMethod.length; i += 1) {
      dataBankMethod[i].is_selected = false;
    }

    dataBankMethod[index].is_selected = true;

    setDataBankMethod(dataBankMethod);
    setDataBankSelected(value);
    getDataDetailPayment(value.bank_code);
  };

  const postPayment = async () => {
    setLoadingPayment('Loading...');

    api
      .post(`${endpoint.PAYMENT}`, dataFormPayment, getAuthHeader)
      .then((res) => {
        setLoadingPayment('Pay Now');
        if (res.data.success) {
          toastAlert(res.data.message, 'success');
          const dataPayment = res.data.data;

          if (dataPayment.type === 'cc') {
            window.open(dataPayment.address);
          }

          setTimeout(() => {
            if (dataPayment.type === 'qris' || dataPayment.type === 'va') {
              navigate(
                `/pay/payment/success?page=pay&&paymenttype=${
                  dataFormPayment.payment_type === 'qris' ? 'qris' : 'va'
                }&&id=${idPay}&&logo=${dataFormPayment.logo}`
              );
            }
          }, 2000);

          dispatch({
            type: 'DATA_PAYMENT',
            data: res.data.data,
          });
        }
      })
      .catch((err) => {
        setLoadingPayment('Submit');
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  return {
    dataBank,
    loadingPayoutFund,
    button,
    open,
    setOpen,
    fullWidth,
    maxWidth,
    setAmountData,
    paymentPayoutFund,
    dataBankData,
    formPayout,
    handleChangeAdditionalForm,
    submitPaymentAditional,
    buttonAdditional,
    idPay,
    dataBankMethod,
    dataBankSelected,
    handleSelectedBank,
    dataAmount,
    dataFormAdditional,
    getDataDetailPayment,
    dataDetailPayment,
    postPayment,
    loadingPayment,
    dataFormPayment,
    setDataFormPayment,
    dispatch,
    myParamLogoData,
    dataWithdraw,
  };
};

export default usePaymentPay;
