import { Helmet } from 'react-helmet-async';
import { Grid, Container, Stack, Typography, Box, Link, Button, CircularProgress } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';

import CardDetailInfo from './components/CardDetailInfo';
import CardDetailInstallmentPayment from './components/CardDetailInstallmentPayment';
import Colors from '../../constant/colors';
import Iconify from '../../components/iconify';
import { usePaylaterDetail } from './hooks/usePaylater';

export default function PaylaterDetailPage() {
  const { 
    dataDetailInstallments, 
    loadingDataDetailInstallments, 
    handleChangeInstallments, 
    idDetailInstallment, 
    setDetailIdInstallment,
    totalAmount, 
    setTotalAmount,
    paidAt, 
    setPaidAt,
    dateDefault,
    acceleratedRepaymentFee,
    setAcceleratedRepaymentFee,
    postDataInstallments,
    loadingPostInstallment
    } = usePaylaterDetail();

  return (
    <>
      <Helmet>
        <title> Paylater: Detail | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Detail Paylater
            </Typography>
            <Link to="/paylater/list" component={RouterLink} sx={{ display: 'contents' }}>
              <Button startIcon={<Iconify icon="ic:twotone-chevron-left" />} sx={{ color: Colors.WHITE, marginTop: 2 }}>
                Back to list
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid container spacing={3}>
          {loadingDataDetailInstallments && 
            <Grid item xs={12} md={6} lg={7}>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </Grid>
          }
          {! loadingDataDetailInstallments && dataDetailInstallments &&
            <>
              <Grid item xs={12} md={6} lg={7}>
                <CardDetailInfo title="Paylater Detail" dataDetailInstallments={dataDetailInstallments} />
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <CardDetailInstallmentPayment 
                title="Installment Payment" 
                dataDetailInstallments={dataDetailInstallments} 
                handleChangeInstallments={handleChangeInstallments} 
                idDetailInstallment={idDetailInstallment} 
                setDetailIdInstallment={setDetailIdInstallment}
                totalAmount={totalAmount} 
                setTotalAmount={setTotalAmount}
                paidAt={paidAt} 
                setPaidAt={setPaidAt}
                dateDefault={dateDefault}
                acceleratedRepaymentFee={acceleratedRepaymentFee}
                setAcceleratedRepaymentFee={setAcceleratedRepaymentFee}
                postDataInstallments={postDataInstallments}
                loadingPostInstallment={loadingPostInstallment}
                />
              </Grid>
            </>
          }
        </Grid>
      </Container>
    </>
  );
}