// @mui
import PropTypes from 'prop-types';
import { Box, Card, TextField, Stack, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

AddForm.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  dataFormTicket: PropTypes.func,
  loadingImage: PropTypes.bool,
  postDataFileImage: PropTypes.func,
};

export default function AddForm({
  title,
  subheader,
  handleChangeTicket,
  postDataTicket,
  dataFormTicket,
  button,
  loadingImage,
  postDataFileImage,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="subject"
                  label="Subject"
                  value={dataFormTicket.subject}
                  onChange={(e) => handleChangeTicket(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="message"
                  label="Message"
                  multiline
                  rows={6}
                  maxRows={4}
                  value={dataFormTicket.message}
                  onChange={(e) => handleChangeTicket(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Upload File"
                  type="file"
                  onChange={(event) => postDataFileImage(event)}
                />
              </Stack>
              {loadingImage && (
                <Typography sx={{ color: '#adadad' }} variant="body2">
                  Loading upload...
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={postDataTicket}>
                  {button}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
