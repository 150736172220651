import PropTypes, { func } from 'prop-types';
import {
  Box,
  Card,
  TextField,
  Stack,
  CardContent,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import CurrencyTextField from '@lupus-ai/mui-currency-textfield';
import OtpInput from 'react-otp-input';

import Colors from '../../../constant/colors';

AddForm.propTypes = {
  postDataInstallments: PropTypes.func,
  handleChangeInstallments: PropTypes.func,
  button: PropTypes.bool,
  dataRulesTiers: PropTypes.array,
  dataInstallments: PropTypes.object,
  amount: PropTypes.func,
  setAmount: PropTypes.func,
  setDataRulesFilter: PropTypes.func,
  dataRulesFilter: PropTypes.func,
  loanLateFeeRateTotal: PropTypes.func,
  setLoanLateFeeRateTotal: PropTypes.func,
  copyInvoiceFee: PropTypes.func,
  setCopyInvoiceFee: PropTypes.func,
  dutyStampFee: PropTypes.func,
  setDutyStampFee: PropTypes.func,
  submitRequestOTP: PropTypes.func,
  otp: PropTypes.func,
  setOtp: PropTypes.func,
  buttonModalOtp: PropTypes.func, 
  modalOtp: PropTypes.func, 
  setModalOtp: PropTypes.func,
  otpResponse: PropTypes.object,
  companyName: PropTypes.string,
  setDataRulesTiers: PropTypes.func
};

export default function AddForm({
  postDataInstallments, 
  handleChangeInstallments,
  button,
  dataRulesTiers,
  dataInstallments,
  amount,
  setAmount,
  dataRulesFilter,
  setDataRulesFilter,
  loanLateFeeRateTotal,
  setLoanLateFeeRateTotal,
  copyInvoiceFee,
  setCopyInvoiceFee,
  dutyStampFee,
  setDutyStampFee,
  submitRequestOTP,
  otp,
  setOtp,
  buttonModalOtp, 
  modalOtp, 
  setModalOtp,
  otpResponse,
  companyName,
  setDataRulesTiers,
  ...other
}) {
  // const [open, setOpen] = useState(null);
  const fullWidth = true;

  return (
    <Card {...other}>
      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <CurrencyTextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="amount"
                  label="Amount"
                  variant="outlined"
                  value={amount}
                  currencySymbol="Rp."
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={(event, value)=> {
                    handleChangeInstallments(event, value);
                    setAmount(value);

                    if (dataRulesTiers.length > 0 && loanLateFeeRateTotal) {
                      setLoanLateFeeRateTotal(0);
                      setCopyInvoiceFee(0);
                      setDutyStampFee(0);
                      setDataRulesTiers([]);
                    }

                    if (! value) {
                      setDataRulesFilter({
                        id: "",
                        loan_type: "",
                        loan_period: 0,
                        loan_fee_rate: 0,
                        loan_late_fee_rate: 0,
                        accelerated_repayment_fee: 0,
                        copy_invoice_fee: 0,
                        duty_stamp_fee: 0,
                        duty_stamp_threshold: 0
                      });
                    }
                  }}
                />
              </Stack>
            </Grid>
            {dataInstallments.amount ? <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Installment</FormLabel>
                  {dataRulesTiers.map((value, index) => (
                    <RadioGroup key={index} aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel
                        value={value?.id}
                        control={<Radio checked={value?.id === dataRulesFilter?.id} />}
                        label={value?.installments}
                        onChange={() => {
                          // amount * (loan_late_fee_rate / 100)
                          const lateFee = (parseFloat(amount) * value?.loan_late_fee_rate) / 100;
                          setDataRulesFilter(value);
                          setLoanLateFeeRateTotal(Math.ceil(lateFee));
                          setCopyInvoiceFee(value?.copy_invoice_fee);
                          setDutyStampFee(amount >= value?.duty_stamp_threshold ? value?.copy_invoice_fee : 0);
                        }}
                      />
                    </RadioGroup>
                  )
                  )}
                </FormControl>
              </Stack>
            </Grid> : null }
            
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="message"
                  label="Late Fee (Rp)"
                  type="text"
                  value={new Intl.NumberFormat().format(loanLateFeeRateTotal)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="message"
                  label="Biaya Pelunasan Dipercepat (Rp)"
                  type="text"
                  value={new Intl.NumberFormat().format(amount)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="message"
                  label="Biaya Copy Faktur (Rp)"
                  type="text"
                  value={new Intl.NumberFormat().format(copyInvoiceFee)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="message"
                  label="Biaya Materai (Rp)"
                  type="text"
                  value={new Intl.NumberFormat().format(dutyStampFee)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <Button
                  disabled={button ? true : null}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={submitRequestOTP}
                  sx={{
                    borderRadius: 20,
                    backgroundColor: Colors.PRIMARY,
                    '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
                  }}
                >
                  {button ? 'Loading...' : 'Ajukan Paylater'}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <Dialog fullWidth={fullWidth} maxWidth={'xs'} open={modalOtp}>
        <DialogTitle style={{ fontSize: 16, fontWeight: 400 }}>
          Kode OTP: Permintaan paylater untuk company <span style={{ color: '#557aff' }}>{companyName}</span>, Telah
          dikirim ke email : <span style={{ color: '#557aff' }}>{otpResponse?.Receiver}</span> pada {otpResponse?.Date}
        </DialogTitle>
        <DialogContent>
          <OtpInput
            inputStyle={{ width: 50, height: 40 }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOtp(false)}>
            Close
          </Button>
          <LoadingButton loading={buttonModalOtp} variant="contained" onClick={postDataInstallments}>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
