import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FormatRupiah } from '@arismun/format-rupiah';
// @mui
import { Card, CardContent, Button, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

VirtualAccount.propTypes = {
  paramsPage: PropTypes.string,
  dataPayment: PropTypes.object,
  dispatch: PropTypes.object,
  paramsLogo: PropTypes.string,
  user: PropTypes.any,
};

const StyledProductImg = styled('img')({
  width: '150px',
  height: '150px',
  objectFit: 'contain',
});

export default function VirtualAccount({ dataPayment, dispatch, paramsLogo, user }) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Card sx={{ minWidth: 350 }}>
          <CardContent>
            <center>
              <Typography variant="h3">Please Pay</Typography>
              <Typography variant="subtitle1" style={{ color: '#1890FF' }}>
                No VA. {dataPayment.address}
              </Typography>
              <Typography variant="h4">
                <FormatRupiah value={dataPayment.amount} />
              </Typography>
              <StyledProductImg alt={'VA'} src={paramsLogo} />
              <Button
                sx={{ width: '100%' }}
                onClick={() => {
                  if (user.access_token) {
                    navigate(`/invoice/list`, { replace: true });

                    dispatch({
                      type: 'EMPTY_DATA_PAYMENT',
                    });
                  }
                }}
                variant="outlined"
                size="large"
                color="info"
              >
                Done Payment
              </Button>
            </center>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
