import { useState } from 'react';
// @mui
import PropTypes from 'prop-types';
import { Card, CardContent, Box, Grid, Stack, TextField, MenuItem, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import OtpInput from 'react-otp-input';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

PayoutForm.propTypes = {
  dataBankPayout: PropTypes.array,
  formPayout: PropTypes.object,
  handleChangePayout: PropTypes.func,
  loadingPayoutBalance: PropTypes.bool,
  submitPaymentPayoutFunds: PropTypes.func,
  setOtp: PropTypes.func,
  otp: PropTypes.string,
  otpResponse: PropTypes.object,
  companyName: PropTypes.string,
};

export default function PayoutForm({
  dataBankPayout,
  formPayout,
  handleChangePayout,
  loadingPayoutBalance,
  submitPaymentPayoutFunds,
  setOtp,
  otp,
  otpResponse,
  companyName,
}) {
  const [open, setOpen] = useState(false);
  const fullWidth = true;

  return (
    <Card>
      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Bank Name"
                  name="bank_name"
                  select
                  value={formPayout.bank_name}
                  onChange={(e) => handleChangePayout(e)}
                >
                  <MenuItem key={'0'} value={'0'}>
                    Select
                  </MenuItem>

                  {dataBankPayout.map((row, index) => (
                    <MenuItem key={index} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="bank_branch"
                  label="Bank Branch"
                  value={formPayout.bank_branch}
                  onChange={(e) => handleChangePayout(e)}
                />
              </Stack>
            </Grid>

            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="account_name"
                  label="Acount Name"
                  value={formPayout.account_name}
                  onChange={(e) => handleChangePayout(e)}
                />
              </Stack>
            </Grid>
            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="account_number"
                  label="Account Number"
                  value={formPayout.account_number}
                  onChange={(e) => handleChangePayout(e)}
                />
              </Stack>
            </Grid>
            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={() => setOpen(true)}>
                  Confirm
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>

      <Dialog fullWidth={fullWidth} maxWidth={'xs'} open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ fontSize: 16, fontWeight: 400 }}>
          Kode OTP: Permintaan tarik dana untuk company <span style={{ color: '#557aff' }}>{companyName}</span>, Telah
          dikirim ke email : <span style={{ color: '#557aff' }}>{otpResponse?.Receiver}</span> pada {otpResponse?.Date}
        </DialogTitle>
        <DialogContent>
          <OtpInput
            inputStyle={{ width: 50, height: 40 }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={submitPaymentPayoutFunds}>
            {loadingPayoutBalance}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
