import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
import { useState } from 'react';
import { FormatRupiah } from '@arismun/format-rupiah';
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Link,
  Box,
  Chip
} from '@mui/material';
import ucfirst from 'ucfirst';
import { ToastContainer } from 'react-toastify';

import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListHead } from '../../sections/invoice';
import {usePaylaterList} from './hooks/usePaylater';
import Colors from '../../constant/colors';

const TABLE_HEAD = [
  { id: 'amount', label: 'Amount', alignRight: false },
  { id: 'loan_type', label: 'Loan Type', alignRight: false },
  { id: 'loan_period', label: 'Loan Period', alignRight: false },
  { id: 'loan_fee', label: 'Loan Fee', alignRight: false },
  { id: 'biaya_pelunasan_dipercepat', label: 'Biaya Pelunasan Dipercepat', alignRight: false },
  { id: 'biaya_copy_faktur', label: 'Biaya Copy Faktur', alignRight: false },
  { id: 'biaya_materai', label: 'Biaya Materai', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.status.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PaylaterListPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [idData, setIdData] = useState(0);


  const navigate = useNavigate();

  const {
    dataInstallments,
    dataTotalInstallments,
    getDataInstallments
  } = usePaylaterList();

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataInstallments.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    getDataInstallments(newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataInstallments.length) : 0;

  const filteredUsers = applySortFilter(dataInstallments, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  // 0 = requested 
  // 1 = approved 
  // 2 = rejected 
  // 3 = success 
  const validationStatusView = (valueData) => {
    let colors = 'warning';
    let value = 'Requested';
    if (valueData === 1) {
      colors = 'info';
      value = 'Approved';
    }
    if (valueData === 2) {
      colors = 'danger';
      value = 'Rejected';
    }
    if (valueData === 3) {
      colors = 'success';
      value = 'Success';
    }
    return (
      <Chip label={value} color={colors} />
    );
  }

  return (
    <>
      <Helmet>
        <title> Paylater: List | InvoicePlus </title>
      </Helmet>

      <ToastContainer />

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
            Paylater
            </Typography>
            <Link to="/paylater/add" component={RouterLink} sx={{ display: 'contents' }} mt={5}>
              <Button
                sx={{
                  borderRadius: 20,
                  backgroundColor: Colors.PRIMARY,
                  '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
                  marginTop: 3.5,
                }}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Paylater
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataInstallments.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, name } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell align="left">{index + 1}.</TableCell>
                        <TableCell align="left"><FormatRupiah value={row.amount} /></TableCell>
                        <TableCell align="left">{ucfirst(row.loan_type)}</TableCell>
                        <TableCell align="left">{row.loan_period}</TableCell>
                        <TableCell align="left"><FormatRupiah value={row.loan_fee} /></TableCell>
                        <TableCell align="left"><FormatRupiah value={row.accelerated_repayment_fee} /></TableCell>
                        <TableCell align="left"><FormatRupiah value={row.copy_invoice_fee} /></TableCell>
                        <TableCell align="left"><FormatRupiah value={row.duty_stamp_fee} /></TableCell>
                        <TableCell align="left">{validationStatusView(row.status)}</TableCell>
                        <TableCell align="left">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              handleOpenMenu(event);
                              setIdData(row.id);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {dataInstallments.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not data to show
                          </Typography>
                          <Typography variant="body2">
                            This log will automatically update &nbsp;
                            <br /> Once users take action within the application.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={dataTotalInstallments}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/paylater/detail?id=${idData}`, { replace: true });
          }}
        >
          Detail
        </MenuItem>
      </Popover>
    </>
  );
}
