import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField, Typography, Link, MenuItem, IconButton, InputAdornment, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Iconify from '../../../components/iconify';
// constants
import Colors from '../../../constant/colors';

const styleLines = { borderBottom: 0.5, borderColor: Colors.GREYBB, width: '45%', position: 'relative', top: -10 };

export default function RegisterForm({
  firstname,
  setFirstname,
  lastname,
  setLastname,
  username,
  setUsername,
  emailData,
  setEmailData,
  codeNumber,
  setCodeNumber,
  phoneNumber,
  setPhoneNumber,
  passwordData,
  setPasswordData,
  passwordConfirm,
  setPasswordConfirm,
  postRegister,
  button,
  showPassword,
  setShowPassword,
  showPasswordConfirm,
  setShowPasswordConfirm,
}) {
  const navigate = useNavigate();

  return (
    <>
      <Stack spacing={3}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          name="firstname"
          label="Firstname"
        />

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          name="lastname"
          label="Lastname"
        />

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          name="username"
          label="Username"
        />

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={emailData}
          onChange={(e) => setEmailData(e.target.value)}
          name="email"
          label="Email Address"
        />

        <Box>
          <TextField
            sx={{ width: '25%', mr: 1 }}
            select
            value={codeNumber}
            onChange={(e) => {
              setCodeNumber(e.target.value);
              setPhoneNumber(e.target.value);
            }}
          >
            <MenuItem key={'1'} value={'62'}>
              +62
            </MenuItem>
          </TextField>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            name="phone_number"
            label="Phone number"
            placeholder="85894222xxx"
            sx={{ width: '72%' }}
          />
        </Box>

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={passwordData}
          onChange={(e) => setPasswordData(e.target.value)}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
          name="password"
          label="Confirm Password"
          type={showPasswordConfirm ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} edge="end">
                  <Iconify icon={showPasswordConfirm ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <br />

      <LoadingButton
        sx={{
          borderRadius: 20,
          backgroundColor: Colors.PRIMARY,
          '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={postRegister}
      >
        {button}
      </LoadingButton>

      <Box sx={{ mb: 2, mt: 2, display: 'flex' }}>
        <Box sx={styleLines} />
        <Typography sx={{ textAlign: 'center', color: Colors.GREYBB, px: 2 }}> or </Typography>
        <Box sx={styleLines} />
      </Box>

      <LoadingButton
        sx={{
          borderRadius: 20,
          backgroundColor: Colors.WHITE,
          borderColor: Colors.PRIMARY,
          '&.MuiButton-root:hover': { bgcolor: Colors.WHITE, borderColor: Colors.PRIMARY },
          color: Colors.PRIMARY,
          textDecoration: 'none',
        }}
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => navigate('/login')}
      >
        Login
      </LoadingButton>
    </>
  );
}
