import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useRegister = () => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [username, setUsername] = useState('');
  const [emailData, setEmailData] = useState('');
  const [codeNumber, setCodeNumber] = useState('62');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordData, setPasswordData] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [button, setButton] = useState('Register');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const navigate = useNavigate();

  const postRegister = async () => {
    const passwordValidate = validatePassword(passwordData, passwordConfirm);

    if (passwordValidate) {
      setButton('Loading...');

      const data = {
        email: emailData,
        first_name: firstname,
        image: 'image',
        last_name: lastname,
        password: passwordData,
        password_repetition: passwordConfirm,
        phone_code: codeNumber,
        phone_number: phoneNumber,
        user_name: username,
      };
      api
        .post(endpoint.POST_REGISTER, data)
        .then((res) => {
          setButton('Register');

          toastAlert(res.data.message, 'success');

          if (res.data.success) {
            setTimeout(() => {
              navigate('/login/');
            }, 2000);
          }
        })
        .catch((err) => {
          if (!err.response.data.success) {
            toastAlert(err.response.data.message, 'error');
          }
          setButton('Register');
          console.log(err);
        });
    }
  };

  const validatePassword = (passwordData, passwordConfirm) => {
    let data = true;
    if (passwordData !== passwordConfirm) {
      toastAlert('Password not match!', 'error');
      data = false;
    }

    return data;
  };

  return {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    username,
    setUsername,
    emailData,
    setEmailData,
    codeNumber,
    setCodeNumber,
    phoneNumber,
    setPhoneNumber,
    passwordData,
    setPasswordData,
    passwordConfirm,
    setPasswordConfirm,
    postRegister,
    button,
    showPassword,
    setShowPassword,
    showPasswordConfirm,
    setShowPasswordConfirm,
  };
};

export default useRegister;
