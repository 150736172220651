import { combineReducers } from 'redux';
import UserReducer from './users';
import CompanyReducer from './company';
import ContactReducer from './contact';
import PaymentReducer from './payment';
import PayoutFundsReducer from './payoutfunds';

export default combineReducers({
  UserReducer,
  CompanyReducer,
  ContactReducer,
  PaymentReducer,
  PayoutFundsReducer,
});
