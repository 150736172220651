import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const usePayment = () => {
  const { user } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
  }));

  const [dataBank, setDataBank] = useState([]);
  const [dataBankSelected, setDataBankSelected] = useState({
    bank_code: '',
    fee: 0,
    fee_type: '',
    is_selected: false,
    logo: '',
    name: '',
    swift: '',
  });
  const [total, setTotal] = useState(0);
  const [charge, setCharge] = useState(0);
  const [payable, setPayable] = useState(0);
  const [paramsPage, setParamsPage] = useState('');
  const [paramsId, setParamsId] = useState('');
  const [paramsType, setParamsType] = useState('');
  const [paramsLogo, setParamsLogo] = useState('');
  const [loadingRejected, setLoadingRejected] = useState('Rejected');
  const [loadingPayment, setLoadingPayment] = useState('Submit');
  const [dataListPayment, setDataListPayment] = useState({
    contact_person: '',
    email: '',
    grand_total: '',
    due_date: '',
    status: '',
    invoice_number: '',
  });
  const [button, setButton] = useState('Submit');
  const [addressPayment, setAddressPayment] = useState('');
  const [amount, setAmount] = useState(0);
  const [feeApp, setFeeApp] = useState(0);
  const [feeAppTax, setFeeAppTax] = useState(0);
  const [feeAppVat, setFeeAppVat] = useState(0);
  const [paymentGatewayCharge, setPaymentGatewayCharge] = useState(0);
  const [totalAll, setTotalAll] = useState(0);
  const [imageLogo, setImageLogo] = useState('');
  const [dataPayment, setDataPayment] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    getDataBank();

    const urlParams = new URLSearchParams(window.location.search);
    const myParamPage = urlParams.get('page');
    const myParamId = urlParams.get('id');
    const myParamType = urlParams.get('type');
    const myParamLogo = urlParams.get('logo');

    setParamsPage(myParamPage);
    setParamsId(myParamId);
    setParamsType(myParamType);
    setParamsLogo(myParamLogo);

    getDataInvoiceDetail(myParamId, myParamPage);
    getDataInfoPayment(myParamId);
  }, []);

  const getDataBank = async () => {
    api
      .get(`${endpoint.PAYMENT}/method`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const dataBank = res.data.data;

          for (let i = 0; i < dataBank.length; i += 1) {
            dataBank[i].is_selected = false;
          }

          setDataBank(dataBank);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleSelectedBank = async (value, index) => {
    if (value.bank_code === 'cc') {
      setAddressPayment('');
    }

    for (let i = 0; i < dataBank.length; i += 1) {
      dataBank[i].is_selected = false;
    }

    dataBank[index].is_selected = true;

    setDataBank(dataBank);
    setDataBankSelected(value);

    // bank code cc
    let charge = dataPayment?.fee_cc;

    // bank code qris
    if (value.bank_code === 'qris') {
      charge = dataPayment?.fee_qris;
    }

    // bank code va
    if (value.bank_code !== 'cc' && value.fee_type === 'nominal') {
      charge = dataPayment?.fee_va;
    }

    const totalData = feeApp + feeAppTax + feeAppVat + amount + charge;

    setPaymentGatewayCharge(charge);
    setTotalAll(totalData);
  };

  const getDataInvoiceDetail = async (myParamId = '', myParamPage = '') => {
    let url = `${endpoint.BILL}`;
    if (myParamPage === 'invoice') {
      url = `${endpoint.INVOICE}`;
    }

    api
      .get(`${url}/${myParamId}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const dataInv = res.data.data;

          if (myParamPage === 'invoice') {
            setDataListPayment({
              contact_person: dataInv.customer.contact_person,
              email: dataInv.customer.email,
              grand_total: dataInv.grand_total,
              status: dataInv.status,
              due_date: moment(dataInv.due_date).format('D/MM/YYYY'),
              callback_paid_at: dataInv.callback_paid_at,
              payment_method: dataInv.payment_method,
            });
          } else {
            setDataListPayment({
              contact_person: dataInv.vendor.contact_person,
              email: dataInv.vendor.email,
              grand_total: dataInv.grand_total,
              status: dataInv.status,
              due_date: moment(dataInv.due_date).format('D/MM/YYYY'),
              callback_paid_at: dataInv.callback_paid_at,
              payment_method: dataInv.payment_method,
            });
          }

          setTotal(dataInv.grand_total);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postStatusReject = async () => {
    let url = `${endpoint.BILL}`;
    if (paramsPage === 'invoice') {
      url = `${endpoint.INVOICE}`;
    }

    setLoadingRejected('Loading...');

    api
      .put(`${url}/status/${paramsId}`, { status: 'rejected' }, getAuthHeader)
      .then((res) => {
        setLoadingRejected('Rejected');
        if (res.data.success) {
          toastAlert(res.data.message, 'success');

          setTimeout(() => {
            navigate(`/${paramsPage}/list`);
          }, 2000);
        }
      })
      .catch((err) => {
        setLoadingRejected('Rejected');
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postPayment = async () => {
    setLoadingPayment('Loading...');

    let paymentType = '';
    if (dataBankSelected.bank_code === 'qris') {
      paymentType = 'qris';
    } else if (dataBankSelected.bank_code === 'cc') {
      paymentType = 'cc';
    } else {
      paymentType = 'va';
    }

    let addressData = dataBankSelected.bank_code;
    if (dataBankSelected.bank_code === 'qris') {
      addressData = '';
    }

    if (dataBankSelected.bank_code === 'cc') {
      addressData = addressPayment;
    }

    const dataForm = {
      address: addressData,
      id: paramsId,
      payment_type: paymentType,
      type: 'Invoice',
    };

    api
      .post(`${endpoint.PAYMENT}`, dataForm, getAuthHeader)
      .then((res) => {
        setLoadingPayment('Submit');
        if (res.data.success) {
          toastAlert(res.data.message, 'success');
          const dataPayment = res.data.data;

          if (dataPayment.type === 'cc') {
            window.open(dataPayment.address);
          }

          setTimeout(() => {
            if (dataPayment.type === 'qris' || dataPayment.type === 'va') {
              navigate(
                `/payment/confirm?page=${paramsPage}&&id=${paramsId}&&type=${paymentType}&&logo=${dataBankSelected.logo}`
              );
            }
          }, 2000);

          dispatch({
            type: 'DATA_PAYMENT',
            data: res.data.data,
          });
        }
      })
      .catch((err) => {
        setLoadingPayment('Submit');
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataInvoiceDownload = async (idInvoice = null) => {
    getAuthHeader.responseType = 'arraybuffer';

    api
      .get(`${endpoint.INVOICE}/download/${idInvoice}`, getAuthHeader)
      .then((res) => {
        let tempLink = '';

        const data = new Blob([res.data], { type: 'application/pdf' });
        const csvURL = window.URL.createObjectURL(data);

        tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `INV-${idInvoice}.pdf`);
        tempLink.click();
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataInfoPayment = async (myParamId) => {
    api
      .get(`${endpoint.PAYMENT}/fee/invoice/${myParamId}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const { data } = res.data;

          setAmount(data?.amount);
          setFeeApp(data?.fee_app);
          setFeeAppTax(data?.fee_app_tax);
          setFeeAppVat(data?.fee_app_vat);
          setImageLogo(data?.image);
          setDataPayment(data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  return {
    dataBank,
    button,
    handleSelectedBank,
    total,
    charge,
    payable,
    paramsPage,
    paramsId,
    dataListPayment,
    postStatusReject,
    loadingRejected,
    postPayment,
    loadingPayment,
    dataBankSelected,
    paramsType,
    dispatch,
    paramsLogo,
    setAddressPayment,
    getDataInvoiceDownload,
    user,
    feeApp,
    feeAppTax,
    feeAppVat,
    paymentGatewayCharge,
    totalAll,
    imageLogo,
    dataPayment,
  };
};

export default usePayment;
