// @mui
import PropTypes from 'prop-types';
import { Typography, Card, CardContent, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { styled } from '@mui/material/styles';
import { FormatRupiah } from '@arismun/format-rupiah';

PayQris.propTypes = {
  dataPaymentData: PropTypes.object,
  dispatch: PropTypes.func,
};

export default function PayQris({ dataPaymentData, dispatch }) {
  const StyledProductImg = styled('img')({
    width: '80px',
    height: '80px',
    objectFit: 'contain',
  });

  const navigate = useNavigate();

  return (
    <Card style={{ marginRight: 160, marginLeft: 160 }}>
      <CardContent>
        <Box>
          <center>
            <StyledProductImg
              alt={'Qris'}
              src={
                'https://cdn.invoiceplus.id/dev/ec8ad3ea5f9a572f4348ae41a245d9cbba3595efc91fae4c0fb5ac10191e5536d7c50cba3c9d651e0000368b823dc0439e.jpg'
              }
            />
            <Typography variant="h6">Payment Accepted Here</Typography>
          </center>

          <br />

          <QRCode
            size={256}
            style={{ height: 220, maxWidth: '100%', width: '100%' }}
            value={dataPaymentData?.address}
            viewBox={`0 0 256 256`}
          />
          <br />

          <center>
            <Typography mb={3} variant="h4">
              <FormatRupiah value={dataPaymentData.amount} />
            </Typography>
          </center>

          <Button
            sx={{ width: '100%' }}
            onClick={() => {
              navigate(`/pay/list`, { replace: true });

              dispatch({
                type: 'EMPTY_DATA_PAYMENT',
              });
            }}
            variant="outlined"
            size="large"
            color="info"
          >
            Done Payment
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
