// @mui
import PropTypes from 'prop-types';
import { Card, CardContent, Box, Grid, Stack, TextField, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

PayoutForm.propTypes = {
  dataBankData: PropTypes.array,
  buttonAdditional: PropTypes.string,
  submitPaymentAditional: PropTypes.func,
  handleChangeAdditionalForm: PropTypes.func,
  dataFormAdditional: PropTypes.object,
};

export default function PayoutForm({
  dataBankData,
  buttonAdditional,
  submitPaymentAditional,
  handleChangeAdditionalForm,
  dataFormAdditional,
}) {
  return (
    <Card>
      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Bank Name"
                  name="bank_name"
                  select
                  value={dataFormAdditional.bank_name}
                  onChange={(e) => handleChangeAdditionalForm(e)}
                >
                  <MenuItem key={'0'} value={'0'}>
                    Select
                  </MenuItem>
                  {dataBankData.map((row, index) => (
                    <MenuItem key={index} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="account_name"
                  label="Recipent Name PT / Company Vendor / Supplier"
                  value={dataFormAdditional.account_name}
                  onChange={(e) => handleChangeAdditionalForm(e)}
                />
              </Stack>
            </Grid>
            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="account_number"
                  label="Account Number"
                  value={dataFormAdditional.account_number}
                  onChange={(e) => handleChangeAdditionalForm(e)}
                />
              </Stack>
            </Grid>
            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={submitPaymentAditional}
                >
                  {buttonAdditional}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
