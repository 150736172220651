import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Container, Stack, Typography, Button, Link } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// components
import { CardBank } from './components';
import Iconify from '../../../components/iconify';
// mock
import usePaymentPay from '../../../hooks/pay/payment/usePaymentPay';

// ----------------------------------------------------------------------

export default function CompanyListPage() {
  const { dataBank } = usePaymentPay();

  return (
    <>
      <Helmet>
        <title> Payment Pay: List | SASS Invoice </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            How Do You Want To Vendor Pay
          </Typography>
        </Stack>

        <ToastContainer />

        <CardBank dataBank={dataBank} />
      </Container>
    </>
  );
}
