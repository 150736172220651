// @mui
import PropTypes from 'prop-types';
import { Card, CardContent, Box, CardHeader, Stack, Grid, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

PayoutInfo.propTypes = {
  dataBankMethod: PropTypes.array,
  dataBankSelected: PropTypes.bool,
  handleSelectedBank: PropTypes.func,
  postPayment: PropTypes.func,
  loadingPayment: PropTypes.bool,
  dataFormPayment: PropTypes.bool,
  setDataFormPayment: PropTypes.bool,
};

export default function PayoutInfo({
  dataBankMethod,
  dataBankSelected,
  handleSelectedBank,
  postPayment,
  loadingPayment,
  dataFormPayment,
  setDataFormPayment,
}) {
  const StyledProductImg = styled('img')({
    width: '62px',
    height: '62px',
    objectFit: 'contain',
  });

  const styleSelected = (value) => {
    let style = { marginTop: 10, marginBottom: 6 };
    if (value.is_selected) {
      style = { marginTop: 10, marginBottom: 6, border: '2px solid #2065D1', borderRadius: 6 };
    }

    return style;
  };

  return (
    <Card>
      <CardHeader title={'Select Others'} />
      <CardContent>
        <Box>
          <Grid container spacing={1} style={{ height: 315, overflow: 'scroll' }}>
            {dataBankMethod.map((value, index) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                key={index}
                onClick={() => handleSelectedBank(value, index)}
                style={styleSelected(value)}
              >
                <center>
                  <StyledProductImg alt={value.name} src={value.logo} />
                </center>
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={1} mt={2}>
            {dataFormPayment.payment_type === 'cc' && (
              <Grid item md={12} mb={2} xs={12}>
                <Stack spacing={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="address"
                    label="Number Card"
                    value={dataFormPayment.address}
                    onChange={(e) => {
                      setDataFormPayment({
                        ...dataFormPayment,
                        address: e.target.value,
                      });
                    }}
                  />
                </Stack>
              </Grid>
            )}

            <Grid item md={12} xs={12}>
              <Stack spacing={3}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={postPayment}>
                  {loadingPayment}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
