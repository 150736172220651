import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const usePayoutHistory = () => {
  const [dataPayoutHistory, setDataPayoutHistory] = useState([]);

  const [dataPayoutSelected, setDataPayoutSelected] = useState({
    transaction_id: 0,
    admin_note: '',
    account_name: '',
    account_number: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('xs');

  const pageSizeTable = 10;
  const [dataTotalTable, setTotalDataTable] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    if (companyId) {
      getDataPayoutHistory();
    }
  }, []);

  const getDataPayoutHistory = async (newPage = 1, filterTable = '') => {
    api
      .get(`${endpoint.PAYOUTS}/history/${companyId}?page_size=${pageSizeTable}&&page=${newPage}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const dataRes = res.data.data.data;
          const totalData = res.data.data.total_data;
          const totalDataPlus = dataPayoutHistory.length + dataRes.length;

          if (totalDataPlus <= totalData) {
            setDataPayoutHistory([...dataPayoutHistory, ...dataRes]);
          }

          if (filterTable === 'filter') {
            setDataPayoutHistory(dataRes);
          }

          setTotalDataTable(totalData);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const setSelectedDataPayout = (value = '') => {
    setDataPayoutSelected(value);
  };

  return {
    dataPayoutHistory,
    openModal,
    setOpenModal,
    fullWidth,
    maxWidth,
    dataPayoutSelected,
    setSelectedDataPayout,
    getDataPayoutHistory,
    dataTotalTable,
  };
};

export default usePayoutHistory;
