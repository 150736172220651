import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const usePayoutFunds = () => {
  const INITIAL_STATE_DATA_PAYOUT = [
    {
      id: 1,
      bank_code: '',
      fee: '',
      fee_type: '',
      name: 'Bank Transfer',
      swift: '',
      logo: 'https://cdn.invoiceplus.id/dev/044149547dd9c161920828642873e18e4fa28560fe25d49adda647b95c4a216cc4e135f7caf35453ca52555ce8b2528cc0.jpg',
    },
  ];

  const [dataBank, setDataBank] = useState(INITIAL_STATE_DATA_PAYOUT);
  const [dataBankPayout, setDataBankPayout] = useState([]);
  const [nameCompany, setNameCompany] = useState('');
  const [balanceCompany, setBalanceCompany] = useState(0);
  const [loadingPayoutFund, setLoadingPayoutFund] = useState('Next');
  const [loadingPayoutBalance, setLoadingPayoutBalance] = useState('Submit');
  const [amountData, setAmountData] = useState(0);
  const [button, setButton] = useState('Submit');

  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('xs');
  const [otp, setOtp] = useState('');
  const [informationPayout, setInformationPayout] = useState({
    min_payout: 0,
    max_payout: 0,
    fee: '',
    balance: 0,
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId, paymentPayoutFund, dataCompany, otpResponse, companyName } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
    dataCompany: states?.CompanyReducer?.dataCompany,
    paymentPayoutFund: states?.PayoutFundsReducer?.dataPaymentPayoutFund,
    otpResponse: states?.PayoutFundsReducer?.otpResponse,
    companyName: states?.CompanyReducer?.companyName,
  }));

  const [formPayout, setFormPayout] = useState({
    account_name: '',
    account_number: '',
    amount: parseFloat(paymentPayoutFund.requested_amount),
    bank_name: '0',
    company_id: companyId,
    bank_branch: '',
  });

  useEffect(() => {
    if (companyId) {
      getDataBank();

      if (dataCompany.length > 0) {
        const filterDataCompany = dataCompany.filter((i) => i.id === companyId);

        setNameCompany(filterDataCompany[0].name);
        setBalanceCompany(filterDataCompany[0].balance ? filterDataCompany[0].balance : 0);
        getDataInformationPayout();
      }
    }
  }, []);

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const getDataBank = async () => {
    api
      .get(`${endpoint.PAYOUTS}/method`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setDataBankPayout(res.data.data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleChangePayout = async (event) => {
    setFormPayout({
      ...formPayout,
      [event.target.name]: event.target.value,
    });
  };

  const submitRequestOTP = async () => {
    setLoadingPayoutFund('Loading...');

    const data = {
      via: 'email',
      template_name: 'sms_otp',
    };

    api
      .post(`${endpoint.OTP}/user`, data, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: 'DATA_RESPONSE_OTP',
            data: res.data.data,
          });

          submitPayoutFunds();
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const submitPayoutFunds = async () => {
    const dataFormPayout = {
      amount: parseFloat(amountData),
      company_id: companyId,
    };

    api
      .post(`${endpoint.PAYOUTS}/balance`, dataFormPayout, getAuthHeader)
      .then((res) => {
        setLoadingPayoutFund('Next');

        toastAlert(res.data.message, 'success');

        if (res.data.success) {
          dispatch({
            type: 'DATA_PAYMENT_PAYOUT_FUND',
            data: res.data.data,
          });
          setTimeout(() => {
            navigate('/payout-funds/additional-info/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingPayoutFund(false);
      });
  };

  const submitPaymentPayoutFunds = async () => {
    setLoadingPayoutBalance('Loading...');

    formPayout.otp = otp;

    api
      .post(`${endpoint.PAYOUTS}/balance/submit`, formPayout, getAuthHeader)
      .then((res) => {
        setLoadingPayoutBalance('Submit');

        toastAlert(res.data.message, 'success');

        if (res.data.success) {
          setTimeout(() => {
            navigate('/payout-funds/list/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingPayoutBalance('Submit');
      });
  };

  const getDataInformationPayout = async () => {
    api
      .get(`${endpoint.GET_INFORMATION_PAYOUT}/${companyId}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setInformationPayout(res.data.data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  return {
    dataBank,
    loadingPayoutFund,
    button,
    open,
    setOpen,
    fullWidth,
    maxWidth,
    submitPayoutFunds,
    setAmountData,
    paymentPayoutFund,
    dataBankPayout,
    formPayout,
    handleChangePayout,
    submitRequestOTP,
    loadingPayoutBalance,
    nameCompany,
    balanceCompany,
    otp,
    setOtp,
    submitPaymentPayoutFunds,
    otpResponse,
    companyName,
    informationPayout,
  };
};

export default usePayoutFunds;
