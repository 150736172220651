// @mui
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  TextField,
  Stack,
  CardHeader,
  CardContent,
  Grid,
  MenuItem,
  Typography,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Select from 'react-select';

import OtpInput from 'react-otp-input';

AddForm.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  dataCountries: PropTypes.array,
  dataProvinces: PropTypes.array,
  getDataRegionsChild: PropTypes.func,
  dataCity: PropTypes.array,
  loadingImage: PropTypes.bool,
  postDataFileImage: PropTypes.func,
  openModalVerify: PropTypes.bool,
  setOpenVerify: PropTypes.bool,
  fullWidthModal: PropTypes.bool,
  buttonVerify: PropTypes.string,
  postVerifyEmail: PropTypes.func,
  handleChangeCompany: PropTypes.func,
  postDataCompany: PropTypes.func,
  dataFormCompany: PropTypes.object,
  button: PropTypes.string,
  otp: PropTypes.string,
  setOtp: PropTypes.string,
  postVerifyEmailOtp: PropTypes.func,
  openModalVerifyOtp: PropTypes.bool,
  buttonVerifyOtp: PropTypes.bool,
  setOpenModalVerifyOtp: PropTypes.bool,
};

export default function AddForm({
  title,
  subheader,
  handleChangeCompany,
  postDataCompany,
  dataFormCompany,
  button,
  dataCountries,
  dataProvinces,
  getDataRegionsChild,
  dataCity,
  loadingImage,
  postDataFileImage,
  openModalVerify,
  setOpenVerify,
  fullWidthModal,
  buttonVerify,
  postVerifyEmail,
  otp,
  setOtp,
  postVerifyEmailOtp,
  openModalVerifyOtp,
  buttonVerifyOtp,
  setOpenModalVerifyOtp,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="name"
                  label="Name"
                  value={dataFormCompany.name}
                  onChange={(e) => handleChangeCompany(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="email"
                  label="Email address"
                  value={dataFormCompany.email}
                  onChange={(e) => handleChangeCompany(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="phone_number"
                  label="Phone Number"
                  value={dataFormCompany.phone_number}
                  onChange={(e) => handleChangeCompany(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Country"
                  name="country_id"
                  select
                  value={dataFormCompany.country_id}
                  onChange={(e) => handleChangeCompany(e)}
                >
                  <MenuItem key={'1'} value={'0'}>
                    Select
                  </MenuItem>
                  {dataCountries.map((value, index) => (
                    <MenuItem key={index} value={value.country_id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Province"
                  name="region_id"
                  select
                  value={dataFormCompany.region_id}
                  onChange={(e) => {
                    getDataRegionsChild(e.target.value);
                    handleChangeCompany(e);
                  }}
                >
                  <MenuItem key={'1'} value={'0'}>
                    Select
                  </MenuItem>
                  {dataProvinces.map((value, index) => (
                    <MenuItem key={index} value={value.region_id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="City"
                  name="city_id"
                  select
                  value={dataFormCompany.city_id}
                  onChange={(e) => handleChangeCompany(e)}
                >
                  <MenuItem key={'1'} value={'0'}>
                    Select
                  </MenuItem>
                  {dataCity.map((value, index) => (
                    <MenuItem key={index} value={value.city_id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="address"
                  label="Address"
                  value={dataFormCompany.address}
                  onChange={(e) => handleChangeCompany(e)}
                  multiline
                  rows={3}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="address2"
                  label="Address Line 2 (Optional)"
                  value={dataFormCompany.address2}
                  onChange={(e) => handleChangeCompany(e)}
                  multiline
                  rows={3}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="tax_id"
                  label="Tax ID (Optional)"
                  value={dataFormCompany.tax_id}
                  onChange={(e) => handleChangeCompany(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="file"
                  name="logo-image"
                  label="Upload Logo"
                  onChange={(event) => postDataFileImage(event)}
                />
              </Stack>
              {loadingImage && (
                <Typography sx={{ color: '#adadad' }} variant="body2">
                  Loading upload...
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={postDataCompany}>
                  {button}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>

      <Dialog fullWidth={fullWidthModal} maxWidth={'xs'} open={openModalVerify} onClose={() => setOpenVerify(false)}>
        <DialogTitle>Verify Your Email</DialogTitle>
        <DialogContent>
          <span>Check your email & get a notification to get the OTP code</span>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={postVerifyEmail}>
            {buttonVerify}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={fullWidthModal}
        maxWidth={'xs'}
        open={openModalVerifyOtp}
        onClose={() => setOpenModalVerifyOtp(false)}
      >
        <DialogTitle>Verify Your Email</DialogTitle>
        <DialogContent>
          <OtpInput
            inputStyle={{ width: 50, height: 40 }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={postVerifyEmailOtp}>
            {buttonVerifyOtp}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
