import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useTicket = () => {
  const INITIAL_STATE_ADD_TICKET = {
    file: '',
    message: '',
    subject: '',
  };

  const INITIAL_STATE_REPLY_TICKET = {
    file: '',
    message: '',
  };

  const [dataTicket, setDataTicket] = useState([]);
  const [dataTicketDetail, setDataTicketDetail] = useState({
    id: '',
    status: '',
    subject: '',
    user_id: '',
    ticket_details: [],
  });
  const [dataFormTicket, setDataFormTicket] = useState(INITIAL_STATE_ADD_TICKET);
  const [dataFormTicketReply, setDataFormTicketReply] = useState(INITIAL_STATE_REPLY_TICKET);
  const [button, setButton] = useState('Submit');
  const [buttonReply, setButtonReply] = useState('Submit');
  const [buttonClose, setButtonClose] = useState('Close');
  const [loadingImage, setLoadingImage] = useState(false);
  const [idTicketReply, setIdTicketReply] = useState('');
  const pageSizeTable = 10;
  const [dataTotalTable, setTotalDataTable] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    if (companyId) {
      getDataTicket();

      const urlParams = new URLSearchParams(window.location.search);
      const myParamTicketId = urlParams.get('ticket_id');

      if (myParamTicketId) {
        getDataTicketDetail(myParamTicketId);
        setIdTicketReply(myParamTicketId);

        setInterval(() => {
          getDataTicketDetail(myParamTicketId);
        }, 4000);
      }
    }
  }, []);

  const getDataTicket = async (newPage = 1, filterTable = '') => {
    api
      .get(`${endpoint.GET_USER_TICKETS}?page_size=${pageSizeTable}&&page=${newPage}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const dataRes = res.data.data.data;
          const totalData = res.data.data.total_data;
          const totalDataPlus = dataTicket.length + dataRes.length;

          if (totalDataPlus <= totalData) {
            setDataTicket([...dataTicket, ...dataRes]);
          }

          if (filterTable === 'filter') {
            setDataTicket(dataRes);
          }

          setTotalDataTable(totalData);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataTicketDetail = async (myParamTicketId) => {
    api
      .get(`${endpoint.GET_USER_TICKETS}/${myParamTicketId}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setDataTicketDetail(res.data.data);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postDataTicket = async () => {
    setButton('Loading...');

    api
      .post(`${endpoint.GET_USER_TICKETS}`, dataFormTicket, getAuthHeader)
      .then((res) => {
        toastAlert(res.data.message, 'success');

        if (res.data.success) {
          setTimeout(() => {
            navigate('/ticket/list/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postDataTicketReply = async () => {
    setButtonReply('Loading...');

    api
      .post(`${endpoint.GET_USER_TICKETS}/${idTicketReply}/reply`, dataFormTicketReply, getAuthHeader)
      .then((res) => {
        toastAlert(res.data.message, 'success');

        setDataFormTicketReply({
          ...dataFormTicketReply,
          file: null,
          message: '',
        });

        if (res.data.success) {
          setDataFormTicketReply(INITIAL_STATE_REPLY_TICKET);
          getDataTicketDetail(idTicketReply);
        }

        setButtonReply('Submit');
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postDataTicketClose = async () => {
    setButtonClose('Loading...');

    api
      .put(
        `${endpoint.GET_USER_TICKETS}/${idTicketReply}/close`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      )
      .then((res) => {
        setButtonClose('Close');

        toastAlert(res.data.message, 'success');

        if (res.data.success) {
          setTimeout(() => {
            navigate('/ticket/list');
          }, 3500);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleChangeTicket = async (event) => {
    setDataFormTicket({
      ...dataFormTicket,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeTicketReply = async (event) => {
    setDataFormTicketReply({
      ...dataFormTicketReply,
      [event.target.name]: event.target.value,
    });
  };

  const postDataFileImage = (event) => {
    const usernameData = 'uploader';
    const passwordData = '30f87203fed3b0b3c2e517ddea93b4d6';

    try {
      setLoadingImage(true);

      const file = event.target.files[0];

      const requestBody = new FormData();
      requestBody.append('type', 'ticket-file');
      requestBody.append('file', file);

      axios
        .post(endpoint.POST_UPLOAD_FILE, requestBody, {
          auth: {
            username: usernameData,
            password: passwordData,
          },

          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          setLoadingImage(false);

          setDataFormTicket({
            ...dataFormTicket,
            file: res.data.data.file_name,
          });

          setDataFormTicketReply({
            ...dataFormTicketReply,
            file: res.data.data.file_name,
          });
        });
    } catch (err) {
      console.log(err.message);
      setLoadingImage(false);
    }
  };

  return {
    dataTicket,
    postDataTicket,
    handleChangeTicket,
    button,
    dataFormTicket,
    dataTicketDetail,
    buttonReply,
    postDataTicketReply,
    handleChangeTicketReply,
    dataFormTicketReply,
    buttonClose,
    postDataTicketClose,
    loadingImage,
    postDataFileImage,
    getDataTicket,
    dataTotalTable,
  };
};

export default useTicket;
