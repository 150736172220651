import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
import { useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Link,
  Box,
} from '@mui/material';
// @mui
import { ToastContainer } from 'react-toastify';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import Label from '../../components/label';
// sections
import { UserListHead, UserListToolbar } from '../../sections/ticket';
// mock
import useTicket from '../../hooks/ticket/useTicket';
// constant
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'subject', label: 'Subject', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'last_reply', label: 'Last Reply', alignRight: false },
  { id: '', label: '', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.subject.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TicketListPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { dataTicket, getDataTicket, dataTotalTable } = useTicket();

  const navigate = useNavigate();

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataTicket.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    getDataTicket(newPage + 1);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataTicket.length) : 0;

  const filteredUsers = applySortFilter(dataTicket, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Ticket: List | InvoicePlus </title>
      </Helmet>

      <ToastContainer />

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Support Ticket
            </Typography>
            <Link to="/ticket/add" component={RouterLink} sx={{ display: 'contents' }} mt={5}>
              <Button
                sx={{
                  borderRadius: 20,
                  backgroundColor: Colors.PRIMARY,
                  '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
                  marginTop: 3.5,
                }}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Ticket
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataTicket.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, name } = row;
                    const selectedUser = selected.indexOf(name) !== -1;
                    let websiteUrl = row.website;
                    if (websiteUrl) {
                      let more = '';
                      if (websiteUrl.length > 25) {
                        more = '...';
                      }
                      websiteUrl = row.website.substring(0, 25) + more;
                    }

                    let buttonColor = 'primary';
                    if (row.status === 'closed') {
                      buttonColor = 'error';
                    } else if (row.status === 'customer-reply') {
                      buttonColor = 'success';
                    } else if (row.status === 'replied') {
                      buttonColor = 'warning';
                    }

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell align="left">{index + 1}.</TableCell>

                        <TableCell align="left">{row.subject}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="filled"
                            color={buttonColor}
                            sx={{
                              zIndex: 9,
                              top: 16,
                              right: 16,
                              color: '#fff',
                            }}
                          >
                            {row.status}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{row.created_at}</TableCell>

                        <TableCell>
                          <Label
                            variant="filled"
                            color={'primary'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigate(`/ticket/view?ticket_id=${row.id}`);
                            }}
                          >
                            <Iconify style={{ color: '#fff' }} icon={'eva:eye-fill'} /> &nbsp; View
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {dataTicket.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not data to show
                          </Typography>
                          <Typography variant="body2">
                            This log will automatically update &nbsp;
                            <br /> Once users take action within the application.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={dataTotalTable}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Link to="/ticket/view" component={RouterLink} sx={{ display: 'contents' }}>
            <>
              <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
              View
            </>
          </Link>
        </MenuItem>
      </Popover>
    </>
  );
}
