import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Grid, Container, Stack, Typography, Link, Button, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// sections
import { AddForm } from '../../sections/company';
// constant
import Colors from '../../constant/colors';
// components
import Iconify from '../../components/iconify';
import useCompany from '../../hooks/company/useCompany';
// ----------------------------------------------------------------------

export default function CompanyAddPage() {
  const {
    handleChangeCompany,
    postDataCompany,
    dataFormCompany,
    button,
    dataCountries,
    dataProvinces,
    getDataRegionsChild,
    dataCity,
    loadingImage,
    postDataFileImage,
    openModalVerify,
    setOpenVerify,
    fullWidthModal,
    buttonVerify,
    postVerifyEmail,
    otp,
    setOtp,
    postVerifyEmailOtp,
    openModalVerifyOtp,
    buttonVerifyOtp,
    setOpenModalVerifyOtp,
  } = useCompany();

  return (
    <>
      <Helmet>
        <title> Company: Add | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Company / Add
            </Typography>
            <Link to="/company/list" component={RouterLink} sx={{ display: 'contents' }}>
              <Button startIcon={<Iconify icon="ic:twotone-chevron-left" />} sx={{ color: Colors.WHITE, marginTop: 2 }}>
                Back to list
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid item xs={12} md={6} lg={4}>
          <AddForm
            handleChangeCompany={handleChangeCompany}
            dataFormCompany={dataFormCompany}
            postDataCompany={postDataCompany}
            title="Company Add"
            button={button}
            dataCountries={dataCountries}
            dataProvinces={dataProvinces}
            getDataRegionsChild={getDataRegionsChild}
            dataCity={dataCity}
            loadingImage={loadingImage}
            postDataFileImage={postDataFileImage}
            openModalVerify={openModalVerify}
            setOpenVerify={setOpenVerify}
            fullWidthModal={fullWidthModal}
            buttonVerify={buttonVerify}
            postVerifyEmail={postVerifyEmail}
            otp={otp}
            setOtp={setOtp}
            postVerifyEmailOtp={postVerifyEmailOtp}
            openModalVerifyOtp={openModalVerifyOtp}
            buttonVerifyOtp={buttonVerifyOtp}
            setOpenModalVerifyOtp={setOpenModalVerifyOtp}
          />
        </Grid>
      </Container>
    </>
  );
}
