// @mui
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Typography, Card, CardContent, Box, Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormatRupiah } from '@arismun/format-rupiah';

PayVA.propTypes = {
  dataPayment: PropTypes.object,
  dispatch: PropTypes.object,
  myParamLogoData: PropTypes.string,
};

export default function PayVA({ dataPayment, dispatch, myParamLogoData }) {
  const StyledProductImg = styled('img')({
    width: '40%',
    height: 100,
    objectFit: 'contain',
  });

  const navigate = useNavigate();

  return (
    <Card style={{ marginRight: 160, marginLeft: 160 }}>
      <CardContent>
        <Box>
          <Grid container spacing={3} mt={1}>
            <Grid item md={12}>
              <center>
                <Typography variant="h3">Please Pay</Typography>
                <Typography variant="subtitle1" style={{ color: '#1890FF' }} mb={2}>
                  No VA. {dataPayment.address}
                </Typography>
                <Typography mb={3} variant="h4">
                  <FormatRupiah value={dataPayment.amount} />
                </Typography>
                <StyledProductImg alt={'VA'} src={myParamLogoData} />
                <Button
                  sx={{ width: '100%', marginTop: 4 }}
                  onClick={() => {
                    navigate(`/pay/list`, { replace: true });

                    dispatch({
                      type: 'EMPTY_DATA_PAYMENT',
                    });
                  }}
                  variant="outlined"
                  size="large"
                  color="info"
                >
                  Done Payment
                </Button>
              </center>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
