// @mui
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  TextField,
  Stack,
  CardHeader,
  CardContent,
  Grid,
  MenuItem,
  IconButton,
  Popover,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

AddForm.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  dataCustomer: PropTypes.array,
  dataServices: PropTypes.array,
  customerId: PropTypes.string,
  setCustomerId: PropTypes.func,
  dueDate: PropTypes.string,
  setDueDate: PropTypes.func,
  firstPaymentDate: PropTypes.string,
  setFirstPaymentDate: PropTypes.func,
  fontColor: PropTypes.string,
  setFontColor: PropTypes.func,
  invoiceNumber: PropTypes.string,
  setInvoiceNumber: PropTypes.func,
  invoiceTemplate: PropTypes.string,
  setInvoiceTemplate: PropTypes.func,
  invoiceTheme: PropTypes.string,
  setInvoiceTheme: PropTypes.func,
  lines: PropTypes.array,
  setLines: PropTypes.func,
  paymentFrequency: PropTypes.string,
  setPaymentFrequency: PropTypes.func,
  paymentFrequencyPer: PropTypes.string,
  setPaymentFrequencyPer: PropTypes.string,
  status: PropTypes.string,
  setStatus: PropTypes.string,
  taxRate: PropTypes.string,
  setTaxRate: PropTypes.string,
  vatRate: PropTypes.string,
  setVatRate: PropTypes.string,
  numberOfPayments: PropTypes.string,
  setNumberOfPayments: PropTypes.string,
  dataImageInvoice: PropTypes.array,
  setDataImageInvoice: PropTypes.string,
  handleAddServices: PropTypes.func,
  handleChangeServices: PropTypes.func,
  setTempDataServices: PropTypes.string,
  tempDataServices: PropTypes.string,
  handleValueServices: PropTypes.func,
  subTotalServices: PropTypes.number,
  grandTotalServices: PropTypes.number,
  setGrandTotalServices: PropTypes.number,
  totalTaxServices: PropTypes.func,
  setTotalTaxServices: PropTypes.func,
  totalVateTaxServices: PropTypes.func,
  setTotalVateTaxServices: PropTypes.func,
  setCustomerSelected: PropTypes.func,
  deleteServicesInvoice: PropTypes.func,
  updateServicesInvoice: PropTypes.func,
  setIdServicesId: PropTypes.string,
  titleModalServices: PropTypes.string,
  setTitleModalServices: PropTypes.string,
  sendToEmail: PropTypes.string,
  setSendToEmail: PropTypes.string,
  emailNote: PropTypes.string,
  setEmailNote: PropTypes.string,
  postDataInvoice: PropTypes.func,
  button: PropTypes.bool,
  discount: PropTypes.number,
  setDiscount: PropTypes.func,
  countDiscount: PropTypes.func,
  countTax: PropTypes.func,
  countVat: PropTypes.func,
};

export default function AddForm({
  title,
  subheader,
  dataCustomer,
  dataServices,
  postDataInvoice,
  button,
  customerId,
  setCustomerId,
  dueDate,
  setDueDate,
  fontColor,
  setFontColor,
  invoiceNumber,
  setInvoiceNumber,
  setInvoiceTemplate,
  invoiceTheme,
  setInvoiceTheme,
  lines,
  taxRate,
  setTaxRate,
  vatRate,
  setVatRate,
  dataImageInvoice,
  setDataImageInvoice,
  handleAddServices,
  handleChangeServices,
  setTempDataServices,
  tempDataServices,
  handleValueServices,
  subTotalServices,
  grandTotalServices,
  setGrandTotalServices,
  totalTaxServices,
  setTotalTaxServices,
  totalVateTaxServices,
  setTotalVateTaxServices,
  setCustomerSelected,
  deleteServicesInvoice,
  updateServicesInvoice,
  setIdServicesId,
  titleModalServices,
  setTitleModalServices,
  sendToEmail,
  setSendToEmail,
  emailNote,
  setEmailNote,
  discount,
  setDiscount,
  countDiscount,
  countTax,
  countVat,
  ...other
}) {
  const [open, setOpen] = useState(null);
  const [openModalService, setOpenModalService] = useState(null);
  const [openModalPaymentRequest, setOpenModalPaymentRequest] = useState(null);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('xs');

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Vendor Name"
                  select
                  value={customerId}
                  onChange={(e) => setCustomerId(e.target.value)}
                >
                  <MenuItem key={'1'} value={'0'}>
                    Select
                  </MenuItem>
                  {dataCustomer.map((value, index) => (
                    <MenuItem key={index} value={value.id} onClick={() => setCustomerSelected(value)}>
                      {value.contact_person}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="message"
                  label="Bill Number"
                  value={invoiceNumber}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={'date'}
                  label="Due Date"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ width: 120, overflow: 'scroll' }}>
              <Stack spacing={3}>
                <FormLabel id="demo-row-radio-buttons-group-label">Select Template</FormLabel>

                <ImageList sx={{ width: 530, height: 100 }} cols={6}>
                  {dataImageInvoice.map((item, index) => (
                    <ImageListItem key={index}>
                      <Button
                        onClick={() => {
                          const indexPlus = index + 1;
                          setInvoiceTemplate(indexPlus.toString());

                          for (let x = 0; x < dataImageInvoice.length; x += 1) {
                            dataImageInvoice[x].isSelected = false;
                            if (x === index) {
                              dataImageInvoice[x].isSelected = true;
                            }
                          }

                          setDataImageInvoice(dataImageInvoice);
                        }}
                      >
                        <img
                          style={{ border: `${item.isSelected ? 1 : 0}px solid #2065D1`, height: 80, borderRadius: 6 }}
                          src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.title}
                          loading="lazy"
                        />
                      </Button>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={'color'}
                  label="Select Theme"
                  value={invoiceTheme}
                  onChange={(e) => setInvoiceTheme(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={'color'}
                  name="message"
                  label="Select Color"
                  value={fontColor}
                  onChange={(e) => setFontColor(e.target.value)}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Stack spacing={3}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setTempDataServices({
                      service_id: '',
                      title: '',
                      price: 0,
                      quantity: 0,
                      description: '',
                    });

                    setOpenModalService(true);
                    setTitleModalServices('Add Jasa / Produk');
                  }}
                >
                  <Iconify icon={'eva:plus-fill'} sx={{ mr: 1 }} /> Add Jasa / Produk
                </Button>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lines.map((row, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell align="right">{row.quantity}</TableCell>
                          <TableCell align="right">{new Intl.NumberFormat().format(row.price)}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => {
                                setOpen(event.currentTarget);
                                setIdServicesId(row.id);
                                setTempDataServices(row);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    {lines.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={4}>
                            Data jasa / produk is empty
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    <TableBody>
                      <TableCell colSpan={2}>Subtotal</TableCell>
                      <TableCell colSpan={2}>
                        <FormControl variant="filled">
                          <OutlinedInput
                            style={{ backgroundColor: '#f0f0f0' }}
                            value={new Intl.NumberFormat().format(subTotalServices)}
                            readOnly
                            id="outlined-adornment-password"
                            endAdornment={<InputAdornment position="end">IDR</InputAdornment>}
                          />
                        </FormControl>
                      </TableCell>
                    </TableBody>
                    <TableBody>
                      <TableCell colSpan={2}>Discount</TableCell>
                      <TableCell colSpan={2}>
                        <FormControl variant="filled">
                          <OutlinedInput
                            type={'number'}
                            id="outlined-adornment-password"
                            endAdornment={<InputAdornment position="end">% &nbsp;&nbsp; </InputAdornment>}
                            value={discount}
                            onChange={(e) => {
                              const valueDiscount = parseFloat(e.target.value);
                              countDiscount(valueDiscount);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                    </TableBody>
                    <TableBody>
                      <TableCell colSpan={2}>Tax</TableCell>
                      <TableCell colSpan={2}>
                        <FormControl variant="filled">
                          <OutlinedInput
                            type={'number'}
                            id="outlined-adornment-password"
                            endAdornment={<InputAdornment position="end">% &nbsp;&nbsp; </InputAdornment>}
                            value={taxRate}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              countTax(value);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                    </TableBody>
                    <TableBody>
                      <TableCell colSpan={2}>Vat</TableCell>
                      <TableCell colSpan={2}>
                        <FormControl variant="filled">
                          <OutlinedInput
                            type={'number'}
                            id="outlined-adornment-password"
                            endAdornment={<InputAdornment position="end">% &nbsp;&nbsp; </InputAdornment>}
                            value={vatRate}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              countVat(value);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                    </TableBody>
                    <TableBody>
                      <TableCell colSpan={2}>Grand Total</TableCell>
                      <TableCell colSpan={2}>Rp {new Intl.NumberFormat().format(grandTotalServices)}</TableCell>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Grid>

            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={() => setOpen(null)}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  '& .MuiMenuItem-root': {
                    px: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  setOpenModalService(true);
                  setTitleModalServices('Edit Service');
                }}
              >
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                Edit
              </MenuItem>

              <MenuItem
                sx={{ color: 'error.main' }}
                onClick={() => {
                  setOpen(null);
                  deleteServicesInvoice();
                }}
              >
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                Delete
              </MenuItem>
            </Popover>

            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openModalService}
              onClose={() => setOpenModalService(false)}
            >
              <DialogTitle>{titleModalServices}</DialogTitle>
              <DialogContent>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                  }}
                />

                <Grid container spacing={3} style={{ marginTop: -8 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Stack spacing={3}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Jasa / Produk"
                        select
                        name="service_id"
                        value={tempDataServices.service_id}
                        onChange={(e) => handleValueServices(e.target.value)}
                      >
                        <MenuItem key={'1'} value={'0'}>
                          Select
                        </MenuItem>
                        {dataServices.map((value, index) => (
                          <MenuItem key={index} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Stack spacing={3}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Title"
                        name="title"
                        value={tempDataServices.title}
                        onChange={(e) => handleChangeServices(e)}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Stack spacing={3}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={'number'}
                        label="Price"
                        name="price"
                        value={tempDataServices.price}
                        onChange={(e) => handleChangeServices(e)}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Stack spacing={3}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={'number'}
                        label="Quantity"
                        name="quantity"
                        value={tempDataServices.quantity}
                        onChange={(e) => handleChangeServices(e)}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Stack spacing={3}>
                      <TextField
                        label="Description"
                        name="description"
                        value={tempDataServices.description}
                        onChange={(e) => handleChangeServices(e)}
                        multiline
                        rows={6}
                        maxRows={4}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setOpenModalService(false)}>Cancel</Button>
                <Button
                  onClick={() => {
                    setOpenModalService(false);

                    if (titleModalServices === 'Add Jasa / Produk') {
                      handleAddServices();
                    } else {
                      updateServicesInvoice();
                    }
                  }}
                >
                  {titleModalServices === 'Add Jasa / Produk' ? 'Add' : 'Edit'}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openModalPaymentRequest}
              onClose={() => setOpenModalPaymentRequest(false)}
            >
              <DialogTitle>Send Payment Request</DialogTitle>
              <DialogContent>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                  }}
                />

                <Grid container spacing={3} style={{ marginTop: -8 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Stack spacing={3}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="message"
                        label="Customer Email"
                        value={sendToEmail}
                        onChange={(e) => setSendToEmail(e.target.value)}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Stack spacing={3}>
                      <TextField
                        name="about"
                        label="Payment request note (optional)"
                        value={emailNote}
                        onChange={(e) => setEmailNote(e.target.value)}
                        multiline
                        rows={6}
                        maxRows={4}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setOpenModalPaymentRequest(false)}>Cancel</Button>
                <Button onClick={() => postDataInvoice('sent')}>Send</Button>
              </DialogActions>
            </Dialog>

            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <LoadingButton fullWidth size="large" variant="contained" onClick={() => postDataInvoice('unsend')}>
                  {button}
                </LoadingButton>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Button variant="outlined" size="large" onClick={() => setOpenModalPaymentRequest(true)}>
                  Save & Send
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
