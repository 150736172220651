import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
// @mui
import { Grid, Container } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import usePayment from '../../hooks/payment/usePayment';
import { VA, Qris } from './components';

// ----------------------------------------------------------------------

export default function ChangePasswordPage() {
  const { paramsPage, paramsType, dispatch, paramsLogo, user } = usePayment();

  const { dataPayment } = useSelector((states) => ({
    dataPayment: states?.PaymentReducer?.dataPayment,
  }));

  return (
    <>
      <Helmet>
        <title> Payment: Confirm | InvoicePlus </title>
      </Helmet>

      <Container>
        <ToastContainer />

        <Grid item xs={12} md={6} lg={4}>
          {paramsType === 'qris' && (
            <Qris dataPayment={dataPayment} paramsPage={paramsPage} dispatch={dispatch} user={user} />
          )}
          {paramsType === 'va' && (
            <VA
              dataPayment={dataPayment}
              paramsPage={paramsPage}
              dispatch={dispatch}
              paramsLogo={paramsLogo}
              user={user}
            />
          )}
        </Grid>
      </Container>
    </>
  );
}
