import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// components
import { CardBank } from './components';
// mock
import usePaymentPay from '../../../hooks/pay/payment/usePaymentPay';
// constant
import Colors from '../../../constant/colors';
// ----------------------------------------------------------------------

export default function PaymentPayListPage() {
  const { dataBank, idPay } = usePaymentPay();

  return (
    <>
      <Helmet>
        <title> Payment Pay: List | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              How Do You Want To Vendor Pay
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <CardBank dataBank={dataBank} idPay={idPay} />
      </Container>
    </>
  );
}
