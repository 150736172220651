const baseUrl = process.env.REACT_APP_BASE_URL;

const endpoint = {
  POST_LOGIN: `${baseUrl}auth/login`,
  POST_REGISTER: `${baseUrl}auth/register`,
  POST_FORGOT_PASSWORD: `${baseUrl}auth/forgot-password`,
  POST_USER_COMPANY: `${baseUrl}companies`,
  POST_USER_CONTACT: `${baseUrl}contacts`,
  POST_UPLOAD_FILE: `${baseUrl}files`,
  GET_USER_COMPANY: `${baseUrl}companies`,
  GET_USER_PROFILE: `${baseUrl}users`,
  GET_USER_CONTACT: `${baseUrl}contacts`,
  GET_REGIONS: `${baseUrl}regions`,
  GET_USER_TICKETS: `${baseUrl}tickets`,
  GET_USER_SERVICES: `${baseUrl}services`,
  DELETE_USER_CONTACT: `${baseUrl}contacts`,
  POST_ADMIN_LOGIN: `${baseUrl}auth/admin/login`,
  POST_ADMIN_FORGOT_PASSWORD: `${baseUrl}auth/admin/forgot-password`,
  GET_ADMIN_PROFILE: `${baseUrl}admins`,
  INVOICE: `${baseUrl}invoices`,
  BILL: `${baseUrl}bills`,
  PAYMENT: `${baseUrl}payments`,
  CHANGE_PASSWORD: `${baseUrl}auth/change-password`,
  PAYOUT_HISTORY: `${baseUrl}payout-history`,
  TRX_HISTORY: `${baseUrl}transaction-history`,
  AGREGATE: `${baseUrl}agregate`,
  PAYOUTS: `${baseUrl}payouts`,
  OTP: `${baseUrl}otp`,
  POST_RESEND_EMAIL_VERIFY: `${baseUrl}auth/resend-email-verification`,
  POST_EMAIL_VERIFY: `${baseUrl}auth/verify-email`,
  POST_RESET_PASSWORD: `${baseUrl}auth/reset-password`,
  GET_INFORMATION_PAYOUT: `${baseUrl}payouts/metadata`,
  PAYLATER_INSTALLMENTS: `${baseUrl}installments`,
  PAYLATER_TIERS: `${baseUrl}paylater-tiers`,
  PAYLATER_PAYMENT_INSTALLMENTS: `${baseUrl}installment-payments`,
};

export default endpoint;
