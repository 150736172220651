import PropTypes from 'prop-types';
import { FormatRupiah } from '@arismun/format-rupiah';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Grid, Card, CardHeader, CardContent, Box, Button, Stack, Typography, TextField, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  dataBank: PropTypes.array,
  handleSelectedBank: PropTypes.func,
  postPayment: PropTypes.func,
  loadingPayment: PropTypes.bool,
  dataBankSelected: PropTypes.object,
  setAddressPayment: PropTypes.string,
  paramsPage: PropTypes.string,
  paramsId: PropTypes.string,
  feeApp: PropTypes.number,
  feeAppTax: PropTypes.number,
  feeAppVat: PropTypes.number,
  paymentGatewayCharge: PropTypes.number,
  totalAll: PropTypes.number,
  dataPayment: PropTypes.object,
};

const StyledProductImg = styled('img')({
  width: '62px',
  height: '62px',
  objectFit: 'contain',
});

export default function ProductList({
  dataBank,
  handleSelectedBank,
  postPayment,
  loadingPayment,
  dataBankSelected,
  setAddressPayment,
  paramsPage,
  paramsId,
  feeApp,
  feeAppTax,
  feeAppVat,
  paymentGatewayCharge,
  totalAll,
  dataPayment,
  ...other
}) {
  const styleSelected = (value) => {
    let style = { marginTop: 10, marginBottom: 6, maxWidth: '50%' };
    if (value.is_selected) {
      style = { marginTop: 10, marginBottom: 6, border: '2px solid #2065D1', borderRadius: 6, maxWidth: '50%' };
    }

    return style;
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Card sx={{ minWidth: 350 }}>
          <CardContent>
            <Link
              to={`/payment/list?page=${paramsPage}&&id=${paramsId}`}
              component={RouterLink}
              sx={{ display: 'contents' }}
            >
              <Button startIcon={<Iconify icon="ic:twotone-chevron-left" />}>Back</Button>
            </Link>
          </CardContent>
          <CardHeader title={'Choose How You Want To Pay'} />
          <CardContent>
            {/* card list bank */}
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Grid container spacing={1} {...other} style={{ height: 190, overflow: 'scroll' }}>
                  {dataBank.map((value, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      key={index}
                      onClick={() => handleSelectedBank(value, index)}
                      style={styleSelected(value)}
                    >
                      <center>
                        <StyledProductImg alt={value.name} src={value.logo} />
                      </center>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
            {/* card informasi */}
            <Card>
              <CardContent>
                <Grid container spacing={3} {...other}>
                  {dataBankSelected.bank_code === 'cc' && (
                    <Grid item md={12}>
                      <Stack spacing={3}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="address"
                          label="Number Card"
                          onChange={(e) => setAddressPayment(e.target.value)}
                        />
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle2" noWrap>
                        Invoice
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <FormatRupiah value={dataPayment?.amount} />
                      </Typography>
                    </Stack>
                    {parseFloat(feeApp) !== 0 && (
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle2" noWrap>
                          Fee App
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <FormatRupiah value={feeApp} />
                        </Typography>
                      </Stack>
                    )}
                    {parseFloat(feeAppTax) !== 0 && (
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle2" noWrap>
                          Fee App Tax
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <FormatRupiah value={feeAppTax} />
                        </Typography>
                      </Stack>
                    )}
                    {parseFloat(feeAppVat) !== 0 && (
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle2" noWrap>
                          Fee App Vat
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <FormatRupiah value={feeAppVat} />
                        </Typography>
                      </Stack>
                    )}
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle2" noWrap>
                        Payment Gateway Charge
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <FormatRupiah value={paymentGatewayCharge} />
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle2" noWrap>
                        Total
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <FormatRupiah value={totalAll} />
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    {dataBankSelected.bank_code ? (
                      <Button sx={{ width: '100%' }} onClick={postPayment} variant="contained" size="large">
                        {loadingPayment}
                      </Button>
                    ) : (
                      <Button sx={{ width: '100%' }} variant="outlined" size="large" disabled>
                        Submit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
