import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { Card, CardContent, Box, Button, Typography, Grid } from '@mui/material';
import QRCode from 'react-qr-code';
import { styled } from '@mui/material/styles';
import { FormatRupiah } from '@arismun/format-rupiah';

// ----------------------------------------------------------------------

Qris.propTypes = {
  paramsPage: PropTypes.string,
  dataPayment: PropTypes.object,
  dispatch: PropTypes.object,
  user: PropTypes.any,
};

const StyledProductImg = styled('img')({
  width: '80px',
  height: '80px',
  objectFit: 'contain',
});

export default function Qris({ paramsPage, dataPayment, dispatch, user, ...other }) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Card sx={{ minWidth: 350 }}>
          <CardContent>
            <center>
              <StyledProductImg
                alt={'Qris'}
                src={
                  'https://cdn.invoiceplus.id/dev/ec8ad3ea5f9a572f4348ae41a245d9cbba3595efc91fae4c0fb5ac10191e5536d7c50cba3c9d651e0000368b823dc0439e.jpg'
                }
              />
              <Typography variant="h6" sx={{ mb: 3 }}>
                Payment Accepted Here
              </Typography>
            </center>
            <QRCode
              size={256}
              style={{ height: 220, maxWidth: '100%', width: '100%' }}
              value={dataPayment.address}
              viewBox={`0 0 256 256`}
            />
            <center>
              <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
                <FormatRupiah value={dataPayment.amount} />
              </Typography>
            </center>
            <Button
              sx={{ width: '100%' }}
              onClick={() => {
                if (user.access_token) {
                  navigate(`/invoice/list`, { replace: true });

                  dispatch({
                    type: 'EMPTY_DATA_PAYMENT',
                  });
                }
              }}
              variant="outlined"
              size="large"
              color="info"
            >
              Done Payment
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
