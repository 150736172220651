import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useForgotPassword = () => {
  const [emailData, setEmailData] = useState('');
  const [button, setButton] = useState('Send Link');

  const navigate = useNavigate();

  const postForgotPassword = async () => {
    setButton('Loading...');

    const data = {
      email: emailData,
    };

    api
      .post(endpoint.POST_FORGOT_PASSWORD, data)
      .then((res) => {
        setButton('Send Link');

        toastAlert(res.data.message, 'success');

        if (res.data.success) {
          setTimeout(() => {
            navigate('/login/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }
        setButton('Send Link');
        console.log(err);
      });
  };

  return {
    emailData,
    setEmailData,
    postForgotPassword,
    button,
  };
};

export default useForgotPassword;
