import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { FormatRupiah } from '@arismun/format-rupiah';
// componentss
import Label from '../../components/label';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

CardData.propTypes = {
  value: PropTypes.object,
  balanceData: PropTypes.number,
  changeCompany: PropTypes.func,
  confirmDeleteCompany: PropTypes.func,
};

export default function CardData({ value, balanceData, confirmDeleteCompany, changeCompany }) {
  const { name, logo, id, invoices, bills } = value;

  const navigate = useNavigate();

  return (
    <Card style={{ border: value.is_selected_company ? '2px solid #2065D1' : '0px solid' }}>
      <Stack onClick={() => changeCompany(value.id, value.name, value)}>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          {logo && (
            <StyledProductImg alt={name} src={`${logo}`} />
          )}
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Link color="inherit" underline="hover">
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Link>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2" noWrap>
              Invoice
            </Typography>
            <Typography variant="subtitle1">
              <Typography
                component="span"
                variant="body1"
                sx={{
                  color: 'text.disabled',
                }}
              >
                {invoices ? invoices.length : 0}
              </Typography>
              &nbsp;
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2" noWrap>
              Bill
            </Typography>
            <Typography variant="subtitle1">
              <Typography
                component="span"
                variant="body1"
                sx={{
                  color: 'text.disabled',
                }}
              >
                {bills ? bills.length : 0}
              </Typography>
              &nbsp;
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2" noWrap>
              Balance
            </Typography>
            <Typography variant="subtitle1">
              <Typography component="span" variant="body1">
                <FormatRupiah value={balanceData} />
              </Typography>
              &nbsp;
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Grid container>
          {value.is_selected_company && (
            <Grid item xs={2} sm={2} md={6}>
              <Label
                variant="filled"
                color={'info'}
                sx={{
                  textTransform: 'uppercase',
                  color: '#fff',
                }}
              >
                <Iconify
                  style={{ color: '#fff' }}
                  icon={
                    'streamline:interface-file-clipboard-check-checkmark-edit-task-edition-checklist-check-success-clipboard-form'
                  }
                />
                &nbsp; Active
              </Label>
            </Grid>
          )}
          <Grid item xs={2} sm={2} md={3}>
            <Label
              variant="filled"
              color={'default'}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(`/company/edit?company_id=${id}`)}
            >
              <Iconify style={{ color: '#2065D1' }} icon={'eva:edit-fill'} />
            </Label>
          </Grid>
          <Grid item xs={2} sm={2} md={3}>
            <Label
              variant="filled"
              color={'default'}
              style={{ cursor: 'pointer' }}
              onClick={() => confirmDeleteCompany(id)}
            >
              <Iconify color="red" icon={'mdi:trash'} />
            </Label>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}
