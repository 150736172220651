// @mui
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Typography, CardHeader } from '@mui/material';
import { FormatRupiah } from '@arismun/format-rupiah';

ChooseDetailPayment.propTypes = {
  dataDetailPayment: PropTypes.array,
};

export default function ChooseDetailPayment({ dataDetailPayment }) {
  return (
    <Card>
      <CardHeader title={'Payment Details'} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={8}>
            <Typography variant="subtitle2" mb={1}>
              Subtotal:
            </Typography>
            <Typography variant="subtitle2" mb={1}>
              Gateway Charge
            </Typography>
            <Typography variant="subtitle2" mb={1}>
              Fixed Charge
            </Typography>
            <Typography variant="subtitle2" mb={1}>
              Percent Charge
            </Typography>
            <Typography variant="subtitle2" mb={1}>
              Application Fee
            </Typography>
            <Typography variant="subtitle2" mb={1}>
              Tax
            </Typography>
            <Typography variant="subtitle2" mb={1}>
              Vat
            </Typography>
            <Typography variant="subtitle2" mb={1}>
              Total:
            </Typography>
          </Grid>
          <Grid item md={6} xs={4}>
            <Typography variant="subtitle2" mb={1} style={{ textAlign: 'right', color: 'green' }}>
              <FormatRupiah value={dataDetailPayment.subtotal} />
            </Typography>
            <Typography variant="subtitle2" mb={1} style={{ textAlign: 'right', color: 'red' }}>
              <FormatRupiah value={dataDetailPayment.gateway_charge} />
            </Typography>
            <Typography variant="subtitle2" mb={1} style={{ textAlign: 'right', color: 'red' }}>
              <FormatRupiah value={dataDetailPayment.fixed_charge} />
            </Typography>
            <Typography variant="subtitle2" mb={1} style={{ textAlign: 'right', color: 'red' }}>
              <FormatRupiah value={dataDetailPayment.percent_charge} />
            </Typography>
            <Typography variant="subtitle2" mb={1} style={{ textAlign: 'right', color: 'red' }}>
              <FormatRupiah value={dataDetailPayment.app_fee} />
            </Typography>
            <Typography variant="subtitle2" mb={1} style={{ textAlign: 'right', color: 'red' }}>
              <FormatRupiah value={dataDetailPayment.tax} />
            </Typography>
            <Typography variant="subtitle2" mb={1} style={{ textAlign: 'right', color: 'red' }}>
              <FormatRupiah value={dataDetailPayment.vat} />
            </Typography>
            <Typography variant="subtitle2" mb={1} style={{ textAlign: 'right', color: 'green' }}>
              <FormatRupiah value={dataDetailPayment.total} />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
