import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useDashboard = () => {
  const [dataDashboard, setDataDashboard] = useState({
    get_paid_compilation: 0,
    pay_compilation: 0,
    payout_compilation: 0,
    summary: '',
    total_bill: 0,
    total_customer: 0,
    total_invoice: 0,
    total_vendor: 0,
    upcoming_invoices: null,
    invoice_chart: {
      x: [],
      y: [],
    },
  });

  const [labelChartLine, setLabelChartLine] = useState([]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  useEffect(() => {
    if (companyId) {
      getData();
    }
  }, []);

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const getData = () => {
    api
      .get(`${endpoint.AGREGATE}/dashboard/${companyId}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setDataDashboard(res.data.data);

          const dataY = res?.data?.data?.bill_chart?.y?.data;

          const dataYPush = [];
          for (let i = 0; i < dataY.length; i += 1) {
            dataYPush.push(moment(dataY[i]).format('D/MM/YYYY'));
          }

          setLabelChartLine(dataYPush);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  return {
    dataDashboard,
    labelChartLine,
  };
};

export default useDashboard;
