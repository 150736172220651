const INITIAL_STATE = {
  dataRowContact: {
    id: '',
    type: '',
    company_id: '',
    company_name: '',
    contact_person: '',
    email: '',
    phone_number: '62',
    website: '',
    referred_by: '',
    about: '',
  },
};

const contact = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'DATA_ROW_CONTACT':
      return {
        ...state,
        dataRowContact: action.data,
      };
    default:
      return state;
  }
};

export default contact;
