import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Card,
  CardContent,
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Iconify from '../../../components/iconify';
// constants
import Colors from '../../../constant/colors';
// hooks
import useResetPassword from '../../../hooks/auth/useResetPassword';

// ----------------------------------------------------------------------

ChangePasswordPage.propTypes = {
  loadingRejected: PropTypes.bool,
};

export default function ChangePasswordPage() {
  const {
    passwordData,
    setPasswordData,
    passwordDataConfirm,
    setPasswordDataConfirm,
    showPassword,
    setShowPassword,
    showPasswordConfirm,
    setShowPasswordConfirm,
    postChangePassword,
    button,
  } = useResetPassword();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Card sx={{ minWidth: 350 }}>
          <CardContent>
            <Box
              component="div"
              sx={{
                width: 72,
                height: 35,
                display: 'inline-flex',
                mb: 1,
                background: `linear-gradient(180deg, ${Colors.PURPLE} 60%, #D04D4D)`,
                borderRadius: 1,
              }}
            >
              <img src="/assets/img/logo.png" alt="" style={{ padding: 4 }} />
            </Box>
            <Typography variant="h5" component="div" sx={{ mb: 0 }}>
              Change Password
            </Typography>
            <hr style={{ marginBottom: 24, border: '0.5px solid #ebebeb' }} />
            <Stack spacing={3}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                value={passwordData}
                onChange={(e) => setPasswordData(e.target.value)}
                name="password"
                label="Enter new password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                value={passwordDataConfirm}
                onChange={(e) => setPasswordDataConfirm(e.target.value)}
                name="password"
                label="Confirm new password"
                type={showPasswordConfirm ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} edge="end">
                        <Iconify icon={showPasswordConfirm ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={postChangePassword}
                sx={{
                  borderRadius: 20,
                  backgroundColor: Colors.PRIMARY,
                  '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
                }}
              >
                {button}
              </LoadingButton>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
