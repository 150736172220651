import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// sections
import { ListBank } from '../../sections/payment';
// components
import usePayment from '../../hooks/payment/usePayment';
// ----------------------------------------------------------------------

export default function ChangePasswordPage() {
  const {
    dataBank,
    handleSelectedBank,
    total,
    charge,
    payable,
    paramsPage,
    paramsId,
    postPayment,
    loadingPayment,
    dataBankSelected,
    setAddressPayment,
    feeApp,
    feeAppTax,
    feeAppVat,
    paymentGatewayCharge,
    totalAll,
    dataPayment,
  } = usePayment();

  return (
    <>
      <Helmet>
        <title> Payment: Choose Bank | InvoicePlus </title>
      </Helmet>

      <Container>
        <ToastContainer />

        <Grid item xs={12} md={6} lg={4}>
          <ListBank
            dataBank={dataBank}
            handleSelectedBank={handleSelectedBank}
            total={total}
            charge={charge}
            payable={payable}
            postPayment={postPayment}
            loadingPayment={loadingPayment}
            dataBankSelected={dataBankSelected}
            setAddressPayment={setAddressPayment}
            paramsPage={paramsPage}
            paramsId={paramsId}
            feeApp={feeApp}
            feeAppTax={feeAppTax}
            feeAppVat={feeAppVat}
            paymentGatewayCharge={paymentGatewayCharge}
            totalAll={totalAll}
            dataPayment={dataPayment}
          />
        </Grid>
      </Container>
    </>
  );
}
