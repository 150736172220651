import PropTypes from 'prop-types';
// @mui
import { Grid, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

import { FormatRupiah } from '@arismun/format-rupiah';

import Card from './Card';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  dataBank: PropTypes.func,
  open: PropTypes.string,
  setOpen: PropTypes.string,
  fullWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  submitRequestOTP: PropTypes.func,
  setAmountData: PropTypes.number,
  loadingPayoutFund: PropTypes.bool,
  informationPayout: PropTypes.object,
};

const styleTitle = { color: 'red', fontSize: 12, marginBottom: 12 };

export default function ProductList({
  dataBank,
  open,
  setOpen,
  fullWidth,
  maxWidth,
  submitRequestOTP,
  setAmountData,
  loadingPayoutFund,
  nameCompany,
  balanceCompany,
  informationPayout,
  ...other
}) {
  return (
    <Grid container spacing={3} {...other}>
      {dataBank.map((value, index) => (
        <Grid
          style={{ cursor: 'pointer' }}
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
          onClick={() => {
            // changeCompany(value.id, value.name, value)
            setOpen(true);
          }}
        >
          <Card value={value} />
        </Grid>
      ))}
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Payout By Wire Transfer ({nameCompany})</DialogTitle>
        <DialogContent>
          <DialogContentText style={styleTitle}>
            Transaction Limit: <FormatRupiah value={informationPayout.min_payout} /> -{' '}
            <FormatRupiah value={informationPayout.max_payout} />
          </DialogContentText>
          <DialogContentText style={styleTitle}>Fee: {informationPayout.fee}</DialogContentText>
          <DialogContentText style={styleTitle}>
            Balance: <FormatRupiah value={balanceCompany} />
          </DialogContentText>
          <FormControl variant="filled" style={{ width: '100%' }}>
            <OutlinedInput
              type={'number'}
              id="outlined-adornment-password"
              onChange={(e) => setAmountData(e.target.value)}
              endAdornment={<InputAdornment position="end">IDR</InputAdornment>}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
          <Button variant="contained" onClick={submitRequestOTP}>
            {loadingPayoutFund}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
