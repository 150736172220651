import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useCompany = () => {
  const INITIAL_STATE_ADD_COMPANY = {
    address: '',
    address2: '',
    email: '',
    logo: '',
    name: '',
    phone_number: '62',
    region_id: 0,
    tax_id: '',
    city_id: '0',
    country_id: '0',
    paylater_tier_id: ''
  };

  const [dataCompany, setDataCompany] = useState([]);
  const [dataCountries, setDataCountries] = useState([{ country_id: 1, name: 'Indonesia' }]);
  const [dataProvinces, setDataProvinces] = useState([]);
  const [dataCity, setDataCity] = useState([]);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [dataFormCompany, setDataFormCompany] = useState(INITIAL_STATE_ADD_COMPANY);
  const [loadingImage, setLoadingImage] = useState(false);
  const [idCompany, setIdCompany] = useState(null);
  const [openModalVerify, setOpenVerify] = useState(false);
  const [buttonVerify, setButtonVerify] = useState('Resend Email');
  const [openModalVerifyOtp, setOpenModalVerifyOtp] = useState(false);
  const [buttonVerifyOtp, setButtonVerifyOtp] = useState('Submit');
  const [otp, setOtp] = useState('');

  const fullWidthModal = true;

  const [button, setButton] = useState('Submit');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId, companyEmail, userProfile } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
    companyEmail: states?.CompanyReducer?.companyEmail,
    userProfile: states?.UserReducer?.dataUserProfile,
  }));

  useEffect(() => {
    getDataRegions();

    if (companyId) {
      getDataCompany(companyId);

      const urlParams = new URLSearchParams(window.location.search);
      const myParamId = urlParams.get('company_id');

      if (myParamId) {
        getDataCompanyDetail(myParamId);
        setIdCompany(myParamId);
      }
    }
  }, []);

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  const getDataCompany = async (companyIdData) => {
    setLoadingCompany(true);

    api
      .get(endpoint.GET_USER_COMPANY, getAuthHeader)
      .then((res) => {
        setLoadingCompany(false);

        if (res.data.success) {
          const dataCompany = res.data.data;

          if (dataCompany.length === 0) {
            dispatch({
              type: 'DATA_USER_COMPANY_ID',
              data: '',
            });

            dispatch({
              type: 'DATA_USER_COMPANY_NAME',
              data: '',
            });

            dispatch({
              type: 'DATA_USER_COMPANY',
              data: [],
            });
          }

          for (let i = 0; i < dataCompany.length; i += 1) {
            let select = false;
            if (companyIdData === dataCompany[i].id) {
              select = true;
            }

            dataCompany[i].is_selected_company = select;
          }

          dispatch({
            type: 'DATA_USER_COMPANY',
            data: dataCompany,
          });

          if (dataCompany.length === 1) {
            dispatch({
              type: 'DATA_USER_COMPANY_ID',
              data: dataCompany[0].id,
            });

            dispatch({
              type: 'DATA_USER_COMPANY_NAME',
              data: dataCompany[0].name,
            });

            dispatch({
              type: 'DATA_USER_COMPANY_EMAIL',
              data: dataCompany[0].email,
            });

            dispatch({
              type: 'DATA_USER_COMPANY_PHONE',
              data: dataCompany[0].phone_number,
            });

            dispatch({
              type: 'DATA_USER_COMPANY_ADDRESS',
              data: dataCompany[0].address,
            });

            dispatch({
              type: 'DATA_USER_COMPANY_LOGO',
              data: dataCompany[0].logo,
            });

            dataCompany[0].is_selected_company = true;
          }

          setDataCompany(dataCompany);
        }
      })
      .catch((err) => {
        setLoadingCompany(false);

        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataCompanyDetail = async (companyIdData) => {
    setLoadingCompany(true);

    api
      .get(`${endpoint.GET_USER_COMPANY}/${companyIdData}`, getAuthHeader)
      .then((res) => {
        setLoadingCompany(false);

        if (res.data.success) {
          const dataCompany = res.data.data;

          setDataFormCompany({
            address: dataCompany.address,
            address2: dataCompany.address_2,
            email: dataCompany.email,
            logo: dataCompany.logo,
            name: dataCompany.name,
            phone_number: dataCompany.phone_number,
            region_id: parseFloat(dataCompany.region_id),
            tax_id: dataCompany.tax_id,
            city_id: '0',
            country_id: '1',
            paylater_tier: dataCompany?.paylater_tier?.id,
          });
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingCompany(false);
        console.log(err);
      });
  };

  const getDataRegions = async () => {
    setLoadingCompany(true);

    api
      .get(endpoint.GET_REGIONS, getAuthHeader)
      .then((res) => {
        setLoadingCompany(false);

        const dataProv = res.data.data;
        for (let i = 0; i < dataProv.length; i += 1) {
          dataProv[i].region_id = dataProv[i].id;
        }

        setDataProvinces(dataProv);
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingCompany(false);
        console.log(err);
      });
  };

  const getDataRegionsChild = async (idChild = 0) => {
    setLoadingCompany(true);

    api
      .get(`${endpoint.GET_REGIONS}/${idChild}`, getAuthHeader)
      .then((res) => {
        const dataCity = res.data.data;
        for (let i = 0; i < dataCity.length; i += 1) {
          dataCity[i].city_id = dataCity[i].id;
        }

        setDataCity(res.data.data);
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingCompany(false);
        console.log(err);
      });
  };

  const handleChangeCompany = async (event) => {
    const val = event.target.value;

    setDataFormCompany({
      ...dataFormCompany,
      [event.target.name]: event.target.name === 'region_id' ? parseFloat(val) : val,
    });
  };

  const changeCompany = async (id, name, value) => {
    getDataCompany(id);

    dispatch({
      type: 'DATA_USER_COMPANY_ID',
      data: id,
    });

    dispatch({
      type: 'DATA_USER_COMPANY_NAME',
      data: name,
    });

    dispatch({
      type: 'DATA_USER_COMPANY_EMAIL',
      data: value.email,
    });

    dispatch({
      type: 'DATA_USER_COMPANY_PHONE',
      data: value.phone_number,
    });

    dispatch({
      type: 'DATA_USER_COMPANY_ADDRESS',
      data: value.address,
    });

    dispatch({
      type: 'DATA_USER_COMPANY_LOGO',
      data: value.logo,
    });

    for (let i = 0; i < dataCompany.length; i += 1) {
      let select = false;
      if (id === dataCompany[i].id) {
        select = true;
      }

      dataCompany[i].is_selected_company = select;
    }

    dispatch({
      type: 'DATA_USER_COMPANY',
      data: dataCompany,
    });

    toastAlert(`${name} switch successfully`, 'success');
  };

  const postDataCompany = async () => {
    setLoadingCompany(true);

    setButton('Loading...');

    api
      .post(endpoint.POST_USER_COMPANY, dataFormCompany, getAuthHeader)
      .then((res) => {
        console.log(res);
        setLoadingCompany(false);
        toastAlert(res.data.message, 'success');

        setButton('Submit');

        getDataCompany(0);

        if (res.data.success) {
          setTimeout(() => {
            navigate('/company/list/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          // jika account belum verify
          if (
            err.response.data.error_code === 'E-00047' ||
            err.response.data.message === 'email belum terverifikasi, mohon verifikasi terlebih dahulu'
          ) {
            setOpenVerify(true);
            toastAlert(err.response.data.message, 'error');
          }

          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingCompany(false);
        setButton('Submit');
        console.log(err);
      });
  };

  const postDataCompanyEdit = async () => {
    setLoadingCompany(true);

    setButton('Loading...');

    dataFormCompany.paylater_tier_id = dataFormCompany.paylater_tier;
    dataFormCompany.logo = dataFormCompany.logo ? dataFormCompany.logo : '';

    api
      .put(`${endpoint.POST_USER_COMPANY}/${idCompany}`, dataFormCompany, getAuthHeader)
      .then((res) => {
        setLoadingCompany(false);

        toastAlert(res.data.message, 'success');

        setButton('Submit');

        if (res.data.success) {
          setTimeout(() => {
            navigate('/company/list/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingCompany(false);
        setButton('Submit');
        console.log(err);
      });
  };

  const postDataFileImage = (event) => {
    const usernameData = 'uploader';
    const passwordData = '30f87203fed3b0b3c2e517ddea93b4d6';

    try {
      setLoadingImage(true);

      const file = event.target.files[0];

      const requestBody = new FormData();
      requestBody.append('type', 'company-logo');
      requestBody.append('file', file);

      axios
        .post(endpoint.POST_UPLOAD_FILE, requestBody, {
          auth: {
            username: usernameData,
            password: passwordData,
          },

          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          setLoadingImage(false);

          setDataFormCompany({
            ...dataFormCompany,
            logo: res.data.data.file_name,
          });
        });
    } catch (err) {
      console.log(err.message);
      setLoadingImage(false);
    }
  };

  const confirmDeleteCompany = async (idCompany = null) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        postDeleteCompany(idCompany);
      }
    });
  };

  const postDeleteCompany = async (idCompany) => {
    api
      .delete(`${endpoint.POST_USER_COMPANY}/${idCompany}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          toastAlert(res.data.message, 'success');

          getDataCompany(companyId);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postVerifyEmail = async () => {
    setButtonVerify('Loading...');

    api
      .post(`${endpoint.POST_RESEND_EMAIL_VERIFY}`, { email: userProfile?.email }, getAuthHeader)
      .then((res) => {
        setButtonVerify('Resend Email');

        if (res.data.success) {
          toastAlert(res.data.message, 'success');
          setOpenModalVerifyOtp(true);
        }
      })
      .catch((err) => {
        setButtonVerify('Resend Email');
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postVerifyEmailOtp = async () => {
    setButtonVerifyOtp('Loading...');

    const formData = {
      email: userProfile?.email,
      email_token: otp,
    };

    api
      .post(`${endpoint.POST_EMAIL_VERIFY}`, formData, getAuthHeader)
      .then((res) => {
        setButtonVerifyOtp('Submit');

        if (res.data.success) {
          toastAlert(res.data.message, 'success');
          setButtonVerifyOtp(false);

          postDataCompany();
        }
      })
      .catch((err) => {
        setButtonVerifyOtp('Submit');
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  return {
    dataCompany,
    loadingCompany,
    handleChangeCompany,
    postDataCompany,
    dataFormCompany,
    button,
    dataCountries,
    dataProvinces,
    changeCompany,
    getDataRegionsChild,
    dataCity,
    loadingImage,
    postDataFileImage,
    confirmDeleteCompany,
    postDataCompanyEdit,
    openModalVerify,
    setOpenVerify,
    fullWidthModal,
    buttonVerify,
    postVerifyEmail,
    otp,
    setOtp,
    postVerifyEmailOtp,
    openModalVerifyOtp,
    buttonVerifyOtp,
    setOpenModalVerifyOtp,
  };
};

export default useCompany;
