import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useContact = () => {
  const { user, companyId, dataRowContact } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
    dataRowContact: states?.ContactReducer?.dataRowContact,
  }));

  const INITIAL_STATE_CONTACT = {
    about: dataRowContact.about,
    company_id: companyId,
    company_name: dataRowContact.company_name,
    contact_person: dataRowContact.contact_person,
    email: dataRowContact.email,
    phone_number: dataRowContact.phone_number,
    referred_by: dataRowContact.referred_by,
    type: dataRowContact.type,
    website: dataRowContact.website,
  };

  const [dataContact, setDataContact] = useState([]);
  const [loadingContact, setLoadingContact] = useState(false);
  const [dataFormContact, setDataFormContact] = useState(INITIAL_STATE_CONTACT);
  const [button, setButton] = useState('Submit');
  const [typeContact, setTypeContact] = useState('vendor');
  const [idContact, setIdContact] = useState('0');

  const pageSizeTable = 10;
  const [statusTable, setStatusTable] = useState('vendor');
  const [dataTotalTable, setTotalDataTable] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    if (companyId) {
      getDataContact();
    }
  }, []);

  const getDataContact = async (newPage = 1, filterTable = '') => {
    api
      .get(
        `${endpoint.GET_USER_CONTACT}/${companyId}/${statusTable}?page_size=${pageSizeTable}&&page=${newPage}`,
        getAuthHeader
      )
      .then((res) => {
        if (res.data.success) {
          const dataRes = res.data.data.data;
          const totalData = res.data.data.total_data;
          const totalDataPlus = dataContact.length + dataRes.length;

          if (totalDataPlus <= totalData) {
            setDataContact([...dataContact, ...dataRes]);
          }

          if (filterTable === 'filter') {
            setDataContact(dataRes);
          }

          setTotalDataTable(totalData);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleChangeContact = async (event) => {
    setDataFormContact({
      ...dataFormContact,
      [event.target.name]: event.target.value,
    });
  };

  const postDataContact = async () => {
    setButton('Loading...');

    api
      .post(endpoint.POST_USER_CONTACT, dataFormContact, getAuthHeader)
      .then((res) => {
        setLoadingContact(false);

        toastAlert(res.data.message, 'success');

        setButton('Submit');

        if (res.data.success) {
          setTimeout(() => {
            navigate('/contacts/list/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingContact(false);
        setButton('Submit');
        console.log(err);
      });
  };

  const postDataEditContact = async () => {
    setButton('Loading...');

    const urlParams = new URLSearchParams(window.location.search);
    const myParamId = urlParams.get('contacts_id');

    api
      .put(`${endpoint.POST_USER_CONTACT}/${companyId}/${myParamId}`, dataFormContact, getAuthHeader)
      .then((res) => {
        setLoadingContact(false);

        toastAlert(res.data.message, 'success');

        setButton('Submit');

        if (res.data.success) {
          setTimeout(() => {
            navigate('/contacts/list/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingContact(false);
        setButton('Submit');
        console.log(err);
      });
  };

  const confirmDeleteContact = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        postDeleteContact();
      }
    });
  };

  const postDeleteContact = async () => {
    api
      .delete(`${endpoint.DELETE_USER_CONTACT}/${idContact}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          toastAlert(res.data.message, 'success');

          getDataContact(1, '');
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const submitSearch = () => {
    getDataContact(1, 'filter');
  };

  return {
    dataContact,
    loadingContact,
    handleChangeContact,
    postDataContact,
    dataFormContact,
    button,
    setTypeContact,
    confirmDeleteContact,
    setIdContact,
    getDataContact,
    postDataEditContact,
    idContact,
    dataTotalTable,
    submitSearch,
    setStatusTable,
  };
};

export default useContact;
