import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, TextField, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function OtpForm() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/change-password/');
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          name="email"
          label="Enter your code"
        />
      </Stack>

      <br />

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        SUBMIT
      </LoadingButton>

      <br />

      <Typography variant="body2" sx={{ mb: 5 }}>
        Don't have an account? {''}
        <Link style={{ cursor: 'pointer' }} onClick={() => navigate('/register')} variant="subtitle2">
          Create account
        </Link>
      </Typography>
    </>
  );
}
