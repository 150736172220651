import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Stack,
  CardContent,
  Grid,
  CardHeader,
  Typography,
  Chip,
  Button
} from '@mui/material';
import ucfirst from 'ucfirst';

CardDetailInfo.propTypes = {
  title: PropTypes.string,
  dataDetailInstallments: PropTypes.func
};

export default function CardDetailInfo({
  title,
  dataDetailInstallments,
  ...other
}) {
  
  // 0 = requested 
  // 1 = approved 
  // 2 = rejected 
  // 3 = success 
  const validationStatusView = (valueData) => {
    let colors = 'warning';
    let value = 'Requested';
    if (valueData === 1) {
      colors = 'info';
      value = 'Approved';
    }
    if (valueData === 2) {
      colors = 'danger';
      value = 'Rejected';
    }
    if (valueData === 3) {
      colors = 'success';
      value = 'Success';
    }
    return (
      <Button color={colors} variant="outlined">{value}</Button>
    );
  }

  return (
    <Card {...other}>
      <CardHeader title={title} />
      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Status
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {validationStatusView(dataDetailInstallments?.status)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Installment Number
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {dataDetailInstallments?.installment_number}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Amount
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Rp. {new Intl.NumberFormat().format(dataDetailInstallments?.amount)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Loan Type
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {ucfirst(dataDetailInstallments?.loan_type)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Loan Period
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {dataDetailInstallments?.loan_period}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Loan Fee Rate
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {dataDetailInstallments?.loan_fee_rate}%
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Loan Fee
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Rp. {new Intl.NumberFormat().format(dataDetailInstallments?.loan_fee)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Loan Late Fee Rate
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {dataDetailInstallments?.loan_late_fee_rate}%
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Loan Late Fee
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Rp. {new Intl.NumberFormat().format(dataDetailInstallments?.loan_late_fee)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Period Installment Amount
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Rp. {new Intl.NumberFormat().format(dataDetailInstallments?.period_installment_amount)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  Amount Paid
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Rp. {new Intl.NumberFormat().format(dataDetailInstallments?.total_paid)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
