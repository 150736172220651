// @mui
import PropTypes from 'prop-types';
import { Box, Card, TextField, Stack, CardHeader, CardContent, Grid, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

AddForm.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  postDataEditContact: PropTypes.func,
};

export default function AddForm({
  title,
  subheader,
  handleChangeContact,
  postDataContact,
  dataFormContact,
  dataCompany,
  postDataEditContact,
  button,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Contact Type"
                  name="type"
                  select
                  value={dataFormContact.type}
                  onChange={(e) => handleChangeContact(e)}
                >
                  <MenuItem key={'0'} value={'0'}>
                    Select
                  </MenuItem>
                  <MenuItem key={'1'} value={'vendor'}>
                    Vendor/Supplier (Billing)
                  </MenuItem>
                  <MenuItem key={'2'} value={'customer'}>
                    Customer (Invoice)
                  </MenuItem>
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="company_name"
                  label="Company Name"
                  value={dataFormContact.company_name}
                  onChange={(e) => handleChangeContact(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="contact_person"
                  label="Contact Person"
                  value={dataFormContact.contact_person}
                  onChange={(e) => handleChangeContact(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="email"
                  label="Email Address"
                  value={dataFormContact.email}
                  onChange={(e) => handleChangeContact(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="phone_number"
                  label="Phone Number"
                  value={dataFormContact.phone_number}
                  onChange={(e) => handleChangeContact(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="website"
                  label="Website"
                  value={dataFormContact.website}
                  onChange={(e) => handleChangeContact(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="referred_by"
                  label="Reffered By"
                  value={dataFormContact.referred_by}
                  onChange={(e) => handleChangeContact(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="about"
                  label="About"
                  value={dataFormContact.about}
                  onChange={(e) => handleChangeContact(e)}
                  multiline
                  rows={6}
                  maxRows={4}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={title === 'Contact Add' ? postDataContact : postDataEditContact}
                >
                  {button}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
