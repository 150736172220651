import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FormatRupiah } from '@arismun/format-rupiah';
import moment from 'moment';
// @mui
import { Card, CardContent, Button, TextField, Typography, Chip, Grid, Alert } from '@mui/material';

import { styled } from '@mui/material/styles';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  loadingRejected: PropTypes.bool,
  paramsPage: PropTypes.string,
  paramsId: PropTypes.string,
  dataListPayment: PropTypes.object,
  postStatusReject: PropTypes.func,
  getDataInvoiceDownload: PropTypes.func,
  accessToken: PropTypes.string,
  imageLogo: PropTypes.string,
};

const StyledProductImg = styled('img')({
  objectFit: 'contain',
});

export default function ProductList({
  paramsPage,
  paramsId,
  dataListPayment,
  getDataInvoiceDownload,
  accessToken,
  imageLogo,
}) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Card sx={{ minWidth: 350 }}>
          <CardContent>
            {accessToken && (
              <Button
                onClick={() => {
                  navigate(`/${paramsPage}/list`);
                }}
                startIcon={<Iconify icon="ic:twotone-chevron-left" />}
              >{`Back to ${paramsPage}`}</Button>
            )}
          </CardContent>
          <CardContent>
            <center>
              <StyledProductImg
                sx={{ mb: 1 }}
                alt={''}
                src={imageLogo}
                style={{ maxWidth: 100, height: 100, borderRadius: 6 }}
              />
              <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                {dataListPayment.invoice_number}
              </Typography>
              <Typography variant="h5" component="div" sx={{ mb: 0 }} color="text.secondary">
                {dataListPayment.contact_person}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {dataListPayment.email}
              </Typography>
              <Chip
                avatar={<Iconify icon={'tabler:download'} color="#1877F2" width={32} />}
                label="INVOICE"
                size="small"
                variant="outlined"
                sx={{ mb: 2, mt: 2 }}
                onClick={() => getDataInvoiceDownload(paramsId)}
              />
              <Typography variant="h3" component="div" sx={{ mb: 2 }}>
                <FormatRupiah value={dataListPayment.grand_total} />
              </Typography>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: '100%' }}
                disabled
                label="Due Date"
                value={dataListPayment.due_date}
                sx={{ mb: 2 }}
              />

              {/* jika sudah terbayar, maka button disabled */}
              {dataListPayment.status === 'paid' ||
              dataListPayment.status === 'paid gateway' ||
              dataListPayment.status === 'paid manual' ? (
                <Button sx={{ width: '100%' }} variant="contained" size="large" color={'success'} disabled>
                  Paid
                </Button>
              ) : null}

              {/* jika belum terbayar, maka button redirect ke payment */}
              {dataListPayment.status === 'pending' ||
              dataListPayment.status === 'unsend' ||
              dataListPayment.status === 'sent' ? (
                <Button
                  sx={{ width: '100%' }}
                  onClick={() => {
                    navigate(`/payment/pay?page=${paramsPage}&&id=${paramsId}`);
                  }}
                  variant="contained"
                  size="large"
                >
                  Pay Now
                </Button>
              ) : null}
            </center>

            {/* jika sudah terbayar, maka button disabled */}
            {dataListPayment.status === 'paid' ||
            dataListPayment.status === 'paid gateway' ||
            dataListPayment.status === 'paid manual' ? (
              <Alert sx={{ mt: 2 }} severity="success">
                INFO: Telah terbayar pada{' - '}
                {dataListPayment.callback_paid_at !== '0001-01-01T00:00:00Z'
                  ? moment(dataListPayment.callback_paid_at).format('D/MM/YYYY')
                  : ''}{' '}
                menggunakan Metode{' '}
                {dataListPayment.payment_method === 'va'
                  ? 'Virtual Account'
                  : dataListPayment.payment_method === 'cc'
                  ? 'Credit Card'
                  : dataListPayment.payment_method === 'qris'
                  ? 'Qris'
                  : 'Paid Manual'}
              </Alert>
            ) : null}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
