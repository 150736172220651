// @mui
import PropTypes from 'prop-types';
import { Box, Card, TextField, Stack, CardHeader, CardContent, Grid, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

AddFormEdit.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  button: PropTypes.string,
  list: PropTypes.array.isRequired,
  postDataServices: PropTypes.func,
  dataFormServices: PropTypes.func,
  handleChangeServices: PropTypes.func,
  postDataEditServices: PropTypes.func,
};

export default function AddFormEdit({
  title,
  subheader,
  handleChangeServices,
  postDataServices,
  dataFormServices,
  postDataEditServices,
  button,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Service Type"
                  name="type"
                  select
                  value={dataFormServices.type}
                  onChange={(e) => handleChangeServices(e)}
                >
                  <MenuItem key={'0'} value={'0'}>
                    Select
                  </MenuItem>
                  <MenuItem key={'1'} value={'vendor'}>
                    Vendor/Supplier (Billing)
                  </MenuItem>
                  <MenuItem key={'2'} value={'customer'}>
                    Customer (Invoice)
                  </MenuItem>
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="name"
                  label="Jasa / Produk"
                  value={dataFormServices.name}
                  onChange={(e) => handleChangeServices(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="price_per_unit"
                  label="Price Per Unit (IDR)"
                  type={'number'}
                  value={dataFormServices.price_per_unit}
                  onChange={(e) => handleChangeServices(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="description"
                  label="Description"
                  value={dataFormServices.description}
                  onChange={(e) => handleChangeServices(e)}
                  multiline
                  rows={6}
                  maxRows={4}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={title === 'Jasa / Produk Add' ? postDataServices : postDataEditServices}
                >
                  {button}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
