import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useServices = () => {
  const { user, companyId } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const INITIAL_STATE_ADD_SERVICES = {
    company_id: companyId,
    description: '',
    name: '',
    price_per_unit: '',
    type: '0',
  };

  const [dataServices, setDataServices] = useState([]);
  const [loadingContact, setLoadingContact] = useState(false);
  const [dataFormServices, setDataFormServices] = useState(INITIAL_STATE_ADD_SERVICES);
  const [button, setButton] = useState('Submit');
  const [typeContact, setTypeContact] = useState('vendor');
  const [idServices, setIdServices] = useState('0');
  const pageSizeTable = 10;
  const [statusTable, setStatusTable] = useState('all');
  const [dataTotalTable, setTotalDataTable] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    if (companyId) {
      getDataServices();

      const urlParams = new URLSearchParams(window.location.search);
      const myParamId = urlParams.get('services_id');

      if (myParamId) {
        getDataServicesDetail(myParamId);
        setIdServices(myParamId);
      }
    }
  }, []);

  const getDataServices = async (newPage = 1, filterTable = '') => {
    api
      .get(
        `${endpoint.GET_USER_SERVICES}/${companyId}/${statusTable}?page_size=${pageSizeTable}&&page=${newPage}`,
        getAuthHeader
      )
      .then((res) => {
        if (res.data.success) {
          const dataRes = res.data.data.data;
          const totalData = res.data.data.total_data;
          const totalDataPlus = dataServices.length + dataRes.length;

          if (totalDataPlus <= totalData) {
            setDataServices([...dataServices, ...dataRes]);
          }

          if (filterTable === 'filter') {
            setDataServices(dataRes);
          }

          setTotalDataTable(totalData);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const getDataServicesDetail = async (myParamId) => {
    api
      .get(`${endpoint.GET_USER_SERVICES}/admin?page_size=${9999999999}&&page=${1}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          const dataServices = res.data.data.data;
          const filterData = dataServices.filter((i) => i.id === myParamId);

          console.log('filterData', filterData);

          if (filterData.length > 0) {
            setDataFormServices({
              company_id: companyId,
              description: filterData[0].description,
              name: filterData[0].name,
              price_per_unit: filterData[0].price_per_unit,
              type: filterData[0].type,
            });
          }
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleChangeServices = async (event) => {
    if (event.target.name === 'price_per_unit') {
      setDataFormServices({
        ...dataFormServices,
        [event.target.name]: parseFloat(event.target.value),
      });
    } else {
      setDataFormServices({
        ...dataFormServices,
        [event.target.name]: event.target.value,
      });
    }
  };

  const postDataServices = async () => {
    setButton('Loading...');

    api
      .post(endpoint.GET_USER_SERVICES, dataFormServices, getAuthHeader)
      .then((res) => {
        setLoadingContact(false);

        toastAlert(res.data.message, 'success');

        setButton('Submit');

        if (res.data.success) {
          setTimeout(() => {
            navigate('/services/list/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingContact(false);
        setButton('Submit');
        console.log(err);
      });
  };

  const postDataEditServices = async () => {
    setButton('Loading...');

    api
      .put(`${endpoint.GET_USER_SERVICES}/${idServices}`, dataFormServices, getAuthHeader)
      .then((res) => {
        setLoadingContact(false);
        toastAlert(res.data.message, 'success');
        setButton('Submit');
        if (res.data.success) {
          setTimeout(() => {
            navigate('/services/list/');
          }, 2000);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        setLoadingContact(false);
        setButton('Submit');
        console.log(err);
      });
  };

  const confirmDeleteServices = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You wont be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        postDeleteServices();
      }
    });
  };

  const postDeleteServices = async () => {
    api
      .delete(`${endpoint.GET_USER_SERVICES}/${idServices}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          toastAlert(res.data.message, 'success');

          submitSearch();
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }

        console.log(err);
      });
  };

  const submitSearch = () => {
    getDataServices(1, 'filter');
  };

  return {
    dataServices,
    loadingContact,
    handleChangeServices,
    postDataServices,
    dataFormServices,
    button,
    setTypeContact,
    typeContact,
    confirmDeleteServices,
    setIdServices,
    idServices,
    getDataServices,
    postDataEditServices,
    dataTotalTable,
    submitSearch,
    setStatusTable,
  };
};

export default useServices;
