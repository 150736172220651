import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useResetPassword = () => {
  const [passwordData, setPasswordData] = useState('');
  const [passwordDataConfirm, setPasswordDataConfirm] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [button, setButton] = useState('Change Password');
  const [paramToken, setParamToken] = useState('');
  const [paramEmail, setParamEmail] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParamToken = urlParams.get('token');
    const myParamEmail = urlParams.get('email');

    setParamToken(myParamToken);
    setParamEmail(myParamEmail);
  }, []);

  const postChangePassword = async () => {
    if (passwordData !== passwordDataConfirm) {
      toastAlert('Password tidak cocok!', 'error');
    } else {
      setButton('Loading...');

      const data = {
        email: paramEmail,
        new_password: passwordData,
        new_password_confirmation: passwordDataConfirm,
        token: paramToken,
      };

      api
        .post(endpoint.POST_RESET_PASSWORD, data)
        .then((res) => {
          setButton('Change Password');

          toastAlert(res.data.message, 'success');

          if (res.data.success) {
            setTimeout(() => {
              navigate('/login/');
            }, 2000);
          }
        })
        .catch((err) => {
          if (!err.response.data.success) {
            toastAlert(err.response.data.message, 'error');
          }
          setButton('Change Password');
          console.log(err);
        });
    }
  };

  return {
    passwordData,
    setPasswordData,
    passwordDataConfirm,
    setPasswordDataConfirm,
    showPassword,
    setShowPassword,
    showPasswordConfirm,
    setShowPasswordConfirm,
    postChangePassword,
    button,
  };
};

export default useResetPassword;
