import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Grid, Container, Stack, Typography, Link, Button, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// sections
import { AddForm } from '../../sections/ticket';
// components
import Iconify from '../../components/iconify';
import useTicket from '../../hooks/ticket/useTicket';
// constant
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

export default function TicketAddPage() {
  const { postDataTicket, handleChangeTicket, button, dataFormTicket, loadingImage, postDataFileImage } = useTicket();

  return (
    <>
      <Helmet>
        <title>Ticket: Add | InvoicePlus</title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Ticket / Add
            </Typography>
            <Link to="/ticket/list" component={RouterLink} sx={{ display: 'contents' }}>
              <Button startIcon={<Iconify icon="ic:twotone-chevron-left" />} sx={{ color: Colors.WHITE, marginTop: 2 }}>
                Back to list
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid item xs={12} md={6} lg={4}>
          <AddForm
            handleChangeTicket={handleChangeTicket}
            postDataTicket={postDataTicket}
            title="Ticket Add"
            button={button}
            dataFormTicket={dataFormTicket}
            loadingImage={loadingImage}
            postDataFileImage={postDataFileImage}
          />
        </Grid>
      </Container>
    </>
  );
}
