// @mui
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, TextField, Stack, CardHeader, CardContent, Grid, IconButton, Button } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { Template1, Template2, Template3, Template4, Template5, Template6 } from './components';

// ----------------------------------------------------------------------

AddFormView.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
  dataFormTicket: PropTypes.func,
  lines: PropTypes.array.isRequired,
  subTotalServices: PropTypes.number,
  taxRate: PropTypes.number,
  vatRate: PropTypes.number,
  totalTaxServices: PropTypes.number,
  totalVateTaxServices: PropTypes.number,
  grandTotalServices: PropTypes.number,
  invoiceNumber: PropTypes.string,
  dueDate: PropTypes.string,
  firstPaymentDate: PropTypes.string,
  customerSelected: PropTypes.array,
  discount: PropTypes.any,
};

export default function AddFormView({
  title,
  subheader,
  lines,
  subTotalServices,
  taxRate,
  vatRate,
  totalTaxServices,
  totalVateTaxServices,
  grandTotalServices,
  invoiceTemplate,
  invoiceNumber,
  dueDate,
  firstPaymentDate,
  customerSelected,
  discount,
  ...other
}) {
  const [openModalEditCompany, setOpenModalEditCompany] = useState(null);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('xs');

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      {parseFloat(invoiceTemplate) === 1 && (
        <Template1
          invoiceNumber={invoiceNumber}
          dueDate={dueDate}
          firstPaymentDate={firstPaymentDate}
          customerSelected={customerSelected}
        />
      )}
      {parseFloat(invoiceTemplate) === 2 && (
        <Template2
          invoiceNumber={invoiceNumber}
          dueDate={dueDate}
          firstPaymentDate={firstPaymentDate}
          customerSelected={customerSelected}
        />
      )}
      {parseFloat(invoiceTemplate) === 3 && (
        <Template3
          invoiceNumber={invoiceNumber}
          dueDate={dueDate}
          firstPaymentDate={firstPaymentDate}
          customerSelected={customerSelected}
        />
      )}
      {parseFloat(invoiceTemplate) === 4 && (
        <Template4
          invoiceNumber={invoiceNumber}
          dueDate={dueDate}
          firstPaymentDate={firstPaymentDate}
          customerSelected={customerSelected}
        />
      )}
      {parseFloat(invoiceTemplate) === 5 && (
        <Template5
          invoiceNumber={invoiceNumber}
          dueDate={dueDate}
          firstPaymentDate={firstPaymentDate}
          customerSelected={customerSelected}
        />
      )}
      {parseFloat(invoiceTemplate) === 6 && (
        <Template6
          invoiceNumber={invoiceNumber}
          dueDate={dueDate}
          firstPaymentDate={firstPaymentDate}
          customerSelected={customerSelected}
        />
      )}

      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={4}>Invoice Summary</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lines.map((row) => (
                        <TableRow key={row.title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell align="right">{row.quantity}</TableCell>
                          <TableCell align="right">{new Intl.NumberFormat().format(row.price)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    {lines.length === 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={4}>
                            Data service is empty
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    <TableBody>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Subtotal:</TableCell>
                      <TableCell>Rp{new Intl.NumberFormat().format(subTotalServices)}</TableCell>
                    </TableBody>
                    <TableBody>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Discount ({discount}%)</TableCell>
                      <TableCell>Rp{new Intl.NumberFormat().format((subTotalServices * discount) / 100)}</TableCell>
                    </TableBody>
                    <TableBody>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Tax ({taxRate}%)</TableCell>
                      <TableCell>Rp{new Intl.NumberFormat().format(totalTaxServices)}</TableCell>
                    </TableBody>
                    <TableBody>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Vat ({vatRate}%)</TableCell>
                      <TableCell>Rp{new Intl.NumberFormat().format(totalVateTaxServices)}</TableCell>
                    </TableBody>
                    <TableBody>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Total:</TableCell>
                      <TableCell>Rp{new Intl.NumberFormat().format(grandTotalServices)}</TableCell>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Button variant="outlined" size="small">
                  <Iconify icon={'material-symbols:print-outline'} sx={{ mr: 1 }} /> Print
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack spacing={3}>
                <Button variant="outlined" size="small">
                  <Iconify icon={'ion:download-outline'} sx={{ mr: 1 }} /> Download
                </Button>
              </Stack>
            </Grid> */}

            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openModalEditCompany}
              onClose={() => setOpenModalEditCompany(false)}
            >
              <DialogTitle>Send Payment Request</DialogTitle>
              <DialogContent>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                  }}
                />

                <Grid container spacing={3} style={{ marginTop: -8 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Stack spacing={3}>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="message"
                        label="Customer Email"
                        // value={dataFormTicket.message}
                        // onChange={(e) => handleChangeTicket(e)}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Stack spacing={3}>
                      <TextField
                        name="about"
                        label="Payment request note (optional)"
                        // value={dataFormContact.about}
                        // onChange={(e) => handleChangeContact(e)}
                        multiline
                        rows={6}
                        maxRows={4}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setOpenModalEditCompany(false)}>Cancel</Button>
                <Button>Send</Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
