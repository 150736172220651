import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../api/api';
import endpoint from '../../constant/endpoint';
import toastAlert from '../../constant/toast';

const useLogin = () => {
  const [username, setUsername] = useState('');
  const [passwordData, setPasswordData] = useState('');
  const [button, setButton] = useState('Login');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
  }));

  useEffect(() => {
    if (user.access_token) {
      navigate('/dashboard/app/');
    }
  }, []);

  const postLogin = async () => {
    setButton('Loading...');

    const data = {
      email: username,
      password: passwordData,
    };

    api
      .post(endpoint.POST_LOGIN, data)
      .then((res) => {
        if (res.data.success) {
          getDataProfile(res.data.data.access_token);
          dispatch({
            type: 'DATA_USER_AUTH',
            data: res.data.data,
          });
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }

        setButton('Login');
        console.log(err);
      });
  };

  const getDataProfile = async (token = '') => {
    const getAuthHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .get(endpoint.GET_USER_PROFILE, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          dispatch({
            type: 'DATA_USER_PROFILE',
            data: res.data.data,
          });

          getDataCompany(token);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }

        setButton('Login');
        console.log(err);
      });
  };

  const getDataCompany = async (token = '') => {
    const getAuthHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .get(endpoint.GET_USER_COMPANY, getAuthHeader)
      .then((res) => {
        setButton('Login');

        if (res.data.success) {
          const dataCompany = res.data.data;

          if (dataCompany.length === 0) {
            dispatch({
              type: 'DATA_USER_COMPANY_ID',
              data: '',
            });

            dispatch({
              type: 'DATA_USER_COMPANY_NAME',
              data: '',
            });

            dispatch({
              type: 'DATA_USER_COMPANY',
              data: [],
            });
          }

          if (dataCompany.length > 0) {
            for (let i = 0; i < dataCompany.length; i += 1) {
              dataCompany[0].is_selected_company = true;
            }

            dispatch({
              type: 'DATA_USER_COMPANY',
              data: dataCompany,
            });

            if (dataCompany.length > 0) {
              dispatch({
                type: 'DATA_USER_COMPANY_ID',
                data: dataCompany[0].id,
              });
            } else {
              dispatch({
                type: 'DATA_USER_COMPANY_ID',
                data: 0,
              });
            }

            dispatch({
              type: 'DATA_USER_COMPANY_NAME',
              data: dataCompany[0].name,
            });

            dispatch({
              type: 'DATA_USER_COMPANY_EMAIL',
              data: dataCompany[0].email,
            });

            dispatch({
              type: 'DATA_USER_COMPANY_PHONE',
              data: dataCompany[0].phone_number,
            });

            dispatch({
              type: 'DATA_USER_COMPANY_ADDRESS',
              data: dataCompany[0].address,
            });

            dispatch({
              type: 'DATA_USER_COMPANY_LOGO',
              data: dataCompany[0].logo,
            });
          }

          navigate('/dashboard/app/');
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          toastAlert(err.response.data.message, 'error');
        }

        setButton('Login');
        console.log(err);
      });
  };

  return {
    username,
    setUsername,
    passwordData,
    setPasswordData,
    postLogin,
    button,
  };
};

export default useLogin;
