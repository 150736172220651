import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import api from '../../../api/api';
import endpoint from '../../../constant/endpoint';
import toastAlert from '../../../constant/toast';

// page index
export const usePaylaterList = () => {
  const [dataInstallments, setDataInstallments] = useState([]);

  const pageSizeTable = 10;
  const [statusTable, setStatusTable] = useState('all');
  const [dataTotalInstallments, setTotalDataInstallments] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    if (companyId) {
      getDataInstallments();
    }
  }, []);

  const getDataInstallments = async (newPage = 1, filterTable = '') => {
    api
      .get(`${endpoint.PAYLATER_INSTALLMENTS}/companies/${companyId}/list?page_size=${pageSizeTable}&&page=${newPage}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {

          // console.log('list angsuran', res.data);

          const dataRes = res.data.data.data;
          const totalData = res.data.data.total_data;
          const totalDataPlus = dataInstallments.length + dataRes.length;

          if (totalDataPlus <= totalData) {
            setDataInstallments([...dataInstallments, ...dataRes]);
          }

          if (filterTable === 'filter') {
            setDataInstallments(dataRes);
          }

          setTotalDataInstallments(totalData);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  return {
    dataInstallments,
    dataTotalInstallments,
    getDataInstallments
  };
}

// page add
export const usePaylaterAdd = () => {
  const [dataInstallments, setDataInstallments] = useState({
      accelerated_repayment_fee: 0,
      amount: 0,
      company_id: "",
      copy_invoice_fee: 0,
      duty_stamp_fee: 0,
      duty_stamp_threshold: 0,
      loan_fee: 0,
      loan_fee_rate: 0,
      loan_late_fee: 0,
      loan_late_fee_rate: 0,
      loan_period: 0,
      loan_type: "",
      otp: "",
      paylater_rule_id: "",
      period_installment_amount: 0
  });

  const [amount, setAmount] = useState(0);
  const [dataTiers, setDataTiers] = useState([]);
  const [dataRulesTiers, setDataRulesTiers] = useState([]);
  const [dataRulesFilter, setDataRulesFilter] = useState({
    id: "",
    loan_type: "",
    loan_period: 0,
    loan_fee_rate: 0,
    loan_late_fee_rate: 0,
    accelerated_repayment_fee: 0,
    copy_invoice_fee: 0,
    duty_stamp_fee: 0,
    duty_stamp_threshold: 0,
    loan_late_fee_rate_total: 0
  });
  const [otp, setOtp] = useState('');
  const [loanLateFeeRateTotal, setLoanLateFeeRateTotal] = useState(0);
  const [copyInvoiceFee, setCopyInvoiceFee] = useState(0);
  const [dutyStampFee, setDutyStampFee] = useState(0);
  const [button, setButton] = useState(false);
  const [modalOtp, setModalOtp] = useState(false);
  const [buttonModalOtp, setButtonModalOtp] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId, otpResponse, companyName } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
    otpResponse: states?.PayoutFundsReducer?.otpResponse,
    companyName: states?.CompanyReducer?.companyName,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    if (companyId) {
      getDataTierList();
    }
  }, []);

  const getDataTierList = async () => {
    api
      .get(`${endpoint.PAYLATER_TIERS}/company/${companyId}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setDataTiers(res.data.data);
          setDataRulesTiers(res.data.data.paylater_rules);
        }
      })
      .catch((err) => {
        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleChangeInstallments = async (event, value) => {
    // 1. Installment
    //   - {loan_period} {loan_type} (Rp. ({Amount} + {Amount * loan_fee_rate}) / loan_period)
    //     - 1 monthly (Rp. (1000.000 + (1000.000 * 30 / 100)) / 1 ) = 1000.000 + 330.000 / 1 = 1.330.000
    //     - 3 monthly (Rp. (1000.000 + (1000.000 * 20 / 100)) / 3 ) = 1000.000 + 200.000 / 3 = 400.000
    //     - 6 monthly (Rp. (1000.000 + (1000.000 * 10 / 100)) / 6 ) = 1000.000 + 100.000 / 6 = 160.000

    const amount = parseFloat(value);
    const paylaterRules = dataTiers?.paylater_rules;

    const dataCalculate = [];
    for (let i = 0; i < paylaterRules.length; i+= 1) {
      // menghitung diskon
      const calculateDiscount = (amount * paylaterRules[i].loan_fee_rate) / 100;
      // menghitung amout + diskon
      const calculateAmount = amount + calculateDiscount;
      // dibagi dengan loan period
      const calculateInstallments = calculateAmount / paylaterRules[i].loan_period;

      paylaterRules[i].installments = `${paylaterRules[i].loan_period} ${paylaterRules[i].loan_type === "monthly" ? "Bulan" : "-" } (Rp. ${new Intl.NumberFormat().format(calculateInstallments)})`;
      paylaterRules[i].period_installment_amount = `${parseFloat(calculateInstallments)}`;
      dataCalculate.push(paylaterRules[i]);
    }
      
      dataTiers.paylater_rules = dataCalculate;

      setDataRulesTiers(dataTiers.paylater_rules);
      setDataTiers(dataTiers);

      setDataInstallments({
        ...dataInstallments,
      [event.target.name]: event.target.value
    });
  };

  const submitRequestOTP = async () => {

    setButton(true);

    const data = {
      via: 'email',
      template_name: 'sms_otp',
    };

    api
      .post(`${endpoint.OTP}/user`, data, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setButton(false);
          setModalOtp(true);

          dispatch({
            type: 'DATA_RESPONSE_OTP',
            data: res.data.data,
          });
        }
      })
      .catch((err) => {
        setButton(false);

        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const postDataInstallments = async () => {
    const otpData = otp;
    // amount * (loan fee rate / 100)
    const loanFeeTotal = (parseFloat(amount) * dataRulesFilter.loan_fee_rate) / 100;
    // (amount / loan_period ) + loan_fee
    const periodInstallmentAmountTotal = (parseFloat(amount) / dataRulesFilter.loan_period) + loanFeeTotal;
    // amount * (loan_late_fee_rate / 100)
    const loanLateFeeTotal = (parseFloat(amount) * dataRulesFilter.loan_late_fee_rate) / 100;
    const dutyStampFee =  parseFloat(amount) >= dataRulesFilter.duty_stamp_threshold ? 
                          dataRulesFilter.duty_stamp_fee :
                          0 // validasi amount >= duty_stamp_threshold;

    const payload = {
      period_installment_amount: Math.ceil(periodInstallmentAmountTotal),
      loan_fee : loanFeeTotal,
      loan_late_fee : loanLateFeeTotal,
      amount: parseFloat(amount),
      accelerated_repayment_fee : parseFloat(amount),
      company_id: companyId,
      copy_invoice_fee : dataRulesFilter.copy_invoice_fee,
      duty_stamp_fee : dutyStampFee,
      duty_stamp_threshold : dataRulesFilter.duty_stamp_threshold, 
      id : dataRulesFilter.id,
      loan_fee_rate: dataRulesFilter.loan_fee_rate,
      loan_late_fee_rate : dataRulesFilter.loan_late_fee_rate,
      loan_period: dataRulesFilter.loan_period,
      loan_type : dataRulesFilter.loan_type,
      otp: otpData,
      paylater_rule_id: dataRulesFilter.id,
    };

    setButtonModalOtp(true);

    api
      .post(`${endpoint.PAYLATER_INSTALLMENTS}/new`, payload, getAuthHeader)
      .then((res) => {
        setButtonModalOtp(false);

        if (res.data.success) {
          toastAlert(res.data.message, 'success');
          setTimeout(() => {
            navigate('/paylater/list/');
          }, 2000);
        }
      })
      .catch((err) => {
        setButtonModalOtp(false);

        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  return {
    postDataInstallments,
    handleChangeInstallments,
    button,
    dataRulesTiers,
    dataInstallments,
    amount, 
    setAmount,
    dataRulesFilter,
    setDataRulesFilter,
    loanLateFeeRateTotal, 
    setLoanLateFeeRateTotal,
    copyInvoiceFee, 
    setCopyInvoiceFee,
    dutyStampFee, 
    setDutyStampFee,
    submitRequestOTP,
    otp,
    setOtp,
    buttonModalOtp, 
    modalOtp, 
    setModalOtp,
    otpResponse, 
    companyName,
    setDataRulesTiers
  };
}

// page detail
export const usePaylaterDetail = () => {
  const [dataDetailInstallments, setDataDetailInstallments] = useState(
    {
      id: "",
      installment_number: "",
      amount: 0,
      loan_type: "",
      loan_fee_rate: 0,
      loan_fee: 0,
      loan_late_fee_rate: 0,
      loan_late_fee: 0,
      loan_period: 0,
      period_installment_amount: 0,
      accelerated_repayment_fee: 0,
      copy_invoice_fee: 0,
      duty_stamp_fee: 0,
      duty_stamp_threshold: 0,
      status: 0,
      reason: "",
      installment_payments: []
    }
  );
  const [loadingDataDetailInstallments, setLoadingDataDetailInstallments] = useState(false);
  const [installmentsPayment, setInstallmentsPayment] = useState({
    company_id: "",
    installment_id: "",
    paid_at: "",
    total_amount: 0
  });
  const [idInstallment, setIdInstallment] = useState(0);
  const [idDetailInstallment, setDetailIdInstallment] = useState(0);
  const [dateDefault, setDateDefault] = useState(moment().format("YYYY-MM-D"));
  const [acceleratedRepaymentFee, setAcceleratedRepaymentFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paidAt, setPaidAt] = useState(moment().format("YYYY-MM-D"));
  const [loadingPostInstallment, setLoadingPostInstallment] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, companyId } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    companyId: states?.CompanyReducer?.companyId,
  }));

  const getAuthHeader = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  };

  useEffect(() => {
    if (companyId) {

      const urlParams = new URLSearchParams(window.location.search);
      const myParamId = urlParams.get('id');

      getDataInstallmentsDetail(myParamId);
      setIdInstallment(myParamId);
    }
  }, []);

  const getDataInstallmentsDetail = async (param) => {

    setLoadingDataDetailInstallments(true);

    api
      .get(`${endpoint.PAYLATER_INSTALLMENTS}/${param}/companies/${companyId}`, getAuthHeader)
      .then((res) => {
        if (res.data.success) {
          setLoadingDataDetailInstallments(false);
          setDataDetailInstallments(res?.data?.data);
        }
      })
      .catch((err) => {
        setLoadingDataDetailInstallments(false);

        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  const handleChangeInstallments = async (event) => {

      setInstallmentsPayment({
        ...installmentsPayment,
      [event.target.name]: event.target.value
    });
  };

  const postDataInstallments = async () => {

    setLoadingPostInstallment(true);

    const payload = {
      company_id: companyId,
      installment_id: idInstallment,
      paid_at: moment(paidAt).format("YYYY-MM-D"),
      total_amount: parseFloat(totalAmount)
    }

    api
      .post(`${endpoint.PAYLATER_PAYMENT_INSTALLMENTS}/${idDetailInstallment}/confirm-payment`, payload, getAuthHeader)
      .then((res) => {

        setLoadingPostInstallment(null);

        if (res.data.success) {
          toastAlert(res.data.message, 'success');
          setTimeout(() => {
            navigate('/paylater/list/');
          }, 2000);
        }
      })
      .catch((err) => {

        setLoadingPostInstallment(null);

        if (!err.response.data.success) {
          if (err.response.data.error_code === 'E-00050') {
            setTimeout(() => {
              dispatch({
                type: 'DATA_USER_AUTH_EMPTY',
              });

              navigate('/login');
            }, 700);
          } else {
            toastAlert(err.response.data.message, 'error');
          }
        }
      });
  };

  return {
    dataDetailInstallments,
    loadingDataDetailInstallments,
    handleChangeInstallments,
    postDataInstallments,
    idDetailInstallment, 
    setDetailIdInstallment,
    totalAmount, 
    setTotalAmount,
    paidAt, 
    setPaidAt,
    dateDefault,
    acceleratedRepaymentFee,
    setAcceleratedRepaymentFee,
    loadingPostInstallment
  };
}