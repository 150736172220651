// @mui
import PropTypes from 'prop-types';
import { Typography, Card, CardContent, Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormatRupiah } from '@arismun/format-rupiah';

PayoutInfo.propTypes = {
  paymentPayoutFund: PropTypes.object,
};

export default function PayoutInfo({ paymentPayoutFund }) {
  const StyledProductImg = styled('img')({
    width: '100%',
    height: 200,
    objectFit: 'contain',
  });

  return (
    <Card>
      <CardContent>
        <Box>
          <StyledProductImg
            alt={'Bank Transfer'}
            src={
              'https://cdn.invoiceplus.id/dev/044149547dd9c161920828642873e18e4fa28560fe25d49adda647b95c4a216cc4e135f7caf35453ca52555ce8b2528cc0.jpg'
            }
          />
          <Grid container spacing={3} mt={1}>
            <Grid item md={8} xs={8}>
              <Typography variant="subtitle2">Requested Amount:</Typography>
              <Typography variant="subtitle2">Charge Amount:</Typography>
              <Typography variant="subtitle2">Total Payable:</Typography>
              <Typography variant="subtitle2">Available Balance:</Typography>
              <Typography variant="subtitle2">Current Balance:</Typography>
            </Grid>
            <Grid item md={4} xs={4}>
              <Typography variant="subtitle2" style={{ textAlign: 'right' }}>
                <FormatRupiah value={paymentPayoutFund.requested_amount} />
              </Typography>
              <Typography variant="subtitle2" style={{ textAlign: 'right', color: 'red' }}>
                <FormatRupiah value={paymentPayoutFund.charge_amount} />
              </Typography>
              <Typography variant="subtitle2" style={{ textAlign: 'right', color: 'red' }}>
                <FormatRupiah value={paymentPayoutFund.total_payable} />
              </Typography>
              <Typography variant="subtitle2" style={{ textAlign: 'right' }}>
                <FormatRupiah value={paymentPayoutFund.available_balance} />
              </Typography>
              <Typography variant="subtitle2" style={{ textAlign: 'right' }}>
                <FormatRupiah value={paymentPayoutFund.balance} />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
