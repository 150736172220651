import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Grid, Container, Stack, Typography, Link, Button, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// sections
import { AddFormEdit } from '../../sections/services';
// components
import Iconify from '../../components/iconify';
import useServices from '../../hooks/services/useServices';
// constant
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

export default function ServicesEditPage() {
  const { handleChangeServices, postDataEditServices, dataFormServices, button } = useServices();

  return (
    <>
      <Helmet>
        <title>Jasa Produk: Edit | InvoicePlus</title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Jasa / Produk Edit
            </Typography>
            <Link to="/services/list" component={RouterLink} sx={{ display: 'contents' }}>
              <Button startIcon={<Iconify icon="ic:twotone-chevron-left" />} sx={{ color: Colors.WHITE, marginTop: 2 }}>
                Back to list
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid item xs={12} md={6} lg={4}>
          <AddFormEdit
            handleChangeServices={handleChangeServices}
            dataFormServices={dataFormServices}
            postDataEditServices={postDataEditServices}
            title="Jasa / Produk Edit"
            button={button}
          />
        </Grid>
      </Container>
    </>
  );
}
