import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Container, Stack, Typography, Button, Link, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// components
import { List } from '../../sections/company';
import Iconify from '../../components/iconify';
// mock
import useCompany from '../../hooks/company/useCompany';
// constant
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

export default function CompanyListPage() {
  const { dataCompany, loadingCompany, changeCompany, confirmDeleteCompany } = useCompany();

  return (
    <>
      <Helmet>
        <title>Company: List | InvoicePlus</title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              All Companies
            </Typography>
            <Link to="/company/add" component={RouterLink} sx={{ display: 'contents' }} mt={5}>
              <Button
                sx={{
                  borderRadius: 20,
                  backgroundColor: Colors.PRIMARY,
                  '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
                  marginTop: 3.5,
                }}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Company
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        {loadingCompany ? <Iconify width={60} height={60} icon="line-md:loading-twotone-loop" /> : null}

        <List dataCompany={dataCompany} changeCompany={changeCompany} confirmDeleteCompany={confirmDeleteCompany} />

        {dataCompany.length === 0 && !loadingCompany && (
          <Typography variant="subtitle1" style={{ marginTop: 30, color: '#666666' }}>
            There is no data display for the company!
          </Typography>
        )}
      </Container>
    </>
  );
}
