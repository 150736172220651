import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Stack, Typography, Box, Link, Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
// sections
import { AddFormEdit, AddFormView } from '../../sections/pay';
// constant
import Colors from '../../constant/colors';
// components
import Iconify from '../../components/iconify';
import usePay from '../../hooks/pay/usePay';
// ----------------------------------------------------------------------

export default function InvoiceEditPage() {
  const {
    dataCustomer,
    dataServices,
    button,
    customerId,
    setCustomerId,
    dueDate,
    setDueDate,
    firstPaymentDate,
    setFirstPaymentDate,
    fontColor,
    setFontColor,
    invoiceNumber,
    setInvoiceNumber,
    invoiceTemplate,
    setInvoiceTemplate,
    invoiceTheme,
    setInvoiceTheme,
    linesData,
    setLinesData,
    paymentFrequency,
    setPaymentFrequency,
    paymentFrequencyPer,
    setPaymentFrequencyPer,
    status,
    setStatus,
    taxRate,
    setTaxRate,
    vatRate,
    setVatRate,
    numberOfPayments,
    setNumberOfPayments,
    dataImageInvoice,
    setDataImageInvoice,
    handleAddServices,
    handleChangeServices,
    tempDataServices,
    setTempDataServices,
    handleValueServices,
    subTotalServices,
    grandTotalServices,
    setGrandTotalServices,
    totalTaxServices,
    setTotalTaxServices,
    totalVateTaxServices,
    setTotalVateTaxServices,
    customerSelected,
    setCustomerSelected,
    deleteServicesInvoice,
    updateServicesInvoice,
    setIdServicesId,
    titleModalServices,
    setTitleModalServices,
    sendToEmail,
    setSendToEmail,
    emailNote,
    setEmailNote,
    viewPayment,
    setViewPayment,
    loadingInvDetail,
    postDataInvoiceEdit,
    discount,
    setDiscount,
    countDiscount,
    countTax,
    countVat,
  } = usePay();

  return (
    <>
      <Helmet>
        <title> Billing: Edit | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Billing / Edit
            </Typography>
            <Link to="/pay/list" component={RouterLink} sx={{ display: 'contents' }}>
              <Button startIcon={<Iconify icon="ic:twotone-chevron-left" />} sx={{ color: Colors.WHITE, marginTop: 2 }}>
                Back to list
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7}>
            <AddFormEdit
              handleChangeTicket={{}}
              postDataTicket={{}}
              title="Edit Billing"
              button={button}
              dataFormTicket={{}}
              dataCustomer={dataCustomer}
              dataServices={dataServices}
              postDataInvoice={postDataInvoiceEdit}
              customerId={customerId}
              setCustomerId={setCustomerId}
              dueDate={dueDate}
              setDueDate={setDueDate}
              firstPaymentDate={firstPaymentDate}
              setFirstPaymentDate={setFirstPaymentDate}
              fontColor={fontColor}
              setFontColor={setFontColor}
              invoiceNumber={invoiceNumber}
              setInvoiceNumber={setInvoiceNumber}
              invoiceTemplate={invoiceTemplate}
              setInvoiceTemplate={setInvoiceTemplate}
              invoiceTheme={invoiceTheme}
              setInvoiceTheme={setInvoiceTheme}
              lines={linesData}
              setLines={setLinesData}
              paymentFrequency={paymentFrequency}
              setPaymentFrequency={setPaymentFrequency}
              paymentFrequencyPer={paymentFrequencyPer}
              setPaymentFrequencyPer={setPaymentFrequencyPer}
              status={status}
              setStatus={setStatus}
              taxRate={taxRate}
              setTaxRate={setTaxRate}
              vatRate={vatRate}
              setVatRate={setVatRate}
              numberOfPayments={numberOfPayments}
              setNumberOfPayments={setNumberOfPayments}
              dataImageInvoice={dataImageInvoice}
              setDataImageInvoice={setDataImageInvoice}
              handleAddServices={handleAddServices}
              handleChangeServices={handleChangeServices}
              tempDataServices={tempDataServices}
              setTempDataServices={setTempDataServices}
              handleValueServices={handleValueServices}
              subTotalServices={subTotalServices}
              grandTotalServices={grandTotalServices}
              setGrandTotalServices={setGrandTotalServices}
              totalTaxServices={totalTaxServices}
              setTotalTaxServices={setTotalTaxServices}
              totalVateTaxServices={totalVateTaxServices}
              setTotalVateTaxServices={setTotalVateTaxServices}
              setCustomerSelected={setCustomerSelected}
              deleteServicesInvoice={deleteServicesInvoice}
              updateServicesInvoice={updateServicesInvoice}
              setIdServicesId={setIdServicesId}
              titleModalServices={titleModalServices}
              setTitleModalServices={setTitleModalServices}
              sendToEmail={sendToEmail}
              setSendToEmail={setSendToEmail}
              emailNote={emailNote}
              setEmailNote={setEmailNote}
              viewPayment={viewPayment}
              setViewPayment={setViewPayment}
              loadingInvDetail={loadingInvDetail}
              discount={discount}
              setDiscount={setDiscount}
              countDiscount={countDiscount}
              countTax={countTax}
              countVat={countVat}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <AddFormView
              handleChangeTicket={{}}
              postDataTicket={{}}
              title="Preview"
              button={button}
              dataFormTicket={{}}
              lines={linesData}
              subTotalServices={subTotalServices}
              taxRate={taxRate}
              vatRate={vatRate}
              discount={discount}
              totalTaxServices={totalTaxServices}
              totalVateTaxServices={totalVateTaxServices}
              grandTotalServices={grandTotalServices}
              invoiceTemplate={invoiceTemplate}
              invoiceNumber={invoiceNumber}
              dueDate={dueDate}
              firstPaymentDate={firstPaymentDate}
              customerSelected={customerSelected}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
