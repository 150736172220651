import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography, Grid, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// components
import { ChooseBank, ChooseDetailPayment } from './components';
// mock
import usePaymentPay from '../../../hooks/pay/payment/usePaymentPay';
// constant
import Colors from '../../../constant/colors';

// ----------------------------------------------------------------------

export default function CompanyListPage() {
  const {
    dataBankMethod,
    dataBankSelected,
    handleSelectedBank,
    dataDetailPayment,
    postPayment,
    loadingPayment,
    dataFormPayment,
    setDataFormPayment,
  } = usePaymentPay();

  return (
    <>
      <Helmet>
        <title> Payment Pay: Choose Bank | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Choose How You Want To Pay
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid container spacing={3}>
          <Grid item md={6}>
            <ChooseBank
              dataBankMethod={dataBankMethod}
              dataBankSelected={dataBankSelected}
              handleSelectedBank={handleSelectedBank}
              postPayment={postPayment}
              loadingPayment={loadingPayment}
              dataFormPayment={dataFormPayment}
              setDataFormPayment={setDataFormPayment}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ChooseDetailPayment dataDetailPayment={dataDetailPayment} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
