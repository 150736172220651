import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Box, Tabs, Tab, OutlinedInput, InputAdornment } from '@mui/material';
// component
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  getDataContact: PropTypes.func,
  setTypeContact: PropTypes.string,
};

export default function UserListToolbar({ numSelected, filterName, onFilterName, getDataInvoice }) {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);

    let status = 'all';
    if (newTabIndex === 1) {
      status = 'pending';
    } else if (newTabIndex === 2) {
      status = 'sent';
    } else if (newTabIndex === 3) {
      status = 'unsend';
    } else if (newTabIndex === 4) {
      status = 'scheduled';
    } else if (newTabIndex === 5) {
      status = 'paid';
    } else if (newTabIndex === 6) {
      status = 'rejected';
    }

    getDataInvoice(status, 10, 1, 'filter');
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <Box>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="All" />
          <Tab label="Pending" />
          <Tab label="Send" />
          <Tab label="Unsend" />
          <Tab label="Scheduled" />
          <Tab label="Paid" />
          <Tab label="Rejected" />
        </Tabs>
      </Box>

      <StyledSearch
        value={filterName}
        onChange={onFilterName}
        placeholder="Search pay..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        }
      />
    </StyledRoot>
  );
}
