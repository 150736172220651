// @mui
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Grid,
  CardHeader,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield';

import Colors from '../../../constant/colors';

CardDetailInstallmentPayment.propTypes = {
  title: PropTypes.string,
  dataDetailInstallments: PropTypes.func,
  setDetailIdInstallment: PropTypes.func,
  totalAmount: PropTypes.number,
  setTotalAmount: PropTypes.func,
  paidAt: PropTypes.string,
  setPaidAt: PropTypes.func,
  dateDefault: PropTypes.string,
  acceleratedRepaymentFee: PropTypes.number,
  setAcceleratedRepaymentFee: PropTypes.func,
  postDataInstallments: PropTypes.func,
  loadingPostInstallment: PropTypes.bool
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CardDetailInstallmentPayment({
  title,
  dataDetailInstallments,
  setDetailIdInstallment,
  totalAmount, 
  setTotalAmount,
  paidAt, 
  setPaidAt,
  dateDefault,
  acceleratedRepaymentFee,
  setAcceleratedRepaymentFee,
  postDataInstallments,
  loadingPostInstallment,
  ...other
}) {
  const [openModalPayNow, setOpenModalPayNow] = useState(null);

  const dataInstallments = dataDetailInstallments?.installment_payments;

  // 0 = unpaid 
  // 1 = confirm_payment 
  // 2 = paid 
  // 3 = re_confirm_payment 
  const statusValidate = (valueData) => {

    let dataTextStatus = 'Unpaid';
    let colors = '#e74c3c';
    
    if(valueData === 1) {
      dataTextStatus = 'Confirm Payment';
      colors = '#d3d900';
    }
    if(valueData === 2) {
      dataTextStatus = 'Paid';
      colors = '#32c214';
    }
    if(valueData === 3) {
      dataTextStatus = 'Re Confirm Payment';
      colors = '#ffc107';
    }

    return (
      <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: colors, width: '20%' }}>
        {dataTextStatus}
      </Typography>
    )
  }

  return (
    <Card {...other}>
      <CardHeader title={`${title}`} sx={{marginBottom: 2}} />
      
      <CardContent sx={{height: 370, overflow: 'scroll'}}>
        {dataInstallments.map((value, index) => 
        (
          <Box sx={{marginBottom: 2}} key={index}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {index + 1}/{dataDetailInstallments?.loan_period}
                  </Typography>
                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                      <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                        Rp. {new Intl.NumberFormat().format(value?.amount)}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                      + Rp. {new Intl.NumberFormat().format(value?.loan_late_fee)} Late Fee
                    </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 16, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                        Rp. {new Intl.NumberFormat().format(value?.amount + value?.loan_late_fee)}
                      </Typography>
                      {/* 0 = status unpaid */}
                      {value?.status === 0 && 
                        <Chip label="Pay Now" sx={{backgroundColor: 'red', color: '#fff'}} onClick={() => {
                          setOpenModalPayNow(true);
                          setDetailIdInstallment(value?.id);
                          setTotalAmount(value?.total_amount);
                          setAcceleratedRepaymentFee(dataDetailInstallments?.accelerated_repayment_fee);
                        }} />
                      }
                    </Box>
                  </Box>
                  {/* <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                    2 days overdue
                  </Typography> */}
                  <Box sx={{display: 'flex', marginTop: 2}}>
                    <Typography sx={{ fontSize: 12, marginRight: 5, width: '18%' }} gutterBottom>
                      Due Date
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '50%' }} color="text.secondary" gutterBottom>
                      {value?.due_date}
                    </Typography>
                    {statusValidate(value?.status)}
                  </Box>
                  <Box sx={{display: 'flex'}}>
                    <Typography sx={{ fontSize: 12, marginRight: 5, width: '18%' }} gutterBottom>
                      Paid At
                    </Typography>
                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                      {value?.paid_at}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
              </Grid>
            </Grid>
          </Box>
        )
        )}
      </CardContent>

      <BootstrapDialog
        onClose={() => {
          setOpenModalPayNow(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openModalPayNow}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Saya telah melakukan pembayaran ini</DialogTitle>
        <DialogContent dividers style={{ width: 400 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="message"
                  label="Tanggal"
                  type="date"
                  value={dateDefault}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <CurrencyTextField
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="amount"
                  label="Biaya Pelunasan Dipercepat"
                  variant="outlined"
                  value={acceleratedRepaymentFee}
                  currencySymbol="Rp."
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Tanggal Pembayaran"
                  type="date"
                  name="paid_at"
                  value={paidAt}
                  onChange={(e) => setPaidAt(e?.target?.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <CurrencyTextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="amount"
                  label="Jumlah Pembayaran"
                  variant="outlined"
                  value={totalAmount}
                  currencySymbol="Rp."
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={(event, value) => setTotalAmount(value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={postDataInstallments}
                  loading={loadingPostInstallment}
                  sx={{
                    borderRadius: 20,
                    backgroundColor: Colors.PRIMARY,
                    '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
                  }}
                >
                  Simpan
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </Card>
  );
}
