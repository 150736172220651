const Colors = {
  WHITE: '#FFF',
  BLACK: '#2f2d52',
  GREY: '#282828',
  LIGHT_GREY: '#F5F5F5',
  PURPLE: '#35275B',
  PRIMARY: '#B94C4C',
  GREYBB: '#BBBBBB',
  BLACK21: '#212121',
  GREY80: '#808080',
};

export default Colors;
