// @mui
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Card, TextField, Stack, CardHeader, CardContent, Grid, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

AddForm.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  dataCountries: PropTypes.array,
  dataProvinces: PropTypes.array,
  getDataRegionsChild: PropTypes.func,
  dataCity: PropTypes.array,
  loadingImage: PropTypes.bool,
  postDataFileImage: PropTypes.func,
};

export default function AddForm({
  title,
  subheader,
  handleChangeCompany,
  postDataCompany,
  dataFormCompany,
  button,
  dataCountries,
  dataProvinces,
  getDataRegionsChild,
  dataCity,
  loadingImage,
  postDataFileImage,
  ...other
}) {
  const [country, setCountry] = useState('0');
  const [state, setState] = useState('0');
  const [city, setCity] = useState('0');

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="name"
                  label="Name"
                  value={dataFormCompany.name}
                  onChange={(e) => handleChangeCompany(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="email"
                  label="Email address"
                  value={dataFormCompany.email}
                  onChange={(e) => handleChangeCompany(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="phone_number"
                  label="Phone Number"
                  value={dataFormCompany.phone_number}
                  onChange={(e) => handleChangeCompany(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Country"
                  name="country_id"
                  select
                  value={dataFormCompany.country_id}
                  onChange={(e) => handleChangeCompany(e)}
                >
                  <MenuItem key={'1'} value={'0'}>
                    Select
                  </MenuItem>
                  {dataCountries.map((value, index) => (
                    <MenuItem key={index} value={value.country_id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Province"
                  name="region_id"
                  select
                  value={dataFormCompany.region_id}
                  onChange={(e) => {
                    getDataRegionsChild(e.target.value);
                    handleChangeCompany(e);
                  }}
                >
                  <MenuItem key={'1'} value={'0'}>
                    Select
                  </MenuItem>
                  {dataProvinces.map((value, index) => (
                    <MenuItem key={index} value={value.region_id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="City"
                  name="city_id"
                  select
                  value={dataFormCompany.city_id}
                  onChange={(e) => handleChangeCompany(e)}
                >
                  <MenuItem key={'1'} value={'0'}>
                    Select
                  </MenuItem>
                  {dataCity.map((value, index) => (
                    <MenuItem key={index} value={value.city_id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="address"
                  label="Address"
                  value={dataFormCompany.address}
                  onChange={(e) => handleChangeCompany(e)}
                  multiline
                  rows={3}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="address2"
                  label="Address Line 2 (Optional)"
                  value={dataFormCompany.address2}
                  onChange={(e) => handleChangeCompany(e)}
                  multiline
                  rows={3}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="tax_id"
                  label="Tax ID (Optional)"
                  value={dataFormCompany.tax_id}
                  onChange={(e) => handleChangeCompany(e)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="file"
                  name="logo-image"
                  label="Upload Logo"
                  onChange={(event) => postDataFileImage(event)}
                />
              </Stack>
              <Typography sx={{ color: 'red', fontSize: 10 }}>{dataFormCompany?.logo ? dataFormCompany?.logo?.substring(0, 70) : ''}</Typography>
              {loadingImage && (
                <Typography sx={{ color: '#adadad' }} variant="body2">
                  Loading upload...
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Stack spacing={3}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={postDataCompany}>
                  {button}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
