import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Container, Stack, Typography, Grid, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// components
import { AdditionalForm, AdditionalInfo } from './components';
import Iconify from '../../../components/iconify';
// mock
import usePaymentPay from '../../../hooks/pay/payment/usePaymentPay';
// constant
import Colors from '../../../constant/colors';
// ----------------------------------------------------------------------

export default function CompanyListPage() {
  const {
    dataBankData,
    buttonAdditional,
    submitPaymentAditional,
    dataAmount,
    handleChangeAdditionalForm,
    dataFormAdditional,
    dataWithdraw,
  } = usePaymentPay();

  return (
    <>
      <Helmet>
        <title> Payment Pay: List | SASS Invoice </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Vendor Withdraw Information
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <AdditionalInfo dataWithdraw={dataWithdraw} />
          </Grid>
          <Grid item md={8} xs={12}>
            <AdditionalForm
              dataBankData={dataBankData}
              handleChangeAdditionalForm={handleChangeAdditionalForm}
              buttonAdditional={buttonAdditional}
              submitPaymentAditional={submitPaymentAditional}
              dataFormAdditional={dataFormAdditional}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
