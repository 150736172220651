import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography, Grid, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// components
import { PayoutInfo, PayoutForm } from './components';
// mock
import usePayoutFunds from '../../hooks/payoutfunds/usePayoutFunds';
// constant
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

export default function CompanyListPage() {
  const {
    dataBankPayout,
    paymentPayoutFund,
    formPayout,
    handleChangePayout,
    loadingPayoutBalance,
    submitPaymentPayoutFunds,
    setOtp,
    otp,
    otpResponse,
    companyName,
  } = usePayoutFunds();

  return (
    <>
      <Helmet>
        <title> Payout Funds: Additional Info | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Additional info to withdraw confirm
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <PayoutInfo paymentPayoutFund={paymentPayoutFund} />
          </Grid>
          <Grid item md={8}>
            <PayoutForm
              dataBankPayout={dataBankPayout}
              formPayout={formPayout}
              handleChangePayout={handleChangePayout}
              loadingPayoutBalance={loadingPayoutBalance}
              submitPaymentPayoutFunds={submitPaymentPayoutFunds}
              setOtp={setOtp}
              otp={otp}
              otpResponse={otpResponse}
              companyName={companyName}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
