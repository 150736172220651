import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
// @mui
import { Container, Grid, Box, Stack, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// components
import { PayQris, PayVA } from './components';
// mock
import usePaymentPay from '../../../hooks/pay/payment/usePaymentPay';
// constant
import Colors from '../../../constant/colors';
// ----------------------------------------------------------------------

export default function CompanyListPage() {
  const { dispatch, myParamLogoData } = usePaymentPay();

  const { dataPayment } = useSelector((states) => ({
    dataPayment: states?.PaymentReducer?.dataPayment,
  }));

  return (
    <>
      <Helmet>
        <title> Payment Pay: Success | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Payment
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid container spacing={3}>
          <Grid item md={12}>
            {dataPayment.type === 'qris' && <PayQris dataPaymentData={dataPayment} dispatch={dispatch} />}
            {dataPayment.type === 'va' && (
              <PayVA dataPayment={dataPayment} dispatch={dispatch} myParamLogoData={myParamLogoData} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
