import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Card, CardHeader, CardContent, Box, Stack } from '@mui/material';
import ucfirst from 'ucfirst';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { CChart } from '@coreui/react-chartjs';
// constant
import Colors from '../constant/colors';

// sections
import { AppWidgetSummary } from '../sections/@dashboard/app';
import useDashboard from '../hooks/dashboard/useDashboard';

// ----------------------------------------------------------------------

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default function DashboardAppPage() {
  const theme = useTheme();

  const { dataDashboard, labelChartLine } = useDashboard();

  const { userProfile } = useSelector((states) => ({
    userProfile: states?.UserReducer?.dataUserProfile,
  }));

  const events = [{ title: 'Meeting', start: new Date() }];

  return (
    <>
      <Helmet>
        <title> Dashboard | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="row">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3, fontWeight: 300 }}>
              Welcome Back,&nbsp;
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3, fontWeight: 'bold' }}>
              {`${ucfirst(userProfile.first_name)} ${ucfirst(userProfile.last_name)}`}
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Invoice" total={dataDashboard.total_invoice} icon={'mdi:invoice-outline'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Bill"
              total={dataDashboard.total_bill}
              color="info"
              icon={'medical-icon:i-billing'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Customers"
              total={dataDashboard.total_customer}
              color="warning"
              icon={'mdi:users-add'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Vendors"
              total={dataDashboard.total_vendor}
              color="error"
              icon={'heroicons-solid:users'}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={7}>
            <Card>
              <CardHeader title={'Summary'} />

              <CardContent
                sx={{
                  '& .MuiTimelineItem-missingOppositeContent:before': {
                    display: 'none',
                  },
                }}
              >
                <Box sx={{ width: 'auto', height: 'auto' }}>
                  <CChart
                    type="line"
                    data={{
                      labels: labelChartLine,
                      datasets: [
                        {
                          label: 'Billing',
                          backgroundColor: 'rgba(220, 220, 220, 0.2)',
                          borderColor: 'rgba(220, 220, 220, 1)',
                          pointBackgroundColor: 'rgba(220, 220, 220, 1)',
                          pointBorderColor: '#fff',
                          data: dataDashboard?.bill_chart?.x?.data,
                        },
                        {
                          label: 'Invoice',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: 'rgba(151, 187, 205, 1)',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '#fff',
                          data: dataDashboard?.invoice_chart?.x?.data,
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        legend: {
                          labels: {
                            color: '#2853cc',
                          },
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            color: '#d7d7d7',
                          },
                          ticks: {
                            color: '#35275B',
                          },
                        },
                        y: {
                          grid: {
                            color: '#d7d7d7',
                          },
                          ticks: {
                            color: '#d7d7d7',
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <Card>
              <CardHeader title={'Statistics'} />

              <CardContent
                sx={{
                  '& .MuiTimelineItem-missingOppositeContent:before': {
                    display: 'none',
                  },
                }}
              >
                <Grid container>
                  <Grid item xs={6} md={6} lg={4}>
                    <CircularProgress
                      value={dataDashboard.get_paid_compilation}
                      color="#2853cc"
                      size="130px"
                      thickness="8px"
                    >
                      <CircularProgressLabel>{dataDashboard.get_paid_compilation}%</CircularProgressLabel>
                    </CircularProgress>
                    <Typography style={{ fontSize: 10 }}>Get Paid Compilation</Typography>
                  </Grid>
                  <Grid item xs={6} md={6} lg={4}>
                    <CircularProgress
                      value={dataDashboard.pay_compilation}
                      color="#69e8a1"
                      size="130px"
                      thickness="8px"
                    >
                      <CircularProgressLabel>{dataDashboard.pay_compilation}%</CircularProgressLabel>
                    </CircularProgress>
                    <Typography style={{ fontSize: 10 }}>Pay Compilation</Typography>
                  </Grid>
                  <Grid item xs={6} md={6} lg={4}>
                    <CircularProgress
                      value={dataDashboard.payout_compilation}
                      color="#f19910"
                      size="130px"
                      thickness="8px"
                    >
                      <CircularProgressLabel>{dataDashboard.payout_compilation}%</CircularProgressLabel>
                    </CircularProgress>
                    <Typography style={{ fontSize: 10 }}>Payout Compilation</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
