import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/logo';
import useForgotPassword from '../../hooks/auth/useForgotPassword';
// sections
import { ResetPasswordForm } from '../../sections/auth/reset-password';
// constants
import Colors from '../../constant/colors';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPasswordPage() {
  const mdUp = useResponsive('up', 'md');

  const { emailData, setEmailData, postForgotPassword, button } = useForgotPassword();

  return (
    <Box sx={{ background: `linear-gradient(180deg, ${Colors.PURPLE} 60%, #D04D4D)` }}>
      <Helmet>
        <title> Reset Password | InvoicePlus </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Box
          sx={{
            color: 'white',
            width: '100%',
            px: { xs: 2, sm: 4, md: 55 },
            pt: { xs: 12, md: 18, lg: 20 },
          }}
        >
          <Box sx={{ position: 'absolute', left: 0, bottom: 0 }}>
            <Box sx={{ px: 5 }}>
              <Typography fontSize={30} mb={2} fontWeight="bold">
                InvoicePlus Dashboard
              </Typography>
              <Typography fontSize={16}>
                A centralized digital platform designed to <br /> streamline and manage the invoicing <br /> process for
                businesses and individuals.
              </Typography>
              <Typography mt={6} mb={3} fontSize={10} paddingRight={{ lg: 14 }} lineHeight={{ xs: '16px', md: '26px' }}>
                © Copyright Invoiceplus.id. All Rights Reserved
              </Typography>
            </Box>
          </Box>
        </Box>

        <Container maxWidth="xl" sx={{ borderTopLeftRadius: 30, borderBottomLeftRadius: 30, backgroundColor: '#fff' }}>
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Reset Password
            </Typography>
            <Typography variant="body2" gutterBottom mb={4}>
              Set up a new password for your account
            </Typography>

            <ToastContainer />

            <ResetPasswordForm
              emailData={emailData}
              setEmailData={setEmailData}
              postForgotPassword={postForgotPassword}
              button={button}
            />
          </StyledContent>
        </Container>
      </StyledRoot>
    </Box>
  );
}
