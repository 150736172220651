import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Grid, Container, Stack, Typography, Link, Button, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
// sections
import { AddFormChangePassword } from '../../../sections/profile';
// components
import Iconify from '../../../components/iconify';
import useChangePassword from '../../../hooks/profile/change-password/useChangePassword';
// constant
import Colors from '../../../constant/colors';

// ----------------------------------------------------------------------

export default function ChangePasswordPage() {
  const { handleChangePassword, postDataChangePassword, dataFormChangePassword, button } = useChangePassword();

  return (
    <>
      <Helmet>
        <title> Profile: Edit Password | InvoicePlus </title>
      </Helmet>

      <Box sx={{ background: `${Colors.PURPLE}`, height: 100, mb: 4 }}>
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom sx={{ color: Colors.WHITE, mt: 3 }}>
              Profile / Change Password
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <ToastContainer />

        <Grid item xs={12} md={6} lg={4}>
          <AddFormChangePassword
            handleChangePassword={handleChangePassword}
            postDataChangePassword={postDataChangePassword}
            dataFormChangePassword={dataFormChangePassword}
            title="Change Password"
            button={button}
          />
        </Grid>
      </Container>
    </>
  );
}
