import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Typography, Link, Stack, IconButton, InputAdornment, TextField, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
// constants
import Colors from '../../../constant/colors';

const styleLines = { borderBottom: 0.5, borderColor: Colors.GREYBB, width: '45%', position: 'relative', top: -10 };

export default function LoginForm({ username, setUsername, passwordData, setPasswordData, postLogin, button }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Stack spacing={3}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          name="email"
          label="Email address"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          value={passwordData}
          onChange={(e) => setPasswordData(e.target.value)}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Typography />
        <Link
          style={{ cursor: 'pointer', color: Colors.PRIMARY }}
          variant="subtitle2"
          underline="hover"
          onClick={() => navigate('/password/reset/')}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={postLogin}
        sx={{
          borderRadius: 20,
          backgroundColor: Colors.PRIMARY,
          '&.MuiButton-root:hover': { bgcolor: Colors.PRIMARY },
        }}
      >
        {button}
      </LoadingButton>

      <Box sx={{ mb: 2, mt: 2, display: 'flex' }}>
        <Box sx={styleLines} />
        <Typography sx={{ textAlign: 'center', color: Colors.GREYBB, px: 2 }}> or </Typography>
        <Box sx={styleLines} />
      </Box>

      <LoadingButton
        sx={{
          borderRadius: 20,
          backgroundColor: Colors.WHITE,
          borderColor: Colors.PRIMARY,
          '&.MuiButton-root:hover': { bgcolor: Colors.WHITE, borderColor: Colors.PRIMARY },
          color: Colors.PRIMARY,
          textDecoration: 'none',
        }}
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => navigate('/register')}
      >
        Register
      </LoadingButton>
    </>
  );
}
