import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Drawer, Typography, ButtonGroup } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import toastAlert from '../../../constant/toast';
// config
import navConfig from './config';
// constant
import Colors from '../../../constant/colors';

// ----------------------------------------------------------------------

const NAV_WIDTH = 285;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, dataCompany } = useSelector((states) => ({
    user: states?.UserReducer?.dataUserAuth,
    dataCompany: states?.CompanyReducer?.dataCompany,
    companyId: states?.CompanyReducer?.companyId,
    companyName: states?.CompanyReducer?.companyName,
  }));

  useEffect(() => {
    if (!user.access_token) {
      if (user.dataUserAuth.access_token === '') {
        navigate('/login');
      }
    }

    if (openNav) {
      onCloseNav();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const companyNameFunc = (name) => {
    let valueName = '';
    if (name) {
      let more = '';
      if (name.length > 18) {
        more = '...';
      }

      valueName = name.substring(0, 18) + more;
    }

    return valueName;
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', background: `${Colors.PURPLE}`, width: '100%' }}>
        <Logo />
      </Box>

      <Box sx={{ background: `${Colors.PURPLE}`, marginTop: -2 }}>
        <Box sx={{ background: `${Colors.BLACK21}`, borderTopRightRadius: 32, mt: 2 }}>
          <Box sx={{ py: 3, textAlign: 'center' }}>
            <ButtonGroup aria-label="outlined button group" sx={{ backgroundColor: Colors.PRIMARY }}>
              <Button onClick={() => navigate('/invoice/add')} sx={{ color: Colors.WHITE, borderColor: Colors.WHITE }}>
                + Invoice
              </Button>
              <Button onClick={() => navigate('/pay/add')} sx={{ color: Colors.WHITE, borderColor: Colors.WHITE }}>
                + Billing
              </Button>
            </ButtonGroup>
          </Box>

          <NavSection data={navConfig} />

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ mb: 2 }}>
            <Box sx={{ border: 0.5, ml: 4, mb: 2, borderColor: '#808080' }} />
            <Typography sx={{ color: Colors.WHITE, px: 4, mb: 2, fontSize: 14, fontWeight: 'bold' }}>
              Company
            </Typography>
            <Typography sx={{ color: Colors.WHITE, px: 4, mb: 3, fontSize: 14 }}>
              Change to another company to view their dashboard
            </Typography>
          </Box>

          <Box>
            {dataCompany.map((value, index) => (
              <Box key={index}>
                {value.is_selected_company && (
                  <Box
                    sx={{
                      backgroundColor: Colors.PRIMARY,
                      width: 9,
                      height: 35,
                      position: 'absolute',
                      left: 0,
                      borderTopRightRadius: 6,
                      borderBottomRightRadius: 6,
                    }}
                  />
                )}

                <Typography
                  key={index}
                  sx={{
                    color: value.is_selected_company ? Colors.WHITE : Colors.GREY80,
                    px: 4,
                    fontSize: 14,
                    fontWeight: value.is_selected_company ? 'bold' : '100',
                    cursor: 'pointer',
                    mt: 0.5,
                  }}
                  onClick={() => {
                    dispatch({
                      type: 'DATA_USER_COMPANY_ID',
                      data: value.id,
                    });

                    dispatch({
                      type: 'DATA_USER_COMPANY_NAME',
                      data: value.name,
                    });

                    dispatch({
                      type: 'DATA_USER_COMPANY_EMAIL',
                      data: value.email,
                    });

                    dispatch({
                      type: 'DATA_USER_COMPANY_PHONE',
                      data: value.phone_number,
                    });

                    dispatch({
                      type: 'DATA_USER_COMPANY_ADDRESS',
                      data: value.address,
                    });

                    dispatch({
                      type: 'DATA_USER_COMPANY_LOGO',
                      data: value.logo,
                    });

                    for (let i = 0; i < dataCompany.length; i += 1) {
                      let select = false;
                      if (value.id === dataCompany[i].id) {
                        select = true;
                      }

                      dataCompany[i].is_selected_company = select;
                    }

                    dispatch({
                      type: 'DATA_USER_COMPANY',
                      data: dataCompany,
                    });

                    toastAlert(`${value.name} switch successfully`, 'success');

                    setInterval(() => {
                      // navigate(pathname);
                      window.location.reload(true);
                    }, 2000);
                  }}
                >
                  {companyNameFunc(value.name)}
                </Typography>
                <br />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              borderRightColor: Colors.PURPLE,
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
